import React, { useContext, useState, useEffect } from "react";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import {
  AccountApi,
  ProfileApi,
  SetupChecklistViewModel,
} from "../../apiclient";
import { apiConfig } from "../../config";
import { FormCheck, Button, Spinner, Form, Col, Row } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../../contexts/AccountContext";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import AnalyticsPanelComponent from "../analytics/AnalyticsPanelComponent";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";
import LaunchBanner from "../../components/LaunchBanner";

const HomeOnboardingPage = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(false);
  let profileApi = new ProfileApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [model, setModel] = useState({} as SetupChecklistViewModel);
  const [isLoading, setLoading] = useState(false as boolean);
  const [checklistItemsCompleted, setChecklistItemsCompleted] = useState(
    0 as number
  );
  const [checklistItemsCompletedPercent, setChecklistItemsCompletedPercent] =
    useState(0 as number);
  const [activeChecklist, setActiveChecklist] = useState(1 as number);

  const hideChecklist = () => {
    profileApi
      .profileHideSetupChecklistPost()
      .then((response) => {
        if (response.data) {
          toast.success("Checklist hidden.");
        }
        authContext.refresh(); //refresh context;
      })
      .catch((error: any) => { })
      .finally(() => {
        setModel((values: SetupChecklistViewModel) => ({
          ...values,
          isSetupChecklistHidden: true,
        }));
        fetchChecklistData();
      });
  };

  const markNotificationsAsReviewed = () => {
    profileApi
      .profileSubmitEventPost("gift card notifications reviewed")
      .then((response) => {
        if (response.data) {
          toast.success("Gift card notifications reviewed.");
        }
        authContext.refresh(); //refresh context;
      })
      .catch((error: any) => { })
      .finally(() => {
        setModel((values: SetupChecklistViewModel) => ({
          ...values,
          reviewedEmailNotifications: true,
        }));
        fetchChecklistData();
      });
  };

  const markGiftCardSettingsAsReviewed = () => {
    profileApi
      .profileSubmitEventPost("gift card settings reviewed")
      .then((response) => {
        if (response.data) {
          toast.success("Gift card settings reviewed.");
        }
        authContext.refresh(); //refresh context;
      })
      .catch((error: any) => { })
      .finally(() => {
        setModel((values: SetupChecklistViewModel) => ({
          ...values,
          reviewedGiftCardSettings: true,
        }));
        fetchChecklistData();
      });
  };

  const toggleAccountStatus = () => {
    profileApi
      .profileToggleAccountStatusPost()
      .then((response) => {
        if (response.data) {
          toast.success(
            `Gift card program ${model.giftCardProgramEnabled ? "disabled" : "enabled"
            }.`
          );
          setShowConfetti(true);
        } else {
          toast.success("Gift card program disabled.");
        }
        authContext.refresh(); //refresh context;
      })
      .catch((error: any) => {
        console.log("toggle survey error", error);

        setLoading(false);
      })
      .finally(() => {
        setModel((values: SetupChecklistViewModel) => ({
          ...values,
          giftCardProgramEnabled: !model.giftCardProgramEnabled,
        }));
        fetchChecklistData();
      });
  };

  useEffect(() => {
    fetchChecklistData();
  }, []);

  const fetchChecklistData = () => {
    profileApi
      .profileSetupChecklistGet()
      .then((response) => {
        var data = response.data;
        console.log("fetchChecklistData", data);
        setModel(data);
        var numComplete = 0;
        if (data.salesChannelConnected) numComplete++;
        if (data.reviewedGiftCardSettings) numComplete++;
        if (data.reviewedEmailNotifications) numComplete++;
        if (data.upgradeToPaidPlan) numComplete++;
        if (data.giftCardProgramEnabled) numComplete++;
        setChecklistItemsCompleted(numComplete);

        var percentComplete = Math.round((numComplete / 5) * 100);
        setChecklistItemsCompletedPercent(percentComplete);
        if (!data.upgradeToPaidPlan) {
          setActiveChecklist(4);
        } else if (!data.salesChannelConnected) {
          setActiveChecklist(1);
        } else if (!data.reviewedGiftCardSettings) {
          setActiveChecklist(2);
        } else if (!data.reviewedEmailNotifications) {
          setActiveChecklist(3);
        } else if (!data.giftCardProgramEnabled) {
          setActiveChecklist(5);
        } else {
          setActiveChecklist(6);
        }
        setLoading(false);
      })
      .catch((error: any) => {
        console.log("fetchChecklistData error", error);

        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          recycle={false}
          numberOfPieces={500}
        />
      )}
      <LaunchBanner />
      <div className="container">
        <header>
          <div className="container-fluid">
            <div className="pt-6">
              <div className="row align-items-center">
                <div className="col-sm-12 col-12">
                  <h1 className="h2 ls-tight">Welcome to Wrapped</h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main className="py-6 bg-surface-secondary">
          {isLoading ? (
            <Spinner className="ms-auto" animation="border" />
          ) : (
            <div className="container-fluid px-xl-8 vstack gap-3">
              {model && !model.isSetupChecklistHidden && (authContext.account?.userRole === "Admin" || authContext.account?.userRole === "Owner") && (
                <>
                  <div className="card">
                    <div className="px-4 px-md-8 pb-4">
                      <div className="d-flex align-items-center pt-5">
                        <div className="me-auto ">
                          <h5 className="font-semibold">
                            <span>Set-up guide</span>
                          </h5>
                          <p className="text-sm text-muted mb-3">
                            Step through this set-up guide to start selling and
                            accepting omnichannel gift cards.
                          </p>
                        </div>
                        {checklistItemsCompletedPercent === 100 && (
                          <div className="ms-auto text-end">
                            <button
                              type="button"
                              onClick={() => hideChecklist()}
                              className="btn btn-square btn-neutral"
                            >
                              <span className="svg-icon">
                                <i className="bi bi-x"></i>
                              </span>
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="row mb-4 pb-5 border-bottom">
                        <div className="d-flex align-items-center">
                          <div className="text-sm pr-25">
                            {checklistItemsCompleted} of 5 tasks complete
                          </div>

                          <div className="col px-0 text-end">
                            <div style={{ width: "100%" }}>
                              <div
                                className="progress progress-sm shadow-none"
                                style={{ height: "8px" }}
                              >
                                <div
                                  className="progress-bar bg-primary"
                                  role="progressbar"
                                  style={{
                                    width: checklistItemsCompletedPercent + "%",
                                  }}
                                  aria-valuenow={checklistItemsCompletedPercent}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="list-group list-group-flush">
                        <div className="list-group-item py-1 border-0">
                          <div className="row g-3 align-items-center">
                            <div className="col-sm-11 col-12">
                              <div className="form-check ">
                                {model.upgradeToPaidPlan ? (
                                  <input
                                    className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                                    type="checkbox"
                                    checked
                                  />
                                ) : (
                                  <input
                                    className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                                    type="checkbox"
                                  />
                                )}
                                <a
                                  onClick={() => setActiveChecklist(4)}
                                  className={
                                    "form-check-label ms-2 cursor-pointer " +
                                    (activeChecklist === 4
                                      ? "font-bold"
                                      : "font-regular")
                                  }
                                >
                                  Choose your plan
                                </a>
                              </div>
                              {activeChecklist === 4 && (
                                <div className="row">
                                  <div className="col-md-8 py-5">
                                    <p className="text-sm text-muted mb-3">
                                      Start your 14 day free trial.
                                    </p>
                                    <Link
                                      className="btn btn-sm btn-primary"
                                      to={`/billingplan`}
                                    >
                                      Choose plan
                                    </Link>
                                  </div>
                                  <div className="col-md-4">
                                    <img src="/img/checklist/choose-plan.png" />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="list-group-item py-1 border-0">
                          <div className="row g-3 align-items-center">
                            <div className="col-sm-11 col-12">
                              <div className="form-check ">
                                {model.salesChannelConnected ? (
                                  <input
                                    className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                                    type="checkbox"
                                    checked
                                  />
                                ) : (
                                  <input
                                    className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                                    type="checkbox"
                                  />
                                )}
                                <a
                                  onClick={() => setActiveChecklist(1)}
                                  className={
                                    "form-check-label ms-2 cursor-pointer " +
                                    (activeChecklist === 1
                                      ? "font-bold"
                                      : "font-regular")
                                  }
                                >
                                  Connect your sales channels
                                </a>
                              </div>
                              {activeChecklist == 1 && (
                                <div className="row">
                                  <div className="col-md-8 py-5">
                                    <p className="text-sm text-muted mb-3">
                                      Sync gift cards across your eCommerce and
                                      POS.
                                    </p>
                                    <Link
                                      className="btn btn-sm btn-primary"
                                      to={`/account/sales-channels`}
                                    >
                                      Connect
                                    </Link>
                                  </div>
                                  <div className="col-md-4">
                                    <img src="/img/checklist/connect-sales-channels.png" />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="list-group-item py-1 border-0">
                          <div className="row g-3 align-items-center">
                            <div className="col-sm-11 col-12">
                              <div className="form-check ">
                                {model.reviewedGiftCardSettings ? (
                                  <input
                                    className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                                    type="checkbox"
                                    checked
                                  />
                                ) : (
                                  <input
                                    className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                                    type="checkbox"
                                  />
                                )}
                                <a
                                  onClick={() => setActiveChecklist(2)}
                                  className={
                                    "form-check-label ms-2 cursor-pointer " +
                                    (activeChecklist === 2
                                      ? "font-bold"
                                      : "font-regular")
                                  }
                                >
                                  Review gift card settings
                                </a>
                              </div>
                              {activeChecklist == 2 && (
                                <div className="row">
                                  <div className="col-md-8 py-5">
                                    <p className="text-sm text-muted mb-3">
                                      Set your gift card expiry rules.
                                    </p>
                                    <Link
                                      className="btn btn-sm btn-primary"
                                      to={`/gift-cards/settings`}
                                    >
                                      Review settings
                                    </Link>
                                    <button
                                      className="btn btn-sm btn-outline-primary ml-5"
                                      type="button"
                                      onClick={() =>
                                        markGiftCardSettingsAsReviewed()
                                      }
                                    >
                                      Mark as done
                                    </button>
                                  </div>
                                  <div className="col-md-4">
                                    <img src="/img/checklist/settings.png" />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="list-group-item py-1 border-0">
                          <div className="row g-3 align-items-center">
                            <div className="col-sm-11 col-12">
                              <div className="form-check ">
                                {model.reviewedEmailNotifications ? (
                                  <input
                                    className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                                    type="checkbox"
                                    checked
                                  />
                                ) : (
                                  <input
                                    className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                                    type="checkbox"
                                  />
                                )}
                                <a
                                  onClick={() => setActiveChecklist(3)}
                                  className={
                                    "form-check-label ms-2 cursor-pointer " +
                                    (activeChecklist === 3
                                      ? "font-bold"
                                      : "font-regular")
                                  }
                                >
                                  Customize gift card email notifications and
                                  assets
                                </a>
                              </div>
                              {activeChecklist === 3 && (
                                <div className="row">
                                  <div className="col-md-8 py-5">
                                    <p className="text-sm text-muted mb-3">
                                      Add your brand, gift card design and
                                      messaging to everything customer facing.
                                    </p>
                                    <Link
                                      className="btn btn-sm btn-primary"
                                      to={`/emails`}
                                    >
                                      Customize emails
                                    </Link>
                                    <button
                                      className="btn btn-sm btn-outline-primary ml-5"
                                      type="button"
                                      onClick={() =>
                                        markNotificationsAsReviewed()
                                      }
                                    >
                                      Mark as done
                                    </button>
                                  </div>
                                  <div className="col-md-4">
                                    <img src="/img/checklist/customize.png" />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="list-group-item py-1 border-0">
                          <div className="row g-3 align-items-center">
                            <div className="col-sm-11 col-12">
                              <div className="form-check ">
                                {model.giftCardProgramEnabled ? (
                                  <input
                                    className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                                    type="checkbox"
                                    checked
                                  />
                                ) : (
                                  <input
                                    className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                                    type="checkbox"
                                  />
                                )}
                                <a
                                  onClick={() => setActiveChecklist(5)}
                                  className={
                                    "form-check-label ms-2 cursor-pointer " +
                                    (activeChecklist === 5
                                      ? "font-bold"
                                      : "font-regular")
                                  }
                                >
                                  Turn on your gift cards
                                </a>
                              </div>
                              {activeChecklist === 5 && (
                                <div className="row">
                                  <div className="col-md-8 py-5">
                                    <p className="text-sm text-muted mb-3">
                                      Start selling and accepting gift cards
                                      in-store and online.
                                    </p>
                                    {model.giftCardProgramEnabled ? (
                                      <Button
                                        type="button"
                                        onClick={toggleAccountStatus}
                                        className="btn btn-sm btn-neutral"
                                        disabled={
                                          !model.salesChannelConnected ||
                                          !model.reviewedGiftCardSettings ||
                                          !model.reviewedEmailNotifications ||
                                          !model.upgradeToPaidPlan
                                        }
                                      >
                                        Turn off
                                      </Button>
                                    ) : (
                                      <>
                                        <p className="text-sm text-muted mb-3">
                                          Note: Connected sales channels will be set to active.
                                        </p>
                                        <Button
                                          type="button"
                                          onClick={toggleAccountStatus}
                                          className="btn btn-sm btn-primary"
                                          disabled={
                                            !model.salesChannelConnected ||
                                            !model.reviewedGiftCardSettings ||
                                            !model.reviewedEmailNotifications ||
                                            !model.upgradeToPaidPlan
                                          }
                                        >
                                          Turn on
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                  <div className="col-md-4">
                                    <img src="/img/checklist/turn-on.png" />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </>
              )}
              <AnalyticsPanelComponent />
              {(authContext.account?.userRole === "Admin" || authContext.account?.userRole === "Owner") &&
                <div>
                  <hr />
                  <h2 className="h3 ls-tight pb-5">Get the most out of Wrapped</h2>
                  <div className="card mb-2">
                    <div className="card-body">
                      <div className="mb-5">
                        <h4 className="mb-1">Add your integrations</h4>
                        <p className="text-sm text-muted">
                          Save time and take your customers’ experience to the next
                          level with marketing, returns, mobile ordering
                          integrations and more.
                        </p>
                      </div>
                      <div className="vstack gap-5">
                        <div className="hstack gap-2 justify-content-end">
                          <Link
                            className="btn btn-sm btn-primary"
                            to={`/manage-integrations`}
                          >
                            Connect integration
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-2">
                    <div className="card-body">
                      <div className="mb-5">
                        <h4 className="mb-1">Generate gift cards in bulk</h4>
                        <p className="text-sm text-muted">
                          Use gift cards for employee recognition, charities,
                          corporate partnerships and more.
                        </p>
                      </div>
                      <div className="vstack gap-5">
                        <div className="hstack gap-2 justify-content-end">
                          <Link
                            className="btn btn-sm btn-primary"
                            to={`/bulk-gift-cards`}
                          >
                            Generate gift cards in bulk
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-2">
                    <div className="card-body">
                      <div className="mb-5">
                        <h4 className="mb-1">Migrate existing active codes</h4>
                        <p className="text-sm text-muted">
                          Already have gift card codes in circulation, import them
                          to sync them across all your sales channels.
                        </p>
                      </div>
                      <div className="vstack gap-5">
                        <div className="hstack gap-2 justify-content-end">
                          <Link
                            className="btn btn-sm btn-primary"
                            to={`/gift-cards`}
                          >
                            Migrate existing active codes
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-2">
                    <div className="card-body">
                      <div className="mb-5">
                        <h4 className="mb-1">Need more physical gift cards?</h4>
                        <p className="text-sm text-muted">
                          Integrate physical gift cards that work both in-store and online.
                        </p>
                      </div>
                      <div className="vstack gap-5">
                        <div className="hstack gap-2 justify-content-end">
                          <a
                            className="btn btn-sm btn-primary"
                            href={`https://www.plasticprinters.com/wrapped-gift-cards?company=${authContext.account?.companyName}&merchant_id=${authContext.account?.accountId}&email=${authContext.account?.emailAddress}&phone=${authContext.account?.phone}`}
                            target="_blank"
                          >
                            Buy physical gift cards
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-2">
                    <div className="card-body">
                      <div className="mb-5">
                        <h4 className="mb-1">Invite team members</h4>
                        <p className="text-sm text-muted">
                          Add your employees with permission based access.
                        </p>
                      </div>
                      <div className="vstack gap-5">
                        <div className="hstack gap-2 justify-content-end">
                          <Link
                            className="btn btn-sm btn-primary"
                            to={`/account/users`}
                          >
                            Invite users
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
            </div>
          )}
        </main>
      </div>
    </React.Fragment>
  );
};

export default HomeOnboardingPage;
