import React, { Component, useState, useContext, useEffect } from "react";
import { Button, Row, Modal, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { GiftcardApi } from "../../apiclient";
import { apiConfig } from "../../config";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import {
  GiftCardShippingAddressViewModel,
  GiftCardShippingAddress,
} from "../../apiclient/models";
import "./GiftCardEditShippingDetailsModalComponent.css"; // Include the custom CSS
import countries from 'i18n-iso-countries';

// Import language data for localization (e.g., English)
import en from 'i18n-iso-countries/langs/en.json';

interface GiftCardEditShippingDetailsModalComponentProps {
  shippingAddress: GiftCardShippingAddress;
  onHide: any;
  show: boolean;
  onRefresh: any;
}

const GiftCardEditShippingDetailsModalComponent = (
  props: GiftCardEditShippingDetailsModalComponentProps
) => {
  //modal + form
  let { id } = useParams();
  const [model, setModel] = useState({} as GiftCardShippingAddressViewModel);
  const [validated, setValidated] = useState(false);
  let cardApi = new GiftcardApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );


  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const libraries: ("places")[] = ["places"]; // Load Places library from Google Maps API

  countries.registerLocale(en);
  const countryList = countries.getNames('en');
  const countryOptions = Object.entries(countryList).map(([code, name]) => ({
    code,
    name,
  }));

  const handleLoad = (autocompleteInstance: google.maps.places.Autocomplete): void => {
    setAutocomplete(autocompleteInstance);
  };

  const handlePlaceChanged = (): void => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      console.log("place", place);
      const components: { [key: string]: string } = {
        street_number: "",
        route: "",
        locality: "",
        administrative_area_level_1: "",
        postal_code: "",
        country: "",
        country_code: ""
      };

      if (place.address_components) {
        place.address_components.forEach((component) => {
          component.types.forEach((type) => {
            if (components.hasOwnProperty(type)) {
              components[type] = component.long_name;
              if (type === "country") {
                components["country_code"] = component.short_name;
              }
            }
          });
        });

        console.log("address", `${components.street_number} ${components.route}`.split(",")[0]);

        setModel({
          giftCardId: id,
          name: model.name,
          address1: `${components.street_number} ${components.route}`.split(",")[0],
          address2: model.address2,
          city: components.locality,
          state: components.administrative_area_level_1,
          postalCode: components.postal_code,
          country: components.country,
          countryCode: components.country_code
        });
      }
    }
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: GiftCardShippingAddressViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleCountryChange = (e: any) => {
    const selectedCode = e.target.value;
    setModel((values: GiftCardShippingAddressViewModel) => ({
      ...values,
      countryCode: selectedCode,
      country: countries.getName(selectedCode, 'en')
    }));
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      //setIsSubmitting(false);
      cardApi
        .giftcardSaveEditShippingAddressPost("1", model)
        .then((response) => {
          if (response.data) {
            toast.success("Shipping details have been saved.");
            props.onHide();
            props.onRefresh();
            setValidated(false);
          } else {
            toast.error("Could not save shipping details.");
          }
        })
        .catch((error: any) => {
          toast.error("An error occurred.");
        });
    }
  };
  const modalLoaded = () => {
    setValidated(false);
    if (props.shippingAddress) {
      setModel({
        address1: props.shippingAddress.address1,
        address2: props.shippingAddress.address2,
        city: props.shippingAddress.city,
        country: props.shippingAddress.country,
        countryCode: props.shippingAddress.countryCode,
        postalCode: props.shippingAddress.postalCode,
        giftCardId: id,
        name: props.shippingAddress.name,
        state: props.shippingAddress.state
      });

    }
  };

  return (
    <React.Fragment>
      <Modal fullscreen show={props.show} onHide={props.onHide} onEntered={modalLoaded}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Edit the recipient's address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group className="">
                <Form.Label>Recipient name:</Form.Label>
                <Form.Control
                  name="name"
                  required
                  value={model.name || ""}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mt-5">
                <Form.Label>Address 1:</Form.Label>
                <LoadScript
                  googleMapsApiKey="AIzaSyA5pxYFOO0o-SDH9BYSNYa1F26KAPnPErA" // Replace with your Google API Key
                  libraries={libraries}
                >
                  <Autocomplete
                    onLoad={handleLoad}
                    onPlaceChanged={handlePlaceChanged}
                  >
                    <div className="input-group input-group-inline">
                      <Form.Control
                        required
                        name="address1"
                        value={model.address1 || ""}
                        onChange={handleChange}
                        placeholder="Start typing your address"
                      />
                      <span className="input-group-text" id="">
                        <span className="bi bi-search"></span>
                      </span>
                    </div>

                  </Autocomplete>
                </LoadScript>
                <span className="d-block mt-2 text-sm text-muted">Street address</span>
              </Form.Group>
              <Form.Group className="mt-5">
                <Form.Label>Address 2:</Form.Label>
                <Form.Control
                  name="address2"
                  value={model.address2 || ""}
                  onChange={handleChange}
                />
                <span className="d-block mt-2 text-sm text-muted">Apartment, suite, unit, building, floor, etc.</span>
              </Form.Group>
              <div className="row">
                <div className="col-md-4">
                  <Form.Group className="mt-5">
                    <Form.Label>City:</Form.Label>
                    <Form.Control
                      required
                      name="city"
                      value={model.city || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-4">
                  <Form.Group className="mt-5">
                    <Form.Label>State:</Form.Label>
                    <Form.Control
                      required
                      name="state"
                      value={model.state || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-4">
                  <Form.Group className="mt-5">
                    <Form.Label>Zip:</Form.Label>
                    <Form.Control
                      required
                      name="postalCode"
                      value={model.postalCode || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
              </div>
              <Form.Group className="mt-5">
                <Form.Label>Country:</Form.Label>
                <Form.Select
                  required
                  name="countryCode"
                  onChange={handleCountryChange}
                  value={model.countryCode}
                >
                  <option value="">Select a country</option>
                  {countryOptions.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={props.onHide}>
              Cancel
            </Button>
            <Button size="sm" variant="primary" type="submit">
              Update postal address
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default GiftCardEditShippingDetailsModalComponent;
