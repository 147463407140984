import React, { useContext, useState, useEffect } from "react";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { AccountApi } from "../../apiclient";
import { apiConfig } from "../../config";
import { FormCheck, Button, Spinner, Form, Col, Row } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../../contexts/AccountContext";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import EmailEditReminderSettingsModal from "./EmailEditReminderSettingsModal";
import { EmailApi } from "../../apiclient";
import LaunchBanner from "../../components/LaunchBanner";

const EmailsListPage = () => {
  const authContext = useContext(AuthContext);
  let emailApi = new EmailApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [
    isEmailReminderSettingsModalShown,
    setIsEmailReminderSettingsModalShown,
  ] = useState(false);
  const [reminderEmailEnabled, setReminderEmailEnabled] = useState(false);
  const [giftCardIssuedEmailEnabled, setGiftCardIssuedEmailEnabled] =
    useState(false);
    const [giftCardUpdatedEmailEnabled, setGiftCardUpdatedEmailEnabled] =
    useState(false);
  const [giftCardGiftedEmailEnabled, setGiftCardGiftedEmailEnabled] =
    useState(false);

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
  };

  const toggleReminderEnabled = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;

    setReminderEmailEnabled(!reminderEmailEnabled);

    emailApi
      .emailNotificationToggleEnabledNotificationTypeGet("GiftCardReminder")
      .then((response) => {
        if (response.data) {
          toast.success("Email reminder updated.");
        }
      })
      .catch((error: any) => {
        toast.error("Couldn't update reminder status.");
      });
  };

  const toggleGiftCardIssuedEnabled = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;

    setGiftCardIssuedEmailEnabled(!giftCardIssuedEmailEnabled);

    emailApi
      .emailNotificationToggleEnabledNotificationTypeGet("GiftCardPurchased")
      .then((response) => {
        if (response.data) {
          toast.success("Gift card issued email updated.");
        }
      })
      .catch((error: any) => {
        toast.error("Couldn't update email status.");
      });
  };

  const toggleGiftCardGiftedEnabled = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;

    setGiftCardGiftedEmailEnabled(!giftCardGiftedEmailEnabled);

    emailApi
      .emailNotificationToggleEnabledNotificationTypeGet("GiftCardGifted")
      .then((response) => {
        if (response.data) {
          toast.success("Gift card gifted email updated.");
        }
      })
      .catch((error: any) => {
        toast.error("Couldn't update email status.");
      });
  };

  const toggleGiftCardUpdatedEnabled = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;

    setGiftCardUpdatedEmailEnabled(!giftCardUpdatedEmailEnabled);

    emailApi
      .emailNotificationToggleEnabledNotificationTypeGet("GiftCardUpdated")
      .then((response) => {
        if (response.data) {
          toast.success("Gift card updated email updated.");
        }
      })
      .catch((error: any) => {
        toast.error("Couldn't update email status.");
      });
  };

  useEffect(() => {
    emailApi
      .emailNotificationSettingsNotificationTypeGet("GiftCardReminder")
      .then((response) => {
        setReminderEmailEnabled(response.data.notificationEnabled!);
      })
      .catch((error: any) => {
        toast.error("Couldn't get settings.");
      });

    emailApi
      .emailNotificationSettingsNotificationTypeGet("GiftCardPurchased")
      .then((response) => {
        setGiftCardIssuedEmailEnabled(response.data.notificationEnabled!);
      })
      .catch((error: any) => {
        toast.error("Couldn't get settings.");
      });

    emailApi
      .emailNotificationSettingsNotificationTypeGet("GiftCardGifted")
      .then((response) => {
        setGiftCardGiftedEmailEnabled(response.data.notificationEnabled!);
      })
      .catch((error: any) => {
        toast.error("Couldn't get settings.");
      });

      emailApi
      .emailNotificationSettingsNotificationTypeGet("GiftCardUpdated")
      .then((response) => {
        setGiftCardUpdatedEmailEnabled(response.data.notificationEnabled!);
      })
      .catch((error: any) => {
        toast.error("Couldn't get settings.");
      });
  }, []);
  return (
    <React.Fragment>
            <LaunchBanner />
      <div className="container">
        <header>
          <div className="container-fluid">
            <div className="border-bottom pt-6">
              <div className="row align-items-center">
                <div className="col-sm-12 col-12">
                  <h1 className="h2 ls-tight">Emails</h1>
                </div>
                <p className="text-sm pb-5">
                  Automatically email your digital gift cards to recipients.{" "}
                  <a className="" target="_blank" href="https://help.wrappedgiftcards.com/en/articles/8152965-customize-your-emails">
                    Learn how to customize emails.
                  </a>
                </p>
              </div>
              <ul className="nav nav-tabs overflow-x border-0">
                <li className="nav-item">
                  <Link className="nav-link active" to={`/emails`}>
                    Emails
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={`/assets`}>
                    Gift card assets
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </header>
        <main className="py-6 bg-surface-secondary">
          <div className="container-fluid vstack gap-3">
            {/* <div
              className="bg-light position-relative d-flex p-2 rounded shadow-sm"
              role="alert"
            >
              <div className="w-1 bg-info rounded-pill"></div>
              <div className="ms-4 py-2 d-flex">
                <div className="pe-3 text-lg">
                  <i className="bi bi-info"></i>
                </div>
                <div className="">
                  <span className="d-block font-semibold text-heading">
                    Switch email provider
                  </span>
                  <p className="text-sm">
                    You will have the option to select your Gift Card email
                    provider after enabling one of our email integrations.{" "}
                    <a href="#" className="alert-link">
                      Go to integrations.
                    </a>
                  </p>
                </div>
              </div>
            </div> */}
            <div className="card">
              <div className="card-body pb-0">
                <div className="mb-3 d-flex align-items-center border-bottom pb-5">
                  <h5 className="mb-0">Customize your emails</h5>
                  {/* <div className="ms-auto text-end">
                    <a href="#" className="text-sm font-semibold">
                      Enable
                    </a>
                  </div> */}
                </div>
                <div className="list-group list-group-flush">
                  <div className="list-group-item d-flex align-items-center px-0">
                    <div className="ms-auto">
                    <FormCheck
                        name="giftCardIssuedEmailEnabled"
                        className=""
                        type="switch"
                        checked={giftCardIssuedEmailEnabled}
                        onChange={toggleGiftCardIssuedEnabled}
                      />
                    </div>
                    <div className="flex-fill pl-20">
                      <a
                        href="/notifications/email-edit/GiftCardPurchased"
                        className="d-block h6 font-semibold mb-1"
                      >
                        Gift card issued
                      </a>
                      <span className="d-block text-sm mb-2">
                        Sent to customer when a gift card is issued.
                      </span>
                      <div className="d-flex mx-n1"></div>
                    </div>
                    <div className="ms-auto text-end">
                      <a
                        href="/notifications/email-edit/GiftCardPurchased"
                        className="btn btn-sm btn-neutral mr-10"
                      >
                        Preview &amp; edit
                      </a>
                      {/* <a
                        href="/notifications/email-edit/GiftCardPurchasedPdf"
                        className="btn btn-sm btn-neutral"
                      >
                        Edit pdf
                      </a> */}
                    </div>
                  </div>
                  <div className="list-group-item d-flex align-items-center px-0">
                    <div className="ms-auto">
                      <FormCheck
                        name="giftCardUpdatedEmailEnabled"
                        className=""
                        type="switch"
                        checked={giftCardUpdatedEmailEnabled}
                        onChange={toggleGiftCardUpdatedEnabled}
                      />
                    </div>
                    <div className="flex-fill pl-20">
                      <a
                        href="/notifications/email-edit/GiftCardUpdated"
                        className="d-block h6 font-semibold mb-1"
                      >
                        Gift card updated
                      </a>
                      <span className="d-block text-sm mb-2">
                      Sent to customer when a gift card is updated.
                      </span>
                      <div className="d-flex mx-n1"></div>
                    </div>
                    <div className="ms-auto text-end">
                      <a
                        href="/notifications/email-edit/GiftCardUpdated"
                        className="btn btn-sm btn-neutral"
                      >
                        Preview &amp; edit
                      </a>
                    </div>
                  </div>
                  <div className="list-group-item d-flex align-items-center px-0">
                    <div className="ms-auto">
                      <FormCheck
                        name="giftCardGiftedEmailEnabled"
                        className=""
                        type="switch"
                        checked={giftCardGiftedEmailEnabled}
                        onChange={toggleGiftCardGiftedEnabled}
                      />
                    </div>
                    <div className="flex-fill pl-20">
                      <a
                        href="/notifications/email-edit/GiftCardGifted"
                        className="d-block h6 font-semibold mb-1"
                      >
                        Gift card gifted
                      </a>
                      <span className="d-block text-sm mb-2">
                        Sent to recipient to notify them of their gift, who it
                        is from and the gift card amount.
                      </span>
                      <div className="d-flex mx-n1"></div>
                    </div>
                    <div className="ms-auto text-end">
                      <a
                        href="/notifications/email-edit/GiftCardGifted"
                        className="btn btn-sm btn-neutral"
                      >
                        Preview &amp; edit
                      </a>
                    </div>
                  </div>
                  <div className="list-group-item d-flex align-items-center px-0">
                    <div className="ms-auto">
                      <FormCheck
                        name="reminderEmailEnabled"
                        className=""
                        type="switch"
                        checked={reminderEmailEnabled}
                        onChange={toggleReminderEnabled}
                      />
                    </div>
                    <div className="flex-fill pl-20">
                      <a
                        href="/notifications/email-edit/GiftCardReminder"
                        className="d-block h6 font-semibold mb-1"
                      >
                        Gift card reminder
                      </a>
                      <span className="d-block text-sm mb-2">
                        Remind customers who haven’t used their gift cards.
                      </span>
                      <div className="d-flex mx-n1"></div>
                    </div>
                    <div className="ms-auto text-end">
                      <button
                        onClick={() =>
                          setIsEmailReminderSettingsModalShown(true)
                        }
                        className="btn btn-sm btn-neutral mr-10"
                      >
                        Settings
                      </button>

                      <a
                        href="/notifications/email-edit/GiftCardReminder"
                        className="btn btn-sm btn-neutral"
                      >
                        Preview &amp; edit
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <EmailEditReminderSettingsModal
        show={isEmailReminderSettingsModalShown}
        onHide={() => setIsEmailReminderSettingsModalShown(false)}
      />
    </React.Fragment>
  );
};

export default EmailsListPage;
