import React, { useState } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";

interface AddressDetails {
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}


const GoogleAddressFinder: React.FC = () => {
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const [addressDetails, setAddressDetails] = useState<AddressDetails>({
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });

const libraries: ("places")[] = ["places"]; // Load Places library from Google Maps API


  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini (fmr. 'Swaziland')",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (North)",
    "Korea (South)",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];
  

  const handleLoad = (autocompleteInstance: google.maps.places.Autocomplete): void => {
    setAutocomplete(autocompleteInstance);
  };

  const handlePlaceChanged = (): void => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const components: { [key: string]: string } = {
        street_number: "",
        route: "",
        locality: "",
        administrative_area_level_1: "",
        postal_code: "",
        country: "",
      };

      if (place.address_components) {
        place.address_components.forEach((component) => {
          component.types.forEach((type) => {
            if (components.hasOwnProperty(type)) {
              components[type] = component.long_name;
            }
          });
        });

        setAddressDetails({
          address: `${components.street_number} ${components.route}`.trim(),
          city: components.locality,
          state: components.administrative_area_level_1,
          zip: components.postal_code,
          country: components.country,
        });
      }
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">Checkout</h1>
      <div className="row">
        {/* Left Section */}
        <div className="col-md-7">
          {/* Shipping Information */}
          <div className="p-4 border rounded mb-4">
            <h2 className="mb-4">Shipping Information</h2>
            <form>
              <div className="mb-3">
                <label htmlFor="fullName" className="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullName"
                  placeholder="Enter your name"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email Address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter your email"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="autocomplete" className="form-label">
                  Shipping Address
                </label>
                <LoadScript
                  googleMapsApiKey="AIzaSyA5pxYFOO0o-SDH9BYSNYa1F26KAPnPErA" // Replace with your Google API Key
                  libraries={libraries}
                >
                  <Autocomplete
                    onLoad={handleLoad}
                    onPlaceChanged={handlePlaceChanged}
                  >
                    <div className="input-group input-group-inline">
         
          <input
                      type="text"
                      id="autocomplete"
                      className="form-control"
                      placeholder="Start typing your address"
                    />
                     <span className="input-group-text" id="">
            <span className="bi bi-search"></span>
          </span>
        </div>
                    
                  </Autocomplete>
                </LoadScript>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    value={addressDetails.city}
                    placeholder="City"
                    readOnly
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="state" className="form-label">
                    State/Province
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="state"
                    value={addressDetails.state}
                    placeholder="State"
                    readOnly
                  />
                </div>
                <div className="col-md-2 mb-3">
                  <label htmlFor="zip" className="form-label">
                    ZIP
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="zip"
                    value={addressDetails.zip}
                    placeholder="ZIP"
                    readOnly
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="country" className="form-label">
                  Country
                </label>
                <select
                  id="country"
                  className="form-select"
                  value={addressDetails.country}
                  onChange={(e) =>
                    setAddressDetails({ ...addressDetails, country: e.target.value })
                  }
                >
                  <option value="">Select a country</option>
                  {countries.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleAddressFinder;
