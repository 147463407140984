import React, { useContext, useState, useEffect } from "react";
import TimezoneSelect from "react-timezone-select";
import { GiftcardApi, StorefrontApi } from "../../apiclient";
import {
  SalesChannelListItemViewModel,
  GiftCardProductViewModel,
  GiftCardProductVariant,
  KSeriesSettingPostViewModel,
  GiftCardExpiryViewModel,
  StorefrontSettingsViewModel,
  StorefrontFee,
  StorefrontTranslation,
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Modal,
  InputGroup,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { Link, useSearchParams } from "react-router-dom";

import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import Util from "../../js/util";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditStorefrontDomainModal from "./EditStorefrontDomainModal";
import StorefrontToggleEnabledBanner from "./StorefrontToggleEnabledBanner";
import SettingsNavMenu from "../../components/SettingsNavMenu";
import { CopyBlock, dracula } from "react-code-blocks";
import { useNavigate } from "react-router";

const StorefrontTranslationPage = () => {
  let storefrontApi = new StorefrontApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const authContext = useContext(AuthContext);
  const [model, setModel] = useState({} as StorefrontTranslation);
  const [settings, setSettings] = useState({} as StorefrontSettingsViewModel);
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
  const [isLoading, setLoading] = useState(false as boolean);
  const [imageData, setImageData] = useState(null as any);
  const [description, setDescription] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = () => {
    setLoading(true);
    storefrontApi
      .storefrontSettingsGet(authContext.account?.accountId)
      .then((response) => {
        var data = response.data;
        setSettings(data);
        setModel(data.translation!);
      })
      .catch((error: any) => {
        console.log("get error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setUnsavedChanges(JSON.stringify(model) !== JSON.stringify(settings.translation));
  }, [model]);

  const handleChange = (
    event: React.ChangeEvent<any>,
    component: keyof StorefrontTranslation,
    field: string
  ) => {
    const value = event.target.value;
    setModel((prevData) => ({
      ...prevData,
      [component]: {
        ...prevData[component],
        [field]: value,
      },
    }));

    //setUnsavedChanges(true);
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      storefrontApi
        .storefrontSaveTranslationPost(
          "1",
          model)
        .then((response: any) => {
          toast.success("Translation settings saved");
          setUnsavedChanges(false);
          fetchSettings();
        })
        .catch((error: any) => {
          toast.error("An error occurred when saving translation settings.");
          setUnsavedChanges(false);
        });
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <>
          <Spinner animation="border" />
        </>
      ) : (
        <>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <header className="position-sticky top-0 overlap-10 bg-surface-primary border-bottom">
              <div className="container-fluid py-4">
                <div className="row align-items-center">
                  <div className="col">
                    <div className="d-flex align-items-center gap-4">
                      <div>
                        <Link
                          className="bi bi-chevron-left text-xs"
                          to={`/account/storefront-settings`}
                        ></Link>
                      </div>
                      <div className="vr opacity-20 my-1"></div>
                      {/* <h1 className="h4 ls-tight">{title}</h1> */}
                      <div className="">
                        <h1 className="h2 ls-tight">Storefront translation settings</h1>
                        <p className="text-sm pb-5">
                          Customize all the text for the different components of your storefront.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col"></div>
                  <div className="col-auto">
                    <div className="hstack gap-2 justify-content-end">
                      {hasUnsavedChanges && (
                        <div className="text-sm text-muted font-semibold me-2 d-none d-md-block">
                          <span className="d-none d-sm-inline ms-2">
                            You have unsaved changes.
                          </span>
                        </div>
                      )}
                      <a
                        href={`https://${settings.wrappedStorefrontSubdomain}.wrappedgiftcards.store`}
                        target="_blank"
                        className="btn btn-sm btn-neutral ms-auto"
                      >
                        View <i className="bi bi-box-arrow-up-right"></i>
                      </a>
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                      >
                        <span>Save</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div className="bg-surface-secondary">
              <div className="container py-10">
                {model && (
                  <>
                    <div className="row mb-10">
                      <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                        <h4 className="font-semibold mb-2">Add to cart component</h4>
                        <p className="text-sm">
                          These settings will be used for your online storefront.
                        </p>
                      </div>
                      <div className="col-lg-8">
                        <div className="card shadow border-0">
                          <div className="card-body">
                            <div className="row g-5">
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Buy
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.addToCartComponent?.buy}
                                    onChange={(e) => handleChange(e, 'addToCartComponent', 'buy')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Top up
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.addToCartComponent?.topup}
                                    onChange={(e) => handleChange(e, 'addToCartComponent', 'topup')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Choose an amount
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.addToCartComponent?.chooseAmount}
                                    onChange={(e) => handleChange(e, 'addToCartComponent', 'chooseAmount')}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer text-end py-4">
                            <button type="submit" className="btn btn-sm btn-primary">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-10">
                      <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                        <h4 className="font-semibold mb-2">Check balance component</h4>
                        <p className="text-sm">
                          These settings will be used for your online storefront.
                        </p>
                      </div>
                      <div className="col-lg-8">
                        <div className="card shadow border-0">
                          <div className="card-body">
                            <div className="row g-5">
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Add funds / Top up
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.checkBalanceComponent?.addFunds}
                                    onChange={(e) => handleChange(e, 'checkBalanceComponent', 'addFunds')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Back
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.checkBalanceComponent?.back}
                                    onChange={(e) => handleChange(e, 'checkBalanceComponent', 'back')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Code
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.checkBalanceComponent?.code}
                                    onChange={(e) => handleChange(e, 'checkBalanceComponent', 'code')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Expires
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.checkBalanceComponent?.expires}
                                    onChange={(e) => handleChange(e, 'checkBalanceComponent', 'expires')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Gift card balance
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.checkBalanceComponent?.giftCardBalance}
                                    onChange={(e) => handleChange(e, 'checkBalanceComponent', 'giftCardBalance')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Remaining balance
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.checkBalanceComponent?.remainingBalance}
                                    onChange={(e) => handleChange(e, 'checkBalanceComponent', 'remainingBalance')}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer text-end py-4">
                            <button type="submit" className="btn btn-sm btn-primary">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-10">
                      <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                        <h4 className="font-semibold mb-2">Checkout page</h4>
                        <p className="text-sm">
                          These settings will be used for your online storefront.
                        </p>
                      </div>
                      <div className="col-lg-8">
                        <div className="card shadow border-0">
                          <div className="card-body">
                            <div className="row g-5">
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Buy more gift cards
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.checkoutPage?.buyMoreGiftCards}
                                    onChange={(e) => handleChange(e, 'checkoutPage', 'buyMoreGiftCards')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Check balance
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.checkoutPage?.checkBalance}
                                    onChange={(e) => handleChange(e, 'checkoutPage', 'checkBalance')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Check your gift card balance
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.checkoutPage?.checkYourGiftCardBalance}
                                    onChange={(e) => handleChange(e, 'checkoutPage', 'checkYourGiftCardBalance')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    No gift card found with the code
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.checkoutPage?.noGiftCardFound}
                                    onChange={(e) => handleChange(e, 'checkoutPage', 'noGiftCardFound')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Select top up amount
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.checkoutPage?.selectTopupAmount}
                                    onChange={(e) => handleChange(e, 'checkoutPage', 'selectTopupAmount')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Gift cards for omnichannel brands
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.checkoutPage?.wrappedTagLine}
                                    onChange={(e) => handleChange(e, 'checkoutPage', 'wrappedTagLine')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Your gift card for
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.checkoutPage?.yourGiftCardFor}
                                    onChange={(e) => handleChange(e, 'checkoutPage', 'yourGiftCardFor')}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer text-end py-4">
                            <button type="submit" className="btn btn-sm btn-primary">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-10">
                      <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                        <h4 className="font-semibold mb-2">Coming soon page</h4>
                        <p className="text-sm">
                          These settings will be used for your online storefront.
                        </p>
                      </div>
                      <div className="col-lg-8">
                        <div className="card shadow border-0">
                          <div className="card-body">
                            <div className="row g-5">
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Coming soon
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.comingSoonComponent?.comingSoon}
                                    onChange={(e) => handleChange(e, 'comingSoonComponent', 'comingSoon')}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer text-end py-4">
                            <button type="submit" className="btn btn-sm btn-primary">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-10">
                      <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                        <h4 className="font-semibold mb-2">Gift card details component</h4>
                        <p className="text-sm">
                          These settings will be used for your online storefront.
                        </p>
                      </div>
                      <div className="col-lg-8">
                        <div className="card shadow border-0">
                          <div className="card-body">
                            <div className="row g-5">
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Cart
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.cart}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'cart')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Continue
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.continue}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'continue')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Edit video message
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.editVideoMessage}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'editVideoMessage')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    For use at
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.forUseAt}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'forUseAt')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Gift card design
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.giftCardDesign}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'giftCardDesign')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Information
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.information}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'information')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Message
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.message}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'message')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Payment
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.payment}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'payment')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Quantity
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.quantity}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'quantity')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    What email address should we send the gift card to?
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.recipientEmailAddress}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'recipientEmailAddress')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Recipient name
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.recipientName}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'recipientName')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Record a video message (optional)
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.recordVideoMessage}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'recordVideoMessage')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Remove video
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.removeVideo}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'removeVideo')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Send at
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.sendAt}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'sendAt')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Send later
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.sendLater}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'sendLater')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Send now
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.sendNow}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'sendNow')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Someone else
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.someoneElse}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'someoneElse')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Timezone
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.timezone}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'timezone')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Your email address (for the receipt)
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.yourEmailAddress}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'yourEmailAddress')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Your name
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.yourName}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'yourName')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Yourself
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.giftCardDetailsComponent?.yourself}
                                    onChange={(e) => handleChange(e, 'giftCardDetailsComponent', 'yourself')}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer text-end py-4">
                            <button type="submit" className="btn btn-sm btn-primary">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-10">
                      <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                        <h4 className="font-semibold mb-2">Order summary component</h4>
                        <p className="text-sm">
                          These settings will be used for your online storefront.
                        </p>
                      </div>
                      <div className="col-lg-8">
                        <div className="card shadow border-0">
                          <div className="card-body">
                            <div className="row g-5">
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Add promotion code
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.orderSummaryComponent?.addPromotionalCode}
                                    onChange={(e) => handleChange(e, 'orderSummaryComponent', 'addPromotionalCode')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Apply
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.orderSummaryComponent?.apply}
                                    onChange={(e) => handleChange(e, 'orderSummaryComponent', 'apply')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    By email
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.orderSummaryComponent?.byEmail}
                                    onChange={(e) => handleChange(e, 'orderSummaryComponent', 'byEmail')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Code
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.orderSummaryComponent?.code}
                                    onChange={(e) => handleChange(e, 'orderSummaryComponent', 'code')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    This code is invalid
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.orderSummaryComponent?.codeIsInvalid}
                                    onChange={(e) => handleChange(e, 'orderSummaryComponent', 'codeIsInvalid')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Discount
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.orderSummaryComponent?.discount}
                                    onChange={(e) => handleChange(e, 'orderSummaryComponent', 'discount')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    For
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.orderSummaryComponent?.for}
                                    onChange={(e) => handleChange(e, 'orderSummaryComponent', 'for')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Message
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.orderSummaryComponent?.message}
                                    onChange={(e) => handleChange(e, 'orderSummaryComponent', 'message')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Order summary
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.orderSummaryComponent?.orderSummary}
                                    onChange={(e) => handleChange(e, 'orderSummaryComponent', 'orderSummary')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Recipient name
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.orderSummaryComponent?.recipientName}
                                    onChange={(e) => handleChange(e, 'orderSummaryComponent', 'recipientName')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Send time
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.orderSummaryComponent?.sendTime}
                                    onChange={(e) => handleChange(e, 'orderSummaryComponent', 'sendTime')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Shipping
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.orderSummaryComponent?.shipping}
                                    onChange={(e) => handleChange(e, 'orderSummaryComponent', 'shipping')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Total
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.orderSummaryComponent?.total}
                                    onChange={(e) => handleChange(e, 'orderSummaryComponent', 'total')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Your name
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.orderSummaryComponent?.yourName}
                                    onChange={(e) => handleChange(e, 'orderSummaryComponent', 'yourName')}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer text-end py-4">
                            <button type="submit" className="btn btn-sm btn-primary">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-10">
                      <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                        <h4 className="font-semibold mb-2">Payment complete page</h4>
                        <p className="text-sm">
                          These settings will be used for your online storefront.
                        </p>
                      </div>
                      <div className="col-lg-8">
                        <div className="card shadow border-0">
                          <div className="card-body">
                            <div className="row g-5">
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Code
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.paymentCompletePage?.code}
                                    onChange={(e) => handleChange(e, 'paymentCompletePage', 'code')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Gift card
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.paymentCompletePage?.giftCard}
                                    onChange={(e) => handleChange(e, 'paymentCompletePage', 'giftCard')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Gift cards
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.paymentCompletePage?.giftCards}
                                    onChange={(e) => handleChange(e, 'paymentCompletePage', 'giftCards')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Order received
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.paymentCompletePage?.orderReceived}
                                    onChange={(e) => handleChange(e, 'paymentCompletePage', 'orderReceived')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    {"Thank you for your order, payment of {{paymentAmount}} has been taken. <br />We've just sent an email to {{recipientEmailAddress}}."}
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.paymentCompletePage?.thankyou}
                                    onChange={(e) => handleChange(e, 'paymentCompletePage', 'thankyou')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    {"Thank you for your order, payment of {{paymentAmount}} has been taken. We'll send the gift card by post."}
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.paymentCompletePage?.thankyouByPost}
                                    onChange={(e) => handleChange(e, 'paymentCompletePage', 'thankyouByPost')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    View
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.paymentCompletePage?.view}
                                    onChange={(e) => handleChange(e, 'paymentCompletePage', 'view')}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer text-end py-4">
                            <button type="submit" className="btn btn-sm btn-primary">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-10">
                      <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                        <h4 className="font-semibold mb-2">Payment component</h4>
                        <p className="text-sm">
                          These settings will be used for your online storefront.
                        </p>
                      </div>
                      <div className="col-lg-8">
                        <div className="card shadow border-0">
                          <div className="card-body">
                            <div className="row g-5">
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Cart
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.paymentComponent?.cart}
                                    onChange={(e) => handleChange(e, 'paymentComponent', 'cart')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Information
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.paymentComponent?.information}
                                    onChange={(e) => handleChange(e, 'paymentComponent', 'information')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Payment
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.paymentComponent?.payment}
                                    onChange={(e) => handleChange(e, 'paymentComponent', 'payment')}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer text-end py-4">
                            <button type="submit" className="btn btn-sm btn-primary">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-10">
                      <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                        <h4 className="font-semibold mb-2">Video recorder modal</h4>
                        <p className="text-sm">
                          These settings will be used for your online storefront.
                        </p>
                      </div>
                      <div className="col-lg-8">
                        <div className="card shadow border-0">
                          <div className="card-body">
                            <div className="row g-5">
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Accept
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.videoRecorderModal?.accept}
                                    onChange={(e) => handleChange(e, 'videoRecorderModal', 'accept')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Information
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.videoRecorderModal?.information}
                                    onChange={(e) => handleChange(e, 'videoRecorderModal', 'information')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Payment
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.videoRecorderModal?.payment}
                                    onChange={(e) => handleChange(e, 'videoRecorderModal', 'payment')}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer text-end py-4">
                            <button type="submit" className="btn btn-sm btn-primary">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-10">
                      <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                        <h4 className="font-semibold mb-2">Shipping details component</h4>
                        <p className="text-sm">
                          These settings will be used for your online storefront.
                        </p>
                      </div>
                      <div className="col-lg-8">
                        <div className="card shadow border-0">
                          <div className="card-body">
                            <div className="row g-5">
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    By post
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.shippingDetailsComponent?.byPost}
                                    onChange={(e) => handleChange(e, 'shippingDetailsComponent', 'byPost')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    By email
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.shippingDetailsComponent?.byEmail}
                                    onChange={(e) => handleChange(e, 'shippingDetailsComponent', 'byEmail')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Recipient name
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.shippingDetailsComponent?.recipientName}
                                    onChange={(e) => handleChange(e, 'shippingDetailsComponent', 'recipientName')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Address 1
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.shippingDetailsComponent?.address1}
                                    onChange={(e) => handleChange(e, 'shippingDetailsComponent', 'address1')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Start typing your address
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.shippingDetailsComponent?.address1Placeholder}
                                    onChange={(e) => handleChange(e, 'shippingDetailsComponent', 'address1Placeholder')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                  Street address
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.shippingDetailsComponent?.streetAddress}
                                    onChange={(e) => handleChange(e, 'shippingDetailsComponent', 'streetAddress')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                  Address 2
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.shippingDetailsComponent?.address2}
                                    onChange={(e) => handleChange(e, 'shippingDetailsComponent', 'address2')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Apartment, suite, unit, building, floor, etc.
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.shippingDetailsComponent?.address2Help}
                                    onChange={(e) => handleChange(e, 'shippingDetailsComponent', 'address2Help')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    City
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.shippingDetailsComponent?.city}
                                    onChange={(e) => handleChange(e, 'shippingDetailsComponent', 'city')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    State
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.shippingDetailsComponent?.state}
                                    onChange={(e) => handleChange(e, 'shippingDetailsComponent', 'state')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Country
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.shippingDetailsComponent?.country}
                                    onChange={(e) => handleChange(e, 'shippingDetailsComponent', 'country')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Select a country
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.shippingDetailsComponent?.countryPlaceholder}
                                    onChange={(e) => handleChange(e, 'shippingDetailsComponent', 'countryPlaceholder')}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="">
                                  <label className="form-label">
                                    Zip
                                  </label>
                                  <Form.Control
                                    className="form-control"
                                    type="text"
                                    value={model.shippingDetailsComponent?.zip}
                                    onChange={(e) => handleChange(e, 'shippingDetailsComponent', 'zip')}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer text-end py-4">
                            <button type="submit" className="btn btn-sm btn-primary">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              </div>
            </div>
          </Form>
        </>
      )}
    </React.Fragment>
  );
};

export default StorefrontTranslationPage;
