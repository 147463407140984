import React, { useContext, useState, useEffect } from "react";
import TimezoneSelect from "react-timezone-select";
import { GiftcardApi } from "../../apiclient";
import {
  EmailNotificationType,
  GiftCardDetailViewModel,
  GiftCardReasonType,
  GiftCardTimelineEventViewModel,
  PerformSyncGiftCardViewModel,
  Platform,
  GiftCardSaveNotesModel
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  ButtonGroup,
  Dropdown,
  InputGroup,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import {
  Link,
  useSearchParams,
  useNavigate,
  useParams,
} from "react-router-dom";
import * as Swal from "sweetalert2";
import Util from "../../js/util";
import GiftCardManualAdjustmentModalComponent from "./GiftCardManualAdjustmentModalComponent";
import GiftCardSyncErrorBannerComponent from "./GiftCardSyncErrorBannerComponent";
import GiftCardEmailEventsModalComponent from "./GiftCardEmailEventsModalComponent";
import GiftCardCustomerDetailsEditModalComponent from "./GiftCardCustomerDetailsEditModalComponent";
import GiftCardEditShippingDetailsModalComponent from "./GiftCardEditShippingDetailsModalComponent";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/relativeTime"));

const GiftCardDetailPage = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  let giftcardApi = new GiftcardApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const authContext = useContext(AuthContext);
  const [model, setModel] = useState({} as GiftCardDetailViewModel);
  const [timelineEvents, setTimelineEvents] = useState(
    [] as GiftCardTimelineEventViewModel[]
  );
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
  const [isLoading, setLoading] = useState(false as boolean);
  const [redeemedPercent, setRedeemedPercent] = useState(100 as number);
  const [showManualAdjustmentModel, setShowManualAdjustmentModal] = useState(
    false as boolean
  );
  const [showEmailEventsModal, setShowEmailEventsModal] = useState(false as boolean);
  const [showEditCustomerDetailsModal, setShowEditCustomerDetailsModal] = useState(false as boolean);
  const [showEditShippingDetailsModal, setShowEditShippingDetailsModal] = useState(false as boolean);
  const [btnResyncButtonIsSubmitting, setBtnResyncButtonIsSubmitting] =
    useState(false as boolean);
  const [refreshCount, setRefreshCount] = useState(1 as number);
  const [refreshTimelineBtnText, setRefreshTimelineBtnText] =
    useState("Refresh timeline");

  useEffect(() => {
    performRefresh();
  }, []);

  const performRefresh = () => {
    fetchData();
    fetchTimelineEventsData();

    setRefreshCount(refreshCount + 1);
  };

  const onRefreshTimelineClicked = () => {
    setRefreshTimelineBtnText("Refreshing..");
    performRefresh();
  };

  const fetchData = () => {
    giftcardApi
      .giftcardGetByIdIdGet(id!)
      .then((response) => {
        var data = response.data;
        setModel(data);
        setLoading(false);
        var percent = Math.round(
          (data.balance! / data.totalIssuedAmount!) * 100
        );
        setRedeemedPercent(percent);
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  const fetchTimelineEventsData = () => {
    giftcardApi
      .giftcardGetTimelineEventsByGiftCardIdGet(id!)
      .then((response) => {
        var data = response.data.result;
        setTimelineEvents(data!);
        setRefreshTimelineBtnText("Refresh timeline");
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  const markAsShipped = () => {
    if (model.deliveryStatus === "PendingShipping") {
      giftcardApi
        .giftcardMarkAsShippedPost("1", {
          giftCardId: id!,
          code: model.code
        })
        .then((response) => {
          var data = response.data;
          if (data) {
            toast.success(
              "Gift card has been marked as shipped."
            );

            onBtnResyncClicked();
          }
        })
        .catch((error: any) => {
          toast.error(
            "An error occurred when marking as shipped."
          );
        });
    } else {
      toast.error("Delivery status must be 'To Ship'");
    }
  };

  const sendGiftCardEmail = (emailType: string) => {
    if (model.recipientEmailAddress) {
      giftcardApi
        .giftcardResendEmailByTypeGet(id, emailType)
        .then((response) => {
          var data = response.data;
          if (data) {
            toast.success(
              "Email sent to " + model.recipientEmailAddress
            );

            fetchTimelineEventsData();
          }
        })
        .catch((error: any) => {
          toast.error(
            "Unable to send gift card email."
          );
        });
    } else {
      toast.error("Please enter a valid email address.");
    }
  };

  const voidGiftCard = () => {
    Swal.default
      .fire({
        title: "Are you sure?",
        text: `This will void the gift card in all sales channels. This cannot be undone.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, void gift card",

        confirmButtonColor: "#d9534f",
      })
      .then((result) => {
        if (result.isConfirmed) {
          giftcardApi
            .giftcardVoidGiftCardByIdIdGet(id!)
            .then((response) => {
              var data = response.data;
              if (data) {
                toast.success("Gift card voided.");
                performRefresh();
              } else {
                toast.error("Couldn't void gift card.");
              }
            })
            .catch((error: any) => {
              toast.error("Couldn't void gift card.");
            });
        }
      });
  };

  const toggleIsPromoGiftCard = () => {
    giftcardApi
      .giftcardToggleIsPromotionalGiftCardByIdIdGet(id!)
      .then((response) => {
        var data = response.data;
        if (data) {
          toast.success("Gift card promo flag updated.");
          performRefresh();
        } else {
          toast.error("Couldn't update promo flag gift card.");
        }
      })
      .catch((error: any) => {
        toast.error("Couldn't update promo flag gift card.");
      });
  };

  const deleteGiftCard = () => {
    Swal.default
      .fire({
        title: "Are you sure?",
        text: `This will delete the gift card in all sales channels and hide from dashboard. This cannot be undone.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete gift card",

        confirmButtonColor: "#d9534f",
      })
      .then((result) => {
        if (result.isConfirmed) {
          giftcardApi
            .giftcardDeleteGiftCardByIdIdGet(id!)
            .then((response) => {
              var data = response.data;
              if (data) {
                toast.success("Gift card deleted.");
                performRefresh();
              } else {
                toast.error("Couldn't delete gift card.");
              }
            })
            .catch((error: any) => {
              toast.error("Couldn't delete gift card.");
            });
        }
      });
  };

  const getIconByPlatform = (platform: string) => {
    if (platform === "Shopify") return "/img/integrations/shopify-bag.png";
    else if (
      platform === Platform.Vend ||
      platform == Platform.Kounta ||
      platform == Platform.KSeries ||
      platform == Platform.Ecwid ||
      platform == Platform.RSeries ||
      platform == Platform.CSeries
    )
      return "/img/integrations/Lightspeed_Flame.svg";
    else if (platform === "WooCommerce")
      return "/img/integrations/woocommerce.svg";
    else if (platform === "BigCommerce")
      return "/img/integrations/bigcommerce-144.png";
    else if (platform === "WrappedPublicApi")
      return "/img/integrations/api-50.png";
    else if (platform === "Bopple") return "/img/integrations/bopple-icon2.png";
    else if (platform == "WrappedStorefront")
      return "/img/logos/wrapped-icon.png";
    else if (platform == "Klaviyo")
      return "/img/integrations/klaviyo.png";
    else if (platform == "HeartlandRetail")
      return "/img/integrations/heartland-icon.png";
    else if (platform == "Square")
      return "/img/integrations/Square_Jewel_Black.svg";
    else if (platform == "Hike")
      return "/img/integrations/hike-logo.png";
    else return "/favicon/favicon-32x32.png";
  };

  const renderAdjustmentListItem = (
    adjustment: GiftCardTimelineEventViewModel,
    i: number
  ) => {
    return (
      <div key={"channel-item" + i} className="list-group-item px-2 py-0">
        <div className="border-start">
          <div className="d-flex ms-n2 pb-6">
            <div className="me-4">
              <div className="icon icon-shape text-sm w-4 h-4 bg-secondary rounded-circle"></div>
            </div>
            <div className="flex-fill h6 font-semibold lh-none text-heading d-inline-block mb-1">
              <img
                alt={adjustment.orginatingPlatform}
                src={getIconByPlatform(adjustment.orginatingPlatform!)}
                className="adjustmentPlatformIcon"
              />{" "}
              {adjustment.description}{" "}
              {adjustment.originatingOrderId && (
                <>
                  <a
                    href={`/Redirect/ToOrder/${adjustment.originatingOrderId}`}
                    className="text-primary-hover"
                    target="_blank"
                  >
                    (view order)
                  </a>
                </>
              )}
              {adjustment.description && adjustment.description.includes("email sent") && (
                <a
                  onClick={() => setShowEmailEventsModal(true)}
                  className="text-primary-hover cursor-pointer"
                >
                  (view events)
                </a>
              )}
            </div>
            <div className="ms-auto text-end">
              <small className="text-xs text-muted">
                {dayjs(adjustment.actionedAt)
                  .utc()
                  .tz(authContext?.account?.ianaTimezone)
                  .format("YYYY-MM-DD hh:mm a")}
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleChange = (event: any) => {
    const target = event.target;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    const name = event.target.name;
    if (name === "hasExpiration") {
      const booVal = event.currentTarget.value === "true" ? true : false;
      value = booVal;
    }
    setModel((values: GiftCardDetailViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleSaveNotesSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);

      var postModel = {id: model.id, notes: model.notes} as GiftCardSaveNotesModel;
      giftcardApi
        .giftcardSaveGiftCardNotesPost("1", postModel)
        .then((response) => {
          toast.success("Gift card notes saved");
          setUnsavedChanges(false);
          fetchTimelineEventsData();
          //navigate("/gift-cards");
        })
        .catch((error: any) => {
          console.log('error', error);
          toast.error("An error occurred when saving card.");
          setUnsavedChanges(false);
        });
    }
  };

  const onBtnResyncClicked = () => {
    setBtnResyncButtonIsSubmitting(true);
    giftcardApi
      .giftcardPerformSyncGiftCardPost("1", {
        giftCardId: model.id,
      } as PerformSyncGiftCardViewModel)
      .then((response) => {
        var result = response.data as boolean;
        if (result) {
          toast.success(
            "The gift card has been synced to connected platforms",
            {
              duration: 5000,
            }
          );
        } else {
          toast.error("There was an error re-syncing your gift card");
        }

        setBtnResyncButtonIsSubmitting(false);
        performRefresh();
      })
      .catch(function (error) {
        toast.error("There was an error re-syncing the gift card");
        console.log("resync error", error);
        setBtnResyncButtonIsSubmitting(false);
      });
  };

  const renderStatusBadge = () => {
    var badgeColor = "bg-success text-success";
    var cardStatus = "Redeemable";
    if (model.status === "Disabled") {
      cardStatus = "Disabled";
      badgeColor = "bg-danger text-danger";
    } else if (model.totalIssuedAmount == 0) {
      cardStatus = "Empty";
      badgeColor = "bg-secondary text-secondary";
    } else if (model.balance! <= 0) {
      cardStatus = "Redeemed";
      badgeColor = "bg-secondary text-secondary";
    } else if (model.balance === model.initialValue) {
      cardStatus = "Full";
    } else if (model.balance !== model.initialValue && model.balance! > 0) {
      cardStatus = "Partial";
    }

    var deliveryBadgeColor = "bg-success text-success";
    var deliveryStatus = "Sent";
    if (model.deliveryStatus === "PendingCollection") {
      deliveryStatus = "To be collected";
      deliveryBadgeColor = "bg-warning text-warning";
    } else if (model.deliveryStatus === "PendingShipping") {
      deliveryStatus = "To ship";
      deliveryBadgeColor = "bg-warning text-warning";
    } else if (model.deliveryStatus === "Collected") {
      deliveryStatus = "Shipped";
      deliveryBadgeColor = "bg-success text-success";
    } else if (model.deliveryStatus === "Shipped") {
      deliveryStatus = "Shipped";
      deliveryBadgeColor = "bg-success text-success";
    }
    return (
      <React.Fragment>
        <span className={`badge text-uppercase bg-opacity-30 ${badgeColor}`}>
          {cardStatus}
        </span>
        {(model.deliveryStatus === "PendingShipping" || model.deliveryStatus === "Shipped" || model.recipientEmailAddress && model.deliveryStatus === "Sent") &&
          <span
            className={`badge text-uppercase bg-opacity-30 ${deliveryBadgeColor}`}
          >
            {deliveryStatus}
          </span>}

      </React.Fragment>
    );
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(function () {
        toast.success("Copied to clipboard");
      })
      .catch(function (error) {
        toast.error("Failed to copy to clipboard");
      });
  };

  const renderReasonBadge = () => {
    var badgeColor = "bg-dark text-dark";
    var cardReason = model.reasonType?.toString();
    if (model.reasonType === GiftCardReasonType.Refund) {
      badgeColor = "bg-danger text-danger";
    } else if (model.reasonType === GiftCardReasonType.GeneratedFromBatch) {
      cardReason = "Batch (Generated)";
    } else if (model.reasonType === GiftCardReasonType.ConvertedInstoreOnly) {
      cardReason = "Instore only";
    } else if (model.reasonType === GiftCardReasonType.ImportedFromBatchCsv) {
      cardReason = "Batch (CSV)";
    } else if (model.reasonType === GiftCardReasonType.CsvImport) {
      cardReason = "Import (CSV)";
    }

    return (
      <React.Fragment>
        <span className={`badge text-uppercase bg-opacity-30 ${badgeColor}`}>
          {cardReason}
        </span>
        {model.isPromotional &&
          <span className={`ml-5 badge text-uppercase bg-opacity-30 bg-primary text-primary`}>Promo</span>}
      </React.Fragment>
    );
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <>
          <Spinner animation="border" />
        </>
      ) : (
        <>
          {model.status === "Disabled" && (
            <div className="alert alert-danger" role="alert">
              <div className="d-flex align-items-center">
                <div className="w-8 text-lg">
                  <i className="bi bi-exclamation-circle-fill"></i>
                </div>
                <span className="font-bold">
                  This gift card has been voided.
                </span>
              </div>
            </div>
          )}
          {model.status === "Deleted" && (
            <div className="alert alert-danger" role="alert">
              <div className="d-flex align-items-center">
                <div className="w-8 text-lg">
                  <i className="bi bi-exclamation-circle-fill"></i>
                </div>
                <span className="font-bold">
                  This gift card has been deleted.
                </span>
              </div>
            </div>
          )}

          {model && model.id && refreshCount && (
            <GiftCardSyncErrorBannerComponent giftCardId={model.id!} />
          )}

          <Form noValidate validated={validated} onSubmit={handleSaveNotesSubmit}>
            <header className="position-sticky top-0 overlap-10 bg-surface-primary border-bottom">
              <div className="container-fluid py-4">
                <div className="row align-items-center">
                  <div className="col">
                    <div className="d-flex align-items-center gap-4">
                      <div>
                        <Link
                          className="bi bi-chevron-left text-xs"
                          to={`/gift-cards`}
                        ></Link>
                      </div>
                      <div className="vr opacity-20 my-1"></div>
                      {authContext.account?.userRole == "Admin" ||
                        authContext.account?.userRole == "Owner" ? (
                        <>
                          <h1 className="lh-tight ls-tighter font-bolder h2 mb-2">
                            {model.code}
                          </h1>{" "}
                          {renderStatusBadge()}
                          <button
                            type="button"
                            onClick={() => {
                              copyToClipboard(model.code ?? "");
                            }}
                            className="btn btn-sm btn-neutral btn-icon "
                          >
                            <span className="bi bi-clipboard"></span>
                          </button>
                        </>
                      ) : (
                        <h1 className="lh-tight ls-tighter font-bolder h2 mb-2">
                          •••• •••• •••• {model.code} {renderStatusBadge()}
                        </h1>
                      )}
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="hstack gap-2 justify-content-end">
                      {authContext.account?.userRole != "Readonly" && (
                        <>
                          <div className="text-end mb-5 mb-lg-0">
                            {/* <Dropdown as={ButtonGroup} className="mr-10">
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={voidGiftCard}
                                disabled={model.status == "Disabled"}
                              >
                                Void
                              </Button>

                              <Dropdown.Toggle
                                split
                                size="sm"
                                variant="danger"
                                id="dropdown-split-basic"
                              />

                              <Dropdown.Menu>
                                <Dropdown.Item onClick={deleteGiftCard}>
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown> */}
                            <Dropdown className="mr-10">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                                size="sm"
                              >
                                Actions
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    openInNewTab(`/Preview/GiftCard/${id}`)
                                  }
                                >
                                  Preview{" "}
                                  <i className="bi bi-box-arrow-up-right"></i>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={onBtnResyncClicked}
                                  disabled={btnResyncButtonIsSubmitting}
                                >
                                  {btnResyncButtonIsSubmitting
                                    ? "Syncing.."
                                    : "Re-sync balance to platforms"}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => setShowEmailEventsModal(true)}>
                                  Show email events timeline
                                </Dropdown.Item>
                                <Dropdown.Item href={`/GiftCard/Pdf/${id}`}>
                                  Download PDF
                                </Dropdown.Item>
                                <Dropdown.Item onClick={toggleIsPromoGiftCard}>
                                  {!model.isPromotional ? "Set as promotional" : "Unset as promotional"}
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                  className="text-danger"
                                  onClick={voidGiftCard}
                                  disabled={model.status == "Disabled"}
                                >
                                  Void
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className="text-danger"
                                  onClick={deleteGiftCard}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>


                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </header>

            <div className="bg-surface-secondary">
              <div className="container py-10">
                <div className="row mb-10">
                  <div className="col-lg-8">
                    <div className="card mb-10">
                      <div className="card-body">
                        <div className="row g-5">
                          <div className="col-12">
                            <h4 className="font-semibold">Gift card details</h4>
                            <div className="">
                              <label className="form-label" htmlFor="code">
                                Current balance
                              </label>
                              {(authContext.account?.userRole == "Admin" ||
                                authContext.account?.userRole == "Owner") && (
                                  <>
                                    <div className="float-end">
                                      <a
                                        onClick={() =>
                                          setShowManualAdjustmentModal(true)
                                        }
                                        className="text-sm font-semibold cursor-pointer"
                                      >
                                        Adjust balance
                                      </a>
                                    </div>
                                  </>
                                )}

                              <div className="ms-auto text-end">
                                <div className="h3">
                                  {model.formattedBalance} /{" "}
                                  {model.formattedTotalIssuedAmount}{" "}
                                  {model.isoCurrencyCode}
                                </div>
                              </div>
                              <div className="col px-0 text-end mt-3">
                                <div style={{ width: "100%" }}>
                                  <div
                                    className="progress progress-sm shadow-none"
                                    style={{ height: "15px" }}
                                  >
                                    <div
                                      className="progress-bar bg-primary"
                                      role="progressbar"
                                      style={{
                                        width: redeemedPercent + "%",
                                      }}
                                      aria-valuenow={redeemedPercent}
                                      aria-valuemin={0}
                                      aria-valuemax={100}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="row">
                                {model.purchasedOnOrderSourceId && (
                                  <div className="col">
                                    <div>Order</div>
                                    <a
                                      href={`/Redirect/ToOrder/${model.platformOrderId}`}
                                      className="text-link"
                                      target="_blank"
                                    >
                                      #{model.orderTitle}
                                    </a>
                                  </div>
                                )}
                                <div className="col">
                                  {model.expiresOn && (
                                    <>
                                      <div>Expiration date</div>
                                      {dayjs(model.expiresOn)
                                        .utc()
                                        .tz(authContext?.account?.ianaTimezone)
                                        .format("YYYY-MM-DD hh:mm a")}
                                    </>
                                  )}
                                </div>
                                <div className="col">
                                  <div>Reason: {renderReasonBadge()}</div>
                                  <div>
                                    Issued:{" "}
                                    {dayjs(model.dateIssued)
                                      .utc()
                                      .tz(authContext?.account?.ianaTimezone)
                                      .format("YYYY-MM-DD hh:mm a")}
                                  </div>
                                  {model.giftScheduledFor && (
                                    <>
                                      <div>Gift scheduled for</div>
                                      <span>
                                        {dayjs(model.giftScheduledFor)
                                          .utc()
                                          .tz(
                                            authContext?.account?.ianaTimezone
                                          )
                                          .format("YYYY-MM-DD hh:mm a")}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="contatiner">
                      <a
                        onClick={onRefreshTimelineClicked}
                        className="text-sm font-semibold cursor-pointer float-end"
                      >
                        {refreshTimelineBtnText}
                      </a>
                      <h6 className="text-muted font-semibold mb-5">
                        Timeline
                      </h6>
                      <div className="list-group list-group-flush list-group-borderless">
                        {timelineEvents &&
                          timelineEvents?.map(
                            (
                              adjustment: GiftCardTimelineEventViewModel,
                              i: number
                            ) => renderAdjustmentListItem(adjustment, i)
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                    {model.deliveryStatus === "PendingShipping" && model.shippingAddress && model.shippingAddress.address1 && model.shippingOption &&
                      <div className="alert alert-info border-info mb-5" role="alert">
                        <div className="alert-body">
                          <div className="mb-2 d-flex align-items-center">
                            <h4 className="mb-0">This gift card needs shipping</h4>
                          </div>
                          <p className="text-muted text-sm">The code below was created provisionally when sold and can be changed before you ship it, if required.</p>

                          <div className="list-group list-group-flush">
                            <div className="list-group-item d-flex align-items-center px-0">
                              <div className="flex-fill">
                                <div className="d-block h6 font-semibold mb-1">Ship using</div>
                                <span className="d-block text-sm mb-2">{model.shippingOption.name ?? "Shipping"}</span>
                                <div className="d-flex mx-n1">
                                </div>
                              </div>
                            </div>
                            {model.buyerName &&
                              <div className="list-group-item d-flex align-items-center px-0">
                                <div className="flex-fill">
                                  <div className="d-block h6 font-semibold mb-1">Buyer name</div>
                                  <span className="d-block text-sm mb-2">{model.buyerName}</span>
                                  <div className="d-flex mx-n1">
                                  </div>
                                </div>
                              </div>
                            }
                            {model.buyerEmailAddress &&
                              <div className="list-group-item d-flex align-items-center px-0">
                                <div className="flex-fill">
                                  <div className="d-block h6 font-semibold mb-1">Buyer email address</div>
                                  <span className="d-block text-sm mb-2">{model.buyerEmailAddress}</span>
                                  <div className="d-flex mx-n1">
                                  </div>
                                </div>
                              </div>
                            }
                            <div className="list-group-item d-flex align-items-center px-0">
                              <div className="flex-fill">
                                <div className="d-block h6 font-semibold mb-1">Address (<a
                                  onClick={() =>
                                    setShowEditShippingDetailsModal(true)
                                  }
                                  className="text-sm font-semibold cursor-pointer"
                                >
                                  Edit
                                </a>)</div>
                                <span className="d-block text-sm mb-2">
                                  <div className="pb-3">
                                    <div>{model.shippingAddress?.name}</div>
                                    <div>{model.shippingAddress?.address1}</div>
                                    {model.shippingAddress && model.shippingAddress.address2 &&
                                      <div>{model.shippingAddress?.address2}</div>}
                                    <div>{model.shippingAddress?.city}, {model.shippingAddress?.state} </div>
                                    <div>{model.shippingAddress?.country}</div>
                                  </div>
                                </span>
                                <div className="d-flex mx-n1">
                                </div>
                              </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center px-0">
                                <div className="flex-fill">
                                <div className="d-block h6 font-semibold mb-1">Gift card code (provisional)</div>
                          <Form.Control
                            name="code"
                            value={model.code}
                            onChange={handleChange}
                          />
                          <Button onClick={() => markAsShipped()} variant="primary" type="button" className="w-full mt-2">Mark as shipped</Button>
                                </div>
                              </div>
                          </div>
                          {/* <InputGroup className="mb-3">
                            <Form.Control
                              name="code"
                              value={model.code}
                              onChange={handleChange}
                            />
                            <Button variant="neutral" id="button-addon2">
                              <span> Scan <i className="bi bi-camera text-dark"></i></span>
                            </Button>
                          </InputGroup> */}
                          
                        </div>
                      </div>
                    }
                    {model.deliveryStatus === "Shipped" && model.shippingAddress && model.shippingAddress.address1 && model.shippingOption &&
                      <div className="card mb-5">
                        <div className="">
                          <div className="card-header mb-2 d-flex align-items-center border-bottom">
                            <h5 className="mb-0">Delivery details</h5>
                          </div>
                          <div className="card-body list-group list-group-flush pt-0">
                            <div className="list-group-item d-flex align-items-center px-0">
                              <div className="flex-fill">
                                <div className="d-block h6 font-semibold mb-1">Ship using</div>
                                <span className="d-block text-sm mb-2">{model.shippingOption.name ?? "Shipping"}</span>
                                <div className="d-flex mx-n1">
                                </div>
                              </div>
                            </div>
                            {model.buyerName &&
                              <div className="list-group-item d-flex align-items-center px-0">
                                <div className="flex-fill">
                                  <div className="d-block h6 font-semibold mb-1">Buyer name</div>
                                  <span className="d-block text-sm mb-2">{model.buyerName}</span>
                                  <div className="d-flex mx-n1">
                                  </div>
                                </div>
                              </div>
                            }
                            {model.buyerEmailAddress &&
                              <div className="list-group-item d-flex align-items-center px-0">
                                <div className="flex-fill">
                                  <div className="d-block h6 font-semibold mb-1">Buyer email address</div>
                                  <span className="d-block text-sm mb-2">{model.buyerEmailAddress}</span>
                                  <div className="d-flex mx-n1">
                                  </div>
                                </div>
                              </div>
                            }
                            <div className="list-group-item d-flex align-items-center px-0 pb-0">
                              <div className="flex-fill">
                                <div className="d-block h6 font-semibold mb-1">Address</div>
                                <span className="d-block text-sm mb-2">
                                  <div className="pb-3">
                                    <div>{model.shippingAddress?.name}</div>
                                    <div>{model.shippingAddress?.address1}</div>
                                    {model.shippingAddress && model.shippingAddress.address2 &&
                                      <div>{model.shippingAddress?.address2}</div>}
                                    <div>{model.shippingAddress?.city}, {model.shippingAddress?.state} </div>
                                    <div>{model.shippingAddress?.country}</div>
                                  </div>
                                </span>
                                <div className="d-flex mx-n1">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="card mb-10">
                      <div className="card-header border-bottom mb-2 d-flex align-items-center">
                        <h5 className="mb-0">Customer</h5>
                        {authContext.account?.userRole != "Readonly" && (
                          <>
                            <div className="ms-auto text-end">
                              <Dropdown className="mr-10">
                                <Dropdown.Toggle
                                  variant="neutral"
                                  id="dropdown-basic"
                                  size="sm"
                                >
                                  Send email{" "}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() =>
                                      sendGiftCardEmail(EmailNotificationType.GiftCardPurchased)
                                    }
                                  >
                                    Gift card issued/updated
                                  </Dropdown.Item>
                                  {model.buyerEmailAddress &&
                                    <Dropdown.Item
                                      onClick={() =>
                                        sendGiftCardEmail(EmailNotificationType.GiftCardGifted)
                                      }
                                      disabled={btnResyncButtonIsSubmitting}
                                    >
                                      Gift card gifted
                                    </Dropdown.Item>}
                                  <Dropdown.Item
                                    onClick={() =>
                                      sendGiftCardEmail(EmailNotificationType.GiftCardReminder)
                                    }
                                    disabled={btnResyncButtonIsSubmitting}
                                  >
                                    Gift card reminder
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="pt-0 card-body">

                        <div className=" list-group list-group-flush">
                          <div className="list-group-item d-flex align-items-center px-0">
                            <div className="flex-fill">
                              <div className="d-block h6 font-semibold mb-1">Recipient email</div>
                              <span className="d-block text-sm mb-2">{model.recipientEmailAddress}</span>
                              <div className="d-flex mx-n1">
                              </div>
                            </div>
                          </div>
                          <div className="list-group-item d-flex align-items-center px-0">
                            <div className="flex-fill">
                              <div className="d-block h6 font-semibold mb-1">Recipient name</div>
                              <span className="d-block text-sm mb-2">{model.recipientName}</span>
                              <div className="d-flex mx-n1">
                              </div>
                            </div>
                          </div>
                          {model.buyerEmailAddress &&
                            <div className="list-group-item d-flex align-items-center px-0">
                              <div className="flex-fill">
                                <div className="d-block h6 font-semibold mb-1">'Gifted from' email</div>
                                <span className="d-block text-sm mb-2">{model.buyerEmailAddress}</span>
                                <div className="d-flex mx-n1">
                                </div>
                              </div>
                            </div>}
                          {model.buyerName &&
                            <div className="list-group-item d-flex align-items-center px-0">
                              <div className="flex-fill">
                                <div className="d-block h6 font-semibold mb-1">'Gifted from' name</div>
                                <span className="d-block text-sm mb-2">{model.buyerName}</span>
                                <div className="d-flex mx-n1">
                                </div>
                              </div>
                            </div>}
                          {model.giftMessage &&
                            <div className="list-group-item d-flex align-items-center px-0">
                              <div className="flex-fill">
                                <div className="d-block h6 font-semibold mb-1">Gift message</div>
                                <span className="d-block text-sm mb-2">{model.giftMessage}</span>
                                <div className="d-flex mx-n1">
                                </div>
                              </div>
                            </div>}
                        </div>
                        <Button onClick={() => setShowEditCustomerDetailsModal(true)} variant="neutral" type="button" className="w-full mt-2">Edit details</Button>
                      </div>
                    </div>
                    <div className="card mb-10">
                      <div className="card-body">
                        <div className="col-12">
                          <div className="">
                            <label className="form-label" htmlFor="notes">
                              Notes
                            </label>
                            <Form.Control
                              name={"notes"}
                              className="form-control"
                              value={model.notes!}
                              disabled={
                                authContext.account?.userRole == "Readonly"
                              }
                              onChange={handleChange}
                              as="textarea"
                              rows={3}
                            />
                          </div>
                          <p className="text-sm pt-3 text-muted">
                            These notes are private and won't be shared with the
                            customer.
                          </p>
                        </div>
                        <Button variant="neutral" type="submit" className="w-full mt-2">
                          Save changes
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </>
      )}
      {model && (
        <>
          <GiftCardManualAdjustmentModalComponent
            show={showManualAdjustmentModel}
            onHide={() => setShowManualAdjustmentModal(false)}
            onRefresh={performRefresh}
            giftCardId={model.id || ""}
            currentBalance={model.balance || 0}
            currencyCode={model.isoCurrencyCode || ""}
          />

          <GiftCardEmailEventsModalComponent
            show={showEmailEventsModal}
            giftCardId={model.id || ""}
            onHide={() => setShowEmailEventsModal(false)}
            onRefresh={performRefresh}
          />

          <GiftCardCustomerDetailsEditModalComponent
            show={showEditCustomerDetailsModal}
            onHide={() => setShowEditCustomerDetailsModal(false)}
            onRefresh={performRefresh}
            giftCard={model} />

          <GiftCardEditShippingDetailsModalComponent
            show={showEditShippingDetailsModal}
            onHide={() => setShowEditShippingDetailsModal(false)}
            onRefresh={performRefresh}
            shippingAddress={model.shippingAddress!} />
        </>
      )}
    </React.Fragment>
  );
};

export default GiftCardDetailPage;
