import React, { useContext, useState, useEffect } from "react";
import TimezoneSelect from "react-timezone-select";
import CreatableSelect from 'react-select/creatable';
import { GiftcardApi } from "../../apiclient";
import {
  SalesChannelListItemViewModel,
  GiftCardProductViewModel,
  GiftCardProductVariant,
  KSeriesSettingPostViewModel,
  GiftCardExpiryViewModel,
  Platform,
  RSeriesSettingPostViewModel,
  CSeriesSettingPostViewModel,
  KountaSettingsPostViewModel,
  WooCommerceSettingsPostViewModel,
  GiftCardType,
  SquareLocationViewModel,
  SquareSettingPostViewModel,
  RSeriesShopLocationViewModel,
  ShopifySettingsPostViewModel,
  RSeriesEmployeeViewModel
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Modal,
  InputGroup,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import * as Swal from "sweetalert2";
import SettingsNavMenu from "../../components/SettingsNavMenu";

interface SelectOption {
  value: string;
  label: string;
}

const SalesChannelsPage = () => {
  const navigate = useNavigate();
  let giftcardApi = new GiftcardApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  const [showConnectSalesChannelModal, setShowConnectSalesChannelModal] =
    useState(false as boolean);
  const authContext = useContext(AuthContext);
  const [salesChannels, setSalesChannels] = useState(
    [] as SalesChannelListItemViewModel[]
  );
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasConnectedWrappedStorefront, setHasConnectedWrappedStorefront] =
    useState(false);
  const [isLoading, setLoading] = useState(false as boolean);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showWooCommerceSettingsModal, setShowWooCommerceSettingsModal] =
    useState(false as boolean);
  const [showKSeriesSettingsModal, setShowKSeriesSettingsModal] = useState(
    false as boolean
  );
  const [showRSeriesSettingsModal, setShowRSeriesSettingsModal] = useState(
    false as boolean
  );
  const [showShopifySettingsModal, setShowShopifySettingsModal] = useState(
    false as boolean
  );
  const [shopifySettings, setShopifySettings] = useState({} as ShopifySettingsPostViewModel);

  const [showShopifyConnectSettingsModal, setShowShopifyConnectSettingsModal] = useState(
    false as boolean
  );

  const [showCSeriesSettingsModal, setShowCSeriesSettingsModal] = useState(false as boolean);
  const [cSeriesSettings, setCSeriesSettings] = useState({} as CSeriesSettingPostViewModel);

  const [wooDomain, setWooDomain] = useState("" as string);
  const [shopifyDomain, setShopifyDomain] = useState("" as string);
  const [selectedAccountPlatform, setSelectedAccountPlatform] = useState(
    {} as SalesChannelListItemViewModel
  );
  const [kseriesSettings, setKSeriesSettings] = useState(
    {} as KSeriesSettingPostViewModel
  );
  const [rseriesSettings, setRSeriesSettings] = useState(
    {} as RSeriesSettingPostViewModel
  );
  const [webExtensionsUrl, setWebExtensionUrl] = useState("" as string);
  const [orderWebhookUrl, setOrderWebhookUrl] = useState("" as string);
  const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);

  const [showBoppleSettingsModal, setShowBoppleSettingsModal] = useState(false as boolean);
  const [boppleApiKey, setBoppleApiKey] = useState("" as string);

  const [kountaSettings, setKountaSettings] = useState({} as KountaSettingsPostViewModel);
  const [showKountaSettingsModal, setShowKountaSettingsModal] = useState(false as boolean);

  const [wooSettings, setWooSettings] = useState({} as WooCommerceSettingsPostViewModel);
  const [showWooSettingsModal, setShowWooSettingsModal] = useState(false as boolean);

  const [squareSettings, setSquareSettings] = useState({} as SquareSettingPostViewModel);
  const [showSquareSettingsModal, setShowSquareSettingsModal] = useState(false as boolean);
  const [squareLocations, setSquareLocations] = useState(
    [] as SquareLocationViewModel[]
  );
  const [rseriesLocations, setRSeriesLocations] = useState(
    [] as RSeriesShopLocationViewModel[]
  );

  const [rseriesEmployees, setRSeriesEmployees] = useState(
    [] as RSeriesEmployeeViewModel[]
  );

  useEffect(() => {
    if (authContext.account) {
      setIsOnboardingComplete(
        authContext.account.onboardingStepSalesChannelConnected! &&
        authContext.account.onboardingStepReviewedGiftCardSettings! &&
        authContext.account.onboardingStepReviewedEmailNotifications! &&
        authContext.account.onboardingStepUpgradeToPaidPlan! &&
        authContext.account.onboardingStepGiftCardProgramEnabled!
      );
    }
    fetchSalesChannels();

    const hasConnectedVend = searchParams.get("vendConnected") ?? "";
    if (hasConnectedVend) {
      toast.success("Lightspeed Retail (X-Series) platform has been connected");
    }

    const hasConnectedRSeries = searchParams.get("rseriesConnected") ?? "";
    if (hasConnectedRSeries) {
      toast.success("Lightspeed Retail (R-Series) platform has been connected");
    }

    const hasConnectedShopify = searchParams.get("shopifyConnected") ?? "";
    if (hasConnectedShopify) {
      toast.success("Shopify platform has been connected");
    }

    const hasConnectedWooCommerce = searchParams.get("wooConnected") ?? "";
    if (hasConnectedWooCommerce) {
      toast.success("WooCommerce platform has been connected");
    }

    const hasConnectedKounta = searchParams.get("kountaConnected") ?? "";
    if (hasConnectedKounta) {
      toast.success(
        "Lightspeed Restaurant (O-Series) platform has been connected"
      );
    }

    const hasConnectedEcwid = searchParams.get("ecwidConnected") ?? "";
    if (hasConnectedEcwid) {
      toast.success("Lightspeed eCommerce (Ecwid) platform has been connected");
    }

    const hasConnectedBopple = searchParams.get("boppleConnected") ?? "";
    if (hasConnectedBopple) {
      toast.success("Bopple platform has been connected");
    }

    const hasConnectedBigCommerce =
      searchParams.get("bigcommerceConnected") ?? "";
    if (hasConnectedBigCommerce) {
      toast.success("BigCommerce platform has been connected");
    }

    const hasConnectedKSeries = searchParams.get("kSeriesConnected") ?? "";
    if (hasConnectedKSeries) {
      toast.success("Lightspeed Restaurant (K-Series) platform has been connected");
    }

    const hasHeartlandRetailConnected = searchParams.get("heartlandRetailConnected") ?? "";
    if (hasHeartlandRetailConnected) {
      toast.success("Heartland Retail platform has been connected");
    }

    const hasConnectedSquare = searchParams.get("squareConnected") ?? "";
    if (hasConnectedSquare) {
      toast.success("Square platform has been connected");
    }

    const hasConnectedHike = searchParams.get("hikeConnected") ?? "";
    if (hasConnectedSquare) {
      toast.success("Hike platform has been connected");
    }
  }, []);

  const generateKSeriesSalesChannel = () => {
    giftcardApi
      .giftcardGenerateKSeriesSalesChannelPost()
      .then((response) => {
        var data = response.data;
        let newChannels = [...salesChannels!] as any;
        newChannels.push(data);
        setSalesChannels(newChannels);
        loadKSeriesSettingsModal(data);
      })
      .catch((error: any) => {
        console.log("get error", error);
      });


  };

  const generateBoppleSalesChannel = () => {
    giftcardApi
      .giftcardGenerateBoppleSalesChannelPost()
      .then((response) => {
        var data = response.data as SalesChannelListItemViewModel;
        let newChannels = [...salesChannels!] as any;
        newChannels.push(data);
        setSalesChannels(newChannels);
        loadBoppleSettingsModal(data);
      })
      .catch((error: any) => {
        console.log("get error", error);
      });
  };

  const generateWrappedStorefrontSalesChannel = () => {
    giftcardApi
      .giftcardGenerateWrappedStorefrontSalesChannelPost()
      .then((response) => {
        if (response.data) {
          navigate("/account/storefront-settings");
        }
      })
      .catch((error: any) => {
        console.log("get error", error);
      });
  };

  let loadKSeriesSettingsModal = (data: SalesChannelListItemViewModel) => {
    setSelectedAccountPlatform(data);
    var settings = {
      accountPlatformId: data.id,
      businessLocationId: data.sourceId,
      giftCardSourceProductId: data.giftCardSourceProductId,
      salesChannelDisplayName: data.channelName
    } as KSeriesSettingPostViewModel;
    setKSeriesSettings(settings);
    setWebExtensionUrl(
      `https://manage.wrappedgiftcards.com/pos/lskgiftcard/${settings.accountPlatformId}/`
    );
    setOrderWebhookUrl(
      `https://manage.wrappedgiftcards.com/Webhooks/KSeries/AccountClose?accountPlatformId=${settings.accountPlatformId}`
    );
    //show modal
    setShowConnectSalesChannelModal(false);
    setShowKSeriesSettingsModal(true);
  };

  let loadCSeriesSettingsModal = (data: SalesChannelListItemViewModel) => {
    setSelectedAccountPlatform(data);
    var settings = {
      accountPlatformId: data.id,
      apiKey: data.consumerKey,
      apiSecret: data.consumerSecret,
      clusterId: data.clusterId,
      giftCardSourceProductId: data.giftCardSourceProductId,
    } as CSeriesSettingPostViewModel;
    setCSeriesSettings(settings);

    //show modal
    setShowConnectSalesChannelModal(false);
    setShowCSeriesSettingsModal(true);
  };

  let loadBoppleSettingsModal = (data: SalesChannelListItemViewModel) => {
    if (data && data.accessToken) {
      setBoppleApiKey(data.accessToken!);
    }
    //show modal
    setShowConnectSalesChannelModal(false);
    setShowBoppleSettingsModal(true);
  };

  let loadRSeriesSettingsModal = (data: SalesChannelListItemViewModel) => {
    setSelectedAccountPlatform(data);
    var settings = {
      accountPlatformId: data.id,
      giftCardSourceProductId: data.giftCardSourceProductId,
      wrappedGiftCardPaymentTypeId: data.wrappedGiftCardPaymentTypeId,
      useNativeGiftCards: data.useNativeGiftCards,
      employeeId: data.employeeId
    } as RSeriesSettingPostViewModel;
    setRSeriesSettings(settings);
    setWebExtensionUrl(
      `https://manage.wrappedgiftcards.com/pos/rseries/${settings.accountPlatformId}/`
    );

    giftcardApi
      .giftcardRSeriesLocationListGet(data.id)
      .then((response) => {
        var data = response.data as RSeriesShopLocationViewModel[];
        setRSeriesLocations(data);
      })
      .catch((error: any) => {
        console.log("get error", error);
      });

      giftcardApi
      .giftcardRSeriesEmployeeListGet(data.id)
      .then((response) => {
        var data = response.data as RSeriesEmployeeViewModel[];
        setRSeriesEmployees(data);
      })
      .catch((error: any) => {
        console.log("get error", error);
      });

    //show modal
    setShowConnectSalesChannelModal(false);
    setShowRSeriesSettingsModal(true);
  };

  let loadKountaSettingsModal = (data: SalesChannelListItemViewModel) => {
    setSelectedAccountPlatform(data);
    var settings = {
      accountPlatformId: data.id,
      shouldGenerateOrderOnGiftCardPurchase: data.shouldGenerateOrderOnGiftCardPurchase,
      siteIds: data.siteIds
    } as KountaSettingsPostViewModel;

    setKountaSettings(settings);

    //show modal
    setShowConnectSalesChannelModal(false);
    setShowKountaSettingsModal(true);
  };

  let loadShopifySettingsModal = (data: SalesChannelListItemViewModel) => {
    setSelectedAccountPlatform(data);
    var settings = {
      accountPlatformId: data.id,
      shouldAutoFulfillGiftCards: data.shouldAutoFulfillGiftCards,
    } as ShopifySettingsPostViewModel;

    setShopifySettings(settings);

    //show modal
    setShowConnectSalesChannelModal(false);
    setShowShopifySettingsModal(true);
  };

  let loadWooSettingsModal = (data: SalesChannelListItemViewModel) => {
    setSelectedAccountPlatform(data);
    var settings = {
      accountPlatformId: data.id,
      giftCardType: data.giftCardType
    } as WooCommerceSettingsPostViewModel;

    setWooSettings(settings);

    //show modal
    setShowConnectSalesChannelModal(false);
    setShowWooSettingsModal(true);
  };

  let loadSquareSettingsModal = (data: SalesChannelListItemViewModel) => {
    setSelectedAccountPlatform(data);
    var settings = {
      accountPlatformId: data.id,
      locationId: data.locationId
    } as SquareSettingPostViewModel;
    setSquareSettings(settings);

    giftcardApi
      .giftcardSquareLocationListGet(data.id)
      .then((response) => {
        var data = response.data as SquareLocationViewModel[];
        setSquareLocations(data);
      })
      .catch((error: any) => {
        console.log("get error", error);
      });

    //show modal
    setShowConnectSalesChannelModal(false);
    setShowSquareSettingsModal(true);
  };

  let disconnectSalesChannel = (data: SalesChannelListItemViewModel) => {
    Swal.default
      .fire({
        title: "Are you sure?",
        text: `Do you want to delete this sales channel from your account?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#d9534f",
      })
      .then((result) => {
        if (result.isConfirmed) {
          giftcardApi
            .giftcardDeleteSalesChannelIdDelete(data.id ?? "")
            .then((response) => {
              fetchSalesChannels();
              toast.success(`Sales channel deleted`);
            })
            .catch((error: any) => {
              console.log("get error", error);
            });
        }
      });
  };

  const fetchSalesChannels = () => {
    giftcardApi
      .giftcardSalesChannelListGet()
      .then((response) => {
        var data = response.data as SalesChannelListItemViewModel[];
        setSalesChannels(data);
        console.log("sales channels data", data);
        var bConnected = data.some((s) => s.platform == "WrappedStorefront");
        setHasConnectedWrappedStorefront(bConnected);
      })
      .catch((error: any) => {
        console.log("get error", error);
      });
  };

  const handleKSeriesSettingsChange = (event: any) => {
    const target = event.target;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    const name = event.target.name;
    if (name === "canExpire") {
      const booVal = event.currentTarget.value === "true" ? true : false;
      value = booVal;
    }
    console.log("handleChange", name, value);
    setKSeriesSettings((values: KSeriesSettingPostViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleRSeriesSettingsChange = (event: any) => {
    const target = event.target;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    const name = event.target.name;
    if (name === "canExpire") {
      const booVal = event.currentTarget.value === "true" ? true : false;
      value = booVal;
    }

    if (name === "useNativeGiftCards") {
      const booVal = event.currentTarget.value === "true" ? true : false;
      value = booVal;
    }

    console.log("handleChange", name, value);
    setRSeriesSettings((values: RSeriesSettingPostViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleSquareSettingsChange = (event: any) => {
    const target = event.target;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    const name = event.target.name;

    setSquareSettings((values: SquareSettingPostViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleKountaSettingsChange = (event: any) => {
    const target = event.target;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    const name = event.target.name;

    console.log("handleChange", name, value);
    setKountaSettings((values: KountaSettingsPostViewModel) => ({
      ...values,
      [name]: value,
    }));
  };



  let handleSiteIdsChange = (options: any) => {
    console.log("options", options);
    const selectedValues = options ? options.map((option: { value: string; }) => option.value) : [] as string[];
    console.log("values", selectedValues);
    //let newProductChannels = [...kountaSettings.siteIds!] as any;
    //newProductChannels["siteIds"] = values;

    setKountaSettings((values: KountaSettingsPostViewModel) => ({
      ...values,
      siteIds: selectedValues,
    }));
  };

  const handleWooSettingsChange = (event: any) => {
    const target = event.target;
    var value =
      target.type === "checkbox"
        ? target.checked
        : target.value;
    const name = event.target.name;

    console.log("woo handleChange", name, value);
    setWooSettings((values: WooCommerceSettingsPostViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleCSeriesSettingsChange = (event: any) => {
    const target = event.target;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    const name = event.target.name;
    if (name === "canExpire") {
      const booVal = event.currentTarget.value === "true" ? true : false;
      value = booVal;
    }
    console.log("handleChange", name, value);
    setCSeriesSettings((values: CSeriesSettingPostViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  let handleSalesChannelChange = (event: any, i: number) => {
    const target = event.target;
    const name = event.target.name;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    let newChannels = [...salesChannels!] as any;
    newChannels[i][name] = value;

    setSalesChannels(newChannels);

    handleSaleChannelSubmit(newChannels[i]);
  };

  const handleSaleChannelSubmit = (
    channelToUpdate: SalesChannelListItemViewModel
  ) => {
    giftcardApi
      .giftcardSaveSalesChannelPost("1", channelToUpdate)
      .then((response) => {
        var statusText = channelToUpdate.enabled ? "enabled" : "disabled";
        if (channelToUpdate.platform === "Shopify") {
          toast.success(
            `Sales channel ${statusText}. Shopify product has also been ${statusText}.`,
            { duration: 5000 }
          );
        } else {
          toast.success(`Sales channel ${statusText}.`);
        }
      })
      .catch((error: any) => {
        toast.error("An error occurred when saving sales channel.");
      });
  };

  const handleKSeriesSettingsSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      giftcardApi
        .giftcardSaveKSeriesChannelSettingsPost("1", kseriesSettings)
        .then((response) => {
          toast.success("K-Series sales channel settings updated.", {
            duration: 5000,
          });
          fetchSalesChannels();
          setValidated(false);
        })
        .catch((error: any) => {
          toast.error("An error occurred when saving K-Series settings.");
        });
    }
  };

  const handleRSeriesSettingsSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      giftcardApi
        .giftcardSaveRSeriesChannelSettingsPost("1", rseriesSettings)
        .then((response) => {
          toast.success("R-Series sales channel settings updated.", {
            duration: 5000,
          });
          fetchSalesChannels();
          setValidated(false);
        })
        .catch((error: any) => {
          toast.error("An error occurred when saving R-Series settings.");
        });
    }
  };

  const handleSquareSettingsSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      giftcardApi
        .giftcardSaveSquareChannelSettingsPost("1", squareSettings)
        .then((response) => {
          toast.success("Square sales channel settings updated.", {
            duration: 5000,
          });
          fetchSalesChannels();
          setValidated(false);
        })
        .catch((error: any) => {
          toast.error("An error occurred when saving C-Series settings.");
        });
    }
  };

  const handleKountaSettingsSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      giftcardApi
        .giftcardSaveKountaChannelSettingsPost("1", kountaSettings)
        .then((response) => {
          setShowKountaSettingsModal(false);
          toast.success("Kounta sales channel settings updated.", {
            duration: 5000,
          });
          fetchSalesChannels();
          setValidated(false);
        })
        .catch((error: any) => {
          toast.error("An error occurred when saving Kounta settings.");
        });
    }
  };


  
  const handleShopifySettingsChange = (event: any) => {
    const target = event.target;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    const name = event.target.name;

    console.log("handleChange", name, value);
    setShopifySettings((values: ShopifySettingsPostViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleShopifySettingsSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      giftcardApi
        .giftcardSaveShopifyChannelSettingsPost("1", shopifySettings)
        .then((response) => {
          setShowShopifySettingsModal(false);
          toast.success("Shopify sales channel settings updated.", {
            duration: 5000,
          });
          fetchSalesChannels();
          setValidated(false);
        })
        .catch((error: any) => {
          toast.error("An error occurred when saving Kounta settings.");
        });
    }
  };




  const handleWooSettingsSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      giftcardApi
        .giftcardSaveWooCommerceChannelSettingsPost("1", wooSettings)
        .then((response) => {
          setShowWooSettingsModal(false);
          toast.success("WooCommerce sales channel settings updated.", {
            duration: 5000,
          });
          fetchSalesChannels();
          setValidated(false);
        })
        .catch((error: any) => {
          toast.error("An error occurred when saving WooCommerce settings.");
        });
    }
  };

  const handleCSeriesSettingsSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      giftcardApi
        .giftcardSaveCSeriesChannelSettingsPost("1", cSeriesSettings)
        .then((response) => {
          toast.success("C-Series sales channel settings updated.", {
            duration: 5000,
          });
          setShowCSeriesSettingsModal(false);
          setShowConnectSalesChannelModal(false);
          fetchSalesChannels();
          setValidated(false);
        })
        .catch((error: any) => {
          toast.error("An error occurred when saving C-Series settings.");
        });
    }
  };

  const renderSaleChannelListItem = (
    channel: SalesChannelListItemViewModel,
    i: number
  ) => {
    if (channel.platform === "Shopify") {
      return (
        <div key={"channel-item" + i} className="list-group">
          <div className="list-group-item d-flex align-items-center shadow-1 border rounded mb-5">
            <div className="me-4">
              <div className="avatar">
                <img alt="..." src="/img/integrations/shopify-bag.png" />
              </div>
            </div>
            <div className="flex-fill">
              <div className="d-block h6 font-semibold mb-1">
                {channel.domain}
              </div>
              <span className="d-block text-sm">Shopify</span>
              <div className="pt-1 text-xs text-muted">ID: {channel.id}</div>
            </div>
            <div className="ms-auto text-end">
              <a
                href={`https://admin.shopify.com/store/${channel.domain?.replace(".myshopify.com", "")}/products/${channel.giftCardSourceProductId}`}
                target="_blank"
                rel="noreferrer"
                className="mr-10"
              >
                Edit gift card product
              </a>
              <a
                onClick={()=>loadShopifySettingsModal(channel)}
                rel="noreferrer"
                className="text-link cursor-pointer"
              >
                View settings
              </a>
            </div>
            <div className="ms-auto text-end">
              <FormCheck
                name="enabled"
                className=""
                type="switch"
                checked={channel.enabled}
                onChange={(e) => handleSalesChannelChange(e, i)}
              />
            </div>
            <a
              className="ms-auto text-end ml-10 cursor-pointer"
              title="delete sales channel"
              onClick={() => disconnectSalesChannel(channel)}
            >
              <small>&times;</small>
            </a>
          </div>
        </div>
      );
    } else if (channel.platform === "WooCommerce") {
      return (
        <div key={"channel-item" + i} className="list-group">
          <div className="list-group-item d-flex align-items-center shadow-1 border rounded mb-5">
            <div className="me-4">
              <div className="avatar">
                <img
                  alt="..."
                  src="/img/integrations/woocommerce-logo-color-black@2x.png"
                />
              </div>
            </div>
            <div className="flex-fill">
              <a className="d-block h6 font-semibold mb-1">{channel.domain}</a>
              <span className="d-block text-sm">WooCommerce ({channel.giftCardType})</span>
              <div className="pt-1 text-xs text-muted">ID: {channel.id}</div>
            </div>
            <div className="ms-auto text-end">
              <a
                className="text-link cursor-pointer"
                onClick={() => loadWooSettingsModal(channel)}
              >
                View settings
              </a>
            </div>
            <div className="ms-auto text-end">
              <FormCheck
                name="enabled"
                className=""
                type="switch"
                checked={channel.enabled}
                onChange={(e) => handleSalesChannelChange(e, i)}
              />
            </div>
            <a
              className="ms-auto text-end ml-10 cursor-pointer"
              title="delete sales channel"
              onClick={() => disconnectSalesChannel(channel)}
            >
              <small>&times;</small>
            </a>
          </div>
        </div>
      );
    } else if (channel.platform === "Kounta") {
      return (
        <div key={"channel-item" + i} className="list-group">
          <div className="list-group-item d-flex align-items-center shadow-1 border rounded mb-5">
            <div className="me-4">
              <div className="avatar">
                <img alt="..." src="/img/integrations/Lightspeed_Flame.svg" />
              </div>
            </div>
            <div className="flex-fill">
              <a className="d-block h6 font-semibold mb-1">
                {channel.channelName}
              </a>
              <span className="d-block text-sm">
                Lightspeed Restaurant (O-Series / Kounta)
              </span>
              <div className="pt-1 text-xs text-muted">ID: {channel.id}</div>
            </div>
            <div className="ms-auto text-end">
              <a
                className="text-link cursor-pointer"
                onClick={() => loadKountaSettingsModal(channel)}
              >
                View settings
              </a>
            </div>
            <div className="ms-auto text-end">
              <FormCheck
                name="enabled"
                className=""
                type="switch"
                checked={channel.enabled}
                onChange={(e) => handleSalesChannelChange(e, i)}
              />
            </div>
            <a
              className="ms-auto text-end ml-10 cursor-pointer"
              title="delete sales channel"
              onClick={() => disconnectSalesChannel(channel)}
            >
              <small>&times;</small>
            </a>
          </div>
        </div>
      );
    } else if (channel.platform === Platform.RSeries) {
      return (
        <div key={"channel-item" + i} className="list-group">
          <div className="list-group-item d-flex align-items-center shadow-1 border rounded mb-5">
            <div className="me-4">
              <div className="avatar">
                <img alt="..." src="/img/integrations/Lightspeed_Flame.svg" />
              </div>
            </div>
            <div className="flex-fill">
              <a className="d-block h6 font-semibold mb-1">
                {channel.channelName} ({channel.sourceId})
              </a>
              <span className="d-block text-sm">
                Lightspeed Retail (R-Series)
              </span>
              <div className="pt-1 text-xs text-muted">ID: {channel.id}</div>
            </div>
            <div className="ms-auto text-end">
              <a
                className="text-link cursor-pointer"
                onClick={() => loadRSeriesSettingsModal(channel)}
              >
                View settings
              </a>
            </div>
            <div className="ms-auto text-end">
              <FormCheck
                name="enabled"
                className=""
                type="switch"
                checked={channel.enabled}
                onChange={(e) => handleSalesChannelChange(e, i)}
              />
            </div>
            <a
              className="ms-auto text-end ml-10 cursor-pointer"
              title="delete sales channel"
              onClick={() => disconnectSalesChannel(channel)}
            >
              <small>&times;</small>
            </a>
          </div>
        </div>
      );
    } else if (channel.platform === Platform.Ecwid) {
      return (
        <div key={"channel-item" + i} className="list-group">
          <div className="list-group-item d-flex align-items-center shadow-1 border rounded mb-5">
            <div className="me-4">
              <div className="avatar">
                <img alt="..." src="/img/integrations/Lightspeed_Flame.svg" />
              </div>
            </div>
            <div className="flex-fill">
              <a className="d-block h6 font-semibold mb-1">
                {channel.channelName} (Domain: {channel.domain}, Store ID:{" "}
                {channel.sourceId})
              </a>
              <span className="d-block text-sm">
                Lightspeed eCommerce (Ecwid)
              </span>
              <div className="pt-1 text-xs text-muted">ID: {channel.id}</div>
            </div>
            <div className="ms-auto text-end">
              <FormCheck
                name="enabled"
                className=""
                type="switch"
                checked={channel.enabled}
                onChange={(e) => handleSalesChannelChange(e, i)}
              />
            </div>
            <a
              className="ms-auto text-end ml-10 cursor-pointer"
              title="delete sales channel"
              onClick={() => disconnectSalesChannel(channel)}
            >
              <small>&times;</small>
            </a>
          </div>
        </div>
      );
    } else if (channel.platform === "KSeries") {
      return (
        <div key={"channel-item" + i} className="list-group">
          <div className="list-group-item d-flex align-items-center shadow-1 border rounded mb-5">
            <div className="me-4">
              <div className="avatar">
                <img alt="..." src="/img/integrations/Lightspeed_Flame.svg" />
              </div>
            </div>
            <div className="flex-fill">
              <a className="d-block h6 font-semibold mb-1">
                {channel.channelName}{" "}
                {channel.sourceId ? `(${channel.sourceId})` : ""}
                {channel.accessToken ? `🔗` : ""}
              </a>
              <span className="d-block text-sm">
                Lightspeed Restaurant (K-Series)
              </span>
              <div className="pt-1 text-xs text-muted">ID: {channel.id}</div>
            </div>
            <div className="ms-auto text-end">
              <a
                className="text-link cursor-pointer"
                onClick={() => loadKSeriesSettingsModal(channel)}
              >
                View settings
              </a>
            </div>
            <div className="ms-auto text-end">
              <FormCheck
                name="enabled"
                className=""
                type="switch"
                checked={channel.enabled}
                onChange={(e) => handleSalesChannelChange(e, i)}
              />
            </div>
            <a
              className="ms-auto text-end ml-10 cursor-pointer"
              title="delete sales channel"
              onClick={() => disconnectSalesChannel(channel)}
            >
              <small>&times;</small>
            </a>
          </div>
        </div>
      );
    } else if (channel.platform === "CSeries") {
      return (
        <div key={"channel-item" + i} className="list-group">
          <div className="list-group-item d-flex align-items-center shadow-1 border rounded mb-5">
            <div className="me-4">
              <div className="avatar">
                <img alt="..." src="/img/integrations/Lightspeed_Flame.svg" />
              </div>
            </div>
            <div className="flex-fill">
              <a className="d-block h6 font-semibold mb-1">
                {channel.domain}{" "}
                {channel.sourceId ? `(${channel.sourceId})` : ""}
              </a>
              <span className="d-block text-sm">
                Lightspeed eCommerce (C-Series)
              </span>
              <div className="pt-1 text-xs text-muted">ID: {channel.id}</div>
            </div>
            <div className="ms-auto text-end">
              <a
                className="text-link cursor-pointer"
                onClick={() => loadCSeriesSettingsModal(channel)}
              >
                View settings
              </a>
            </div>
            <div className="ms-auto text-end">
              <FormCheck
                name="enabled"
                className=""
                type="switch"
                checked={channel.enabled}
                onChange={(e) => handleSalesChannelChange(e, i)}
              />
            </div>
            <a
              className="ms-auto text-end ml-10 cursor-pointer"
              title="delete sales channel"
              onClick={() => disconnectSalesChannel(channel)}
            >
              <small>&times;</small>
            </a>
          </div>
        </div>
      );
    } else if (channel.platform === "Bopple") {
      return (
        <div key={"channel-item" + i} className="list-group">
          <div className="list-group-item d-flex align-items-center shadow-1 border rounded mb-5">
            <div className="me-4">
              <div className="avatar">
                <img alt="..." src="/img/integrations/bopple-icon2.png" />
              </div>
            </div>
            <div className="flex-fill">
              <a className="d-block h6 font-semibold mb-1">
                {channel.channelName}{" "}
                {channel.sourceId ? `(${channel.sourceId})` : ""}
              </a>
              <span className="d-block text-sm">
                Bopple - Online ordering for hospitality
              </span>
              <div className="pt-1 text-xs text-muted">ID: {channel.id}</div>
            </div>
            <div className="ms-auto text-end">
              <a
                className="text-link cursor-pointer"
                onClick={() => loadBoppleSettingsModal(channel)}
              >
                View API key
              </a>
            </div>
            <div className="ms-auto text-end">
              <FormCheck
                name="enabled"
                className=""
                type="switch"
                checked={channel.enabled}
                onChange={(e) => handleSalesChannelChange(e, i)}
              />
            </div>
            <a
              className="ms-auto text-end ml-10 cursor-pointer"
              title="delete sales channel"
              onClick={() => disconnectSalesChannel(channel)}
            >
              <small>&times;</small>
            </a>
          </div>
        </div>
      );
    } else if (channel.platform === "BigCommerce") {
      return (
        <div key={"channel-item" + i} className="list-group">
          <div className="list-group-item d-flex align-items-center shadow-1 border rounded mb-5">
            <div className="me-4">
              <div className="avatar">
                <img alt="..." src="/img/integrations/bigcommerce-144.png" />
              </div>
            </div>
            <div className="flex-fill">
              <a className="d-block h6 font-semibold mb-1">{channel.domain}</a>
              <span className="d-block text-sm">BigCommerce</span>
              <div className="pt-1 text-xs text-muted">ID: {channel.id}</div>
            </div>
            <div className="ms-auto text-end">
              <FormCheck
                name="enabled"
                className=""
                type="switch"
                checked={channel.enabled}
                onChange={(e) => handleSalesChannelChange(e, i)}
              />
            </div>
            <a
              className="ms-auto text-end ml-10 cursor-pointer"
              title="delete sales channel"
              onClick={() => disconnectSalesChannel(channel)}
            >
              <small>&times;</small>
            </a>
          </div>
        </div>
      );
    } else if (channel.platform === "Square") {
      return (
        <div key={"channel-item" + i} className="list-group">
          <div className="list-group-item d-flex align-items-center shadow-1 border rounded mb-5">
            <div className="me-4">
              <div className="avatar">
                <img alt="..." src="/img/integrations/Square_Jewel_Black.svg" />
              </div>
            </div>
            <div className="flex-fill">
              <a className="d-block h6 font-semibold mb-1">
                {channel.channelName}{" "}
                {channel.sourceId ? `(${channel.sourceId})` : ""}
              </a>
              <span className="d-block text-sm">
                Square
              </span>
              <div className="pt-1 text-xs text-muted">ID: {channel.id}</div>
            </div>
            <div className="ms-auto text-end">
              <a
                className="text-link cursor-pointer"
                onClick={() => loadSquareSettingsModal(channel)}
              >
                View settings
              </a>
            </div>
            <div className="ms-auto text-end">
              <FormCheck
                name="enabled"
                className=""
                type="switch"
                checked={channel.enabled}
                onChange={(e) => handleSalesChannelChange(e, i)}
              />
            </div>
            <a
              className="ms-auto text-end ml-10 cursor-pointer"
              title="delete sales channel"
              onClick={() => disconnectSalesChannel(channel)}
            >
              <small>&times;</small>
            </a>
          </div>
        </div>
      );
    } else if (channel.platform === "WrappedStorefront") {
      return (
        <div key={"channel-item" + i} className="list-group">
          <div className="list-group-item d-flex align-items-center shadow-1 border rounded mb-5">
            <div className="me-4">
              <div className="avatar">
                <img alt="..." src="/img/logos/wrapped-icon.png" />
              </div>
            </div>
            <div className="flex-fill">
              <a
                className="d-block h6 font-semibold mb-1"
                href={`https://${channel.domain}.wrappedgiftcards.store`}
                target="_blank"
              >
                https://{channel.domain}.wrappedgiftcards.store
              </a>
              <span className="d-block text-sm">
                Wrapped - Online Gift Card Storefront (eCommerce)
              </span>
              <div className="pt-1 text-xs text-muted">ID: {channel.id}</div>
            </div>
            <div className="ms-auto text-end">
              <a
                className="text-link cursor-pointer"
                onClick={() => navigate("/account/storefront-settings")}
              >
                View settings
              </a>
            </div>
            {isOnboardingComplete &&
              <div className="ms-auto text-end">
                <FormCheck
                  name="enabled"
                  className=""
                  type="switch"
                  checked={channel.enabled}
                  onChange={(e) => handleSalesChannelChange(e, i)}
                />
              </div>}
            <a
              className="ms-auto text-end ml-10 cursor-pointer"
              title="delete sales channel"
              onClick={() => disconnectSalesChannel(channel)}
            >
              <small>&times;</small>
            </a>
          </div>
        </div>
      );
    } else if (channel.platform === Platform.HeartlandRetail) {
      return (
        <div key={"channel-item" + i} className="list-group">
          <div className="list-group-item d-flex align-items-center shadow-1 border rounded mb-5">
            <div className="me-4">
              <div className="avatar">
                <img alt="..." src="/img/integrations/heartland-icon.png" />
              </div>
            </div>
            <div className="flex-fill">
              <a className="d-block h6 font-semibold mb-1">{channel.domain}</a>
              <span className="d-block text-sm">Heartland Retail</span>
              <div className="pt-1 text-xs text-muted">ID: {channel.id}</div>
            </div>
            <div className="ms-auto text-end">
              <FormCheck
                name="enabled"
                className=""
                type="switch"
                checked={channel.enabled}
                onChange={(e) => handleSalesChannelChange(e, i)}
              />
            </div>
            <a
              className="ms-auto text-end ml-10 cursor-pointer"
              title="delete sales channel"
              onClick={() => disconnectSalesChannel(channel)}
            >
              <small>&times;</small>
            </a>
          </div>
        </div>
      );
    }
    else if (channel.platform === Platform.Hike) {
      return (
        <div key={"channel-item" + i} className="list-group">
          <div className="list-group-item d-flex align-items-center shadow-1 border rounded mb-5">
            <div className="me-4">
              <div className="avatar">
                <img alt="..." src="/img/integrations/hike-logo.png" />
              </div>
            </div>
            <div className="flex-fill">
              <a className="d-block h6 font-semibold mb-1">{channel.channelName}</a>
              <span className="d-block text-sm">
                Hike
              </span>
              <div className="pt-1 text-xs text-muted">ID: {channel.id}</div>
            </div>
            <div className="ms-auto text-end">
              <FormCheck
                name="enabled"
                className=""
                type="switch"
                checked={channel.enabled}
                onChange={(e) => handleSalesChannelChange(e, i)}
              />
            </div>
            <a
              className="ms-auto text-end ml-10 cursor-pointer"
              title="delete sales channel"
              onClick={() => disconnectSalesChannel(channel)}
            >
              <small>&times;</small>
            </a>
          </div>
        </div>
      );
    }


    else {
      return (
        <div key={"channel-item" + i} className="list-group">
          <div className="list-group-item d-flex align-items-center shadow-1 border rounded mb-5">
            <div className="me-4">
              <div className="avatar">
                <img alt="..." src="/img/integrations/Lightspeed_Flame.svg" />
              </div>
            </div>
            <div className="flex-fill">
              <a className="d-block h6 font-semibold mb-1">{channel.domain}</a>
              <span className="d-block text-sm">
                Lightspeed Retail (X-Series / Vend)
              </span>
              <div className="pt-1 text-xs text-muted">ID: {channel.id}</div>
            </div>
            <div className="ms-auto text-end">
              <FormCheck
                name="enabled"
                className=""
                type="switch"
                checked={channel.enabled}
                onChange={(e) => handleSalesChannelChange(e, i)}
              />
            </div>
            <a
              className="ms-auto text-end ml-10 cursor-pointer"
              title="delete sales channel"
              onClick={() => disconnectSalesChannel(channel)}
            >
              <small>&times;</small>
            </a>
          </div>
        </div>
      );
    }
  };

  const handleWooCommerceSubmit = (event: any) => {
    setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    //}

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      window.location.href = `/WooCommerce/Auth?storeUrl=${wooDomain}`;
    }
  };

  const handleWooDomainChange = (event: any) => {
    setIsSubmitting(false);
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setWooDomain(value);
  };

  const handleShopifyConnectSubmit = (event: any) => {
    setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    //}

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      window.location.href = `/Shopify/InstallFromSettings?domain=${shopifyDomain}`;
    }
  };

  const handleShopifyDomainChange = (event: any) => {
    setIsSubmitting(false);
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setShopifyDomain(value);
  };

  const copyToClipboard = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy);
    toast.success("Copied to clipboard");
  };

  return (
    <React.Fragment>
      <header>
        <div className="container">
          <div className="border-bottom pt-6">
            <div className="row align-items-center">
              <div className="col-sm-6 col-12">
                <h1 className="h2 ls-tight">Sales channels</h1>
                <p className="text-sm pb-3">
                  Connect and enable sales channels that you want your gift
                  cards to be synced with.
                </p>
                {/* <p  className="alert alert-info text-sm">
                  <a href="https://help.wrappedgiftcards.com/en/collections/5925395-integrations" target="_blank">View our setup guides and staff training resources here <i className="bi bi-box-arrow-up-right" /></a>
                </p> */}
              </div>
              <div className="col-sm-6 col-12">
                <a
                  href="https://help.wrappedgiftcards.com/en/collections/5925395-integrations"
                  target={"_blank"}
                  className="text-sm text-muted text-primary-hover font-semibold me-2 d-none d-md-block float-end"
                >
                  <i className="bi bi-question-circle-fill"></i>
                  <span className="d-none d-sm-inline ms-2">View setup guides and staff training resources</span>
                </a>
              </div>
            </div>
            <SettingsNavMenu />
          </div>
        </div>
      </header>
      <div className="py-6">
        <div className="container-fluid max-w-screen-md vstack gap-6">
          <div className="card">
            <div className="card-body pb-0">
              <div className="mb-4">
                <Row>
                  <Col>
                    {(authContext.account?.isAdmin ||
                      authContext.account?.isOwner) && (
                        <button
                          type="button"
                          className="btn btn-sm btn-primary float-end"
                          onClick={() => setShowConnectSalesChannelModal(true)}
                        >
                          <span>Add more sales channels</span>
                        </button>
                      )}
                  </Col>
                </Row>
              </div>
              {isLoading ? (
                <>
                  <Spinner animation="border" />
                </>
              ) : (
                <>
                  {salesChannels &&
                    salesChannels?.map((channel, i) =>
                      renderSaleChannelListItem(channel, i)
                    )}
                  <Modal
                    show={showConnectSalesChannelModal}
                    onHide={() => setShowConnectSalesChannelModal(false)}
                    fullscreen={true}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Connect sales channel</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-6 p-5 border-end">
                          <div className=" list-group list-group-flush">

                            <div className="list-group-item d-flex align-items-center px-0">
                              <div>
                                <a
                                  href="/Vend/InstallFromSettings"
                                  className="avatar"
                                >
                                  <img
                                    alt="Connect Lightspeed Retail X-Series"
                                    src="/img/integrations/Lightspeed_Flame.svg"
                                  />
                                </a>
                              </div>
                              <div className="flex-fill ms-4">
                                <a
                                  href="/Vend/InstallFromSettings"
                                  className="d-block h6 mb-1"
                                >
                                  Lightspeed Retail (X-Series / Vend)
                                </a>
                                <div>
                                  <span className="text-xs text-muted">POS</span>
                                </div>
                              </div>
                              <div className="">
                                <a
                                  href="/Vend/InstallFromSettings"
                                  className="btn btn-sm btn-primary px-4 py-2 text-xs"
                                >
                                  Connect
                                </a>
                              </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center px-0">
                              <div>
                                <a
                                  href="/RSeries/InstallFromSettings"
                                  className="avatar"
                                >
                                  <img
                                    alt="Connect Lightspeed Retail X-Series"
                                    src="/img/integrations/Lightspeed_Flame.svg"
                                  />
                                </a>
                              </div>
                              <div className="flex-fill ms-4">
                                <a
                                  href="/RSeries/InstallFromSettings"
                                  className="d-block h6 mb-1"
                                >
                                  Lightspeed Retail (R-Series)
                                </a>
                                <div>
                                  <span className="text-xs text-muted">POS</span>
                                </div>
                              </div>
                              <div className="">
                                <a
                                  href="/RSeries/InstallFromSettings"
                                  className="btn btn-sm btn-primary px-4 py-2 text-xs"
                                >
                                  Connect
                                </a>
                              </div>
                            </div>

                            <div className="list-group-item d-flex align-items-center px-0">
                              <div>
                                <a
                                  href="/Kounta/InstallFromSettings"
                                  className="avatar"
                                >
                                  <img
                                    alt="Connect Lightspeed Retail X-Series"
                                    src="/img/integrations/Lightspeed_Flame.svg"
                                  />
                                </a>
                              </div>
                              <div className="flex-fill ms-4">
                                <a
                                  href="/Kounta/InstallFromSettings"
                                  className="d-block h6 mb-1"
                                >
                                  Lightspeed Restaurant (O-Series / Kounta)
                                </a>
                                <div>
                                  <span className="text-xs text-muted">POS</span>
                                </div>
                              </div>
                              <div className="">
                                <a
                                  href="/Kounta/InstallFromSettings"
                                  className="btn btn-sm btn-primary px-4 py-2 text-xs"
                                >
                                  Connect
                                </a>
                              </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center px-0">
                              <div>
                                <a
                                  //href="/KSeries/InstallFromSettings"
                                  onClick={() => generateKSeriesSalesChannel()}
                                  className="avatar pointer"
                                >
                                  <img
                                    alt="Connect Lightspeed Retail K-Series"
                                    src="/img/integrations/Lightspeed_Flame.svg"
                                  />
                                </a>
                              </div>
                              <div className="flex-fill ms-4">
                                <a
                                  //href="/KSeries/InstallFromSettings"
                                  onClick={() => generateKSeriesSalesChannel()}
                                  className="d-block h6 mb-1"
                                >
                                  Lightspeed Restaurant (K-Series)
                                </a>
                                <div>
                                  <span className="text-xs text-muted">POS</span>
                                </div>
                              </div>
                              <div className="">
                                <a
                                  className="btn btn-sm btn-primary px-4 py-2 text-xs"
                                  //href="/KSeries/InstallFromSettings"
                                  onClick={() => generateKSeriesSalesChannel()}
                                >
                                  Connect
                                </a>
                              </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center px-0">
                              <div>
                                <a
                                  href="/Ecwid/InstallFromSettings"
                                  className="avatar"
                                >
                                  <img
                                    alt="Connect Lightspeed eCommerce (Ecwid)"
                                    src="/img/integrations/Lightspeed_Flame.svg"
                                  />
                                </a>
                              </div>
                              <div className="flex-fill ms-4">
                                <a
                                  href="/Ecwid/InstallFromSettings"
                                  className="d-block h6 mb-1"
                                >
                                  Lightspeed eCommerce (Ecwid)
                                </a>
                                <div>
                                  <span className="text-xs text-muted">
                                    eCommerce
                                  </span>
                                </div>
                              </div>
                              <div className="">
                                <a
                                  href="/Ecwid/InstallFromSettings"
                                  className="btn btn-sm btn-primary px-4 py-2 text-xs"
                                >
                                  Connect
                                </a>
                              </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center px-0">
                              <div>
                                <a
                                  onClick={() => setShowCSeriesSettingsModal(true)}
                                  className="avatar pointer"
                                >
                                  <img
                                    alt="Connect Lightspeed Retail C-Series"
                                    src="/img/integrations/Lightspeed_Flame.svg"
                                  />
                                </a>
                              </div>
                              <div className="flex-fill ms-4">
                                <a
                                  onClick={() => setShowCSeriesSettingsModal(true)}
                                  className="d-block h6 mb-1"
                                >
                                  Lightspeed eCommerce (C-Series)
                                </a>
                                <div>
                                  <span className="text-xs text-muted">eCommerce</span>
                                </div>
                              </div>
                              <div className="">
                                <button
                                  className="btn btn-sm btn-primary px-4 py-2 text-xs"
                                  onClick={() => setShowCSeriesSettingsModal(true)}
                                >
                                  Connect
                                </button>
                              </div>
                            </div>

                            <div className="list-group-item d-flex align-items-center px-0">
                              <div>
                                <a
                                  href="/Hike/InstallFromSettings"
                                  className="avatar"
                                >
                                  <img
                                    alt="Connect Hike"
                                    src="/img/integrations/hike-logo.png"
                                  />
                                </a>
                              </div>
                              <div className="flex-fill ms-4">
                                <a
                                  href="/Hike/InstallFromSettings"
                                  className="d-block h6 mb-1"
                                >
                                  Hike
                                </a>
                                <div>
                                  <span className="text-xs text-muted">
                                    POS
                                  </span>
                                </div>
                              </div>
                              <div className="">
                                <a
                                  href="/Hike/InstallFromSettings"
                                  className="btn btn-sm btn-primary px-4 py-2 text-xs"
                                >
                                  Connect
                                </a>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="col-md-6 p-5">
                          <div className="list-group list-group-flush">

                            {!hasConnectedWrappedStorefront && (
                              <div className="list-group-item d-flex align-items-center px-0">
                                <div>
                                  <a
                                    onClick={() =>
                                      generateWrappedStorefrontSalesChannel()
                                    }
                                    className="avatar pointer"
                                  >
                                    <img
                                      alt="Connect Wrapped eCommerce"
                                      src="/img/logos/wrapped-icon.png"
                                    />
                                  </a>
                                </div>
                                <div className="flex-fill ms-4">
                                  <a
                                    onClick={() =>
                                      generateWrappedStorefrontSalesChannel()
                                    }
                                    className="d-block h6 mb-1"
                                  >
                                    Wrapped - Online Gift Card Storefront
                                  </a>
                                  <div>
                                    <span className="text-xs text-muted">
                                      eCommerce
                                    </span>
                                  </div>
                                </div>
                                <div className="">
                                  <button
                                    className="btn btn-sm btn-primary px-4 py-2 text-xs"
                                    onClick={() =>
                                      generateWrappedStorefrontSalesChannel()
                                    }
                                  >
                                    Connect
                                  </button>
                                </div>
                              </div>
                            )}
                            {authContext?.account?.billingProvider != "Shopify" && (
                              <>
                                <div className="list-group-item d-flex align-items-center px-0">
                                  <div>
                                    <a
                                      onClick={() =>
                                        setShowShopifyConnectSettingsModal(true)
                                      }
                                      className="avatar pointer"
                                    >
                                      <img
                                        alt="Connect Shopify"
                                        src="/img/integrations/shopify-bag.png"
                                      />
                                    </a>
                                  </div>
                                  <div className="flex-fill ms-4">
                                    <a
                                      onClick={() =>
                                        setShowShopifyConnectSettingsModal(true)
                                      }
                                      className="d-block h6 mb-1"
                                    >
                                      Shopify
                                    </a>
                                    <div>
                                      <span className="text-xs text-muted">
                                        eCommerce
                                      </span>
                                    </div>
                                  </div>
                                  <div className="">
                                    <a
                                      onClick={() =>
                                        setShowShopifyConnectSettingsModal(true)
                                      }
                                      className="btn btn-sm btn-primary px-4 py-2 text-xs"
                                    >
                                      Connect
                                    </a>
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="list-group-item d-flex align-items-center px-0">
                              <div>
                                <a
                                  href="/Square/InstallFromSettings"
                                  className="avatar"
                                >
                                  <img
                                    alt="Connect Square"
                                    src="/img/integrations/Square_Jewel_Black.svg"
                                  />
                                </a>
                              </div>
                              <div className="flex-fill ms-4">
                                <a
                                  href="/Square/InstallFromSettings"
                                  className="d-block h6 mb-1"
                                >
                                  Square
                                </a>
                                <div>
                                  <span className="text-xs text-muted">
                                    POS and eCommerce
                                  </span>
                                </div>
                              </div>
                              <div className="">
                                <a
                                  href="/Square/InstallFromSettings"
                                  className="btn btn-sm btn-primary px-4 py-2 text-xs"
                                >
                                  Connect
                                </a>
                              </div>
                            </div>

                            <div className="list-group-item d-flex align-items-center px-0">
                              <div>
                                <a
                                  onClick={() => generateBoppleSalesChannel()}
                                  className="avatar pointer"
                                >
                                  <img
                                    alt="Connect Bopple"
                                    src="/img/integrations/bopple-icon2.png"
                                  />
                                </a>
                              </div>
                              <div className="flex-fill ms-4">
                                <a
                                  onClick={() => generateBoppleSalesChannel()}
                                  className="d-block h6 mb-1"
                                >
                                  Bopple
                                </a>
                                <div>
                                  <span className="text-xs text-muted">eCommerce - online ordering for hospitality</span>
                                </div>
                              </div>
                              <div className="">
                                <button
                                  className="btn btn-sm btn-primary px-4 py-2 text-xs"
                                  onClick={() => generateBoppleSalesChannel()}
                                >
                                  Connect
                                </button>
                              </div>
                            </div>

                            <div className="list-group-item d-flex align-items-center px-0">
                              <div>
                                <a
                                  href="/BigCommerce/InstallFromSettings"
                                  className="avatar"
                                >
                                  <img
                                    alt="Connect BigCommerce"
                                    src="/img/integrations/bigcommerce-144.png"
                                  />
                                </a>
                              </div>
                              <div className="flex-fill ms-4">
                                <a
                                  href="/BigCommerce/InstallFromSettings"
                                  className="d-block h6 mb-1"
                                >
                                  BigCommerce
                                </a>
                                <div>
                                  <span className="text-xs text-muted">
                                    eCommerce
                                  </span>
                                </div>
                              </div>
                              <div className="">
                                <a
                                  href="/BigCommerce/InstallFromSettings"
                                  className="btn btn-sm btn-primary px-4 py-2 text-xs"
                                >
                                  Connect
                                </a>
                              </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center px-0">
                              <div>
                                <a
                                  onClick={() =>
                                    setShowWooCommerceSettingsModal(true)
                                  }
                                  className="avatar pointer"
                                >
                                  <img
                                    alt="Connect Shopify"
                                    src="/img/integrations/woocommerce-logo-color-black@2x.png"
                                  />
                                </a>
                              </div>
                              <div className="flex-fill ms-4">
                                <a
                                  onClick={() =>
                                    setShowWooCommerceSettingsModal(true)
                                  }
                                  className="d-block h6 mb-1"
                                >
                                  WooCommerce
                                </a>
                                <div>
                                  <span className="text-xs text-muted">
                                    eCommerce
                                  </span>
                                </div>
                              </div>
                              <div className="">
                                <button
                                  type="button"
                                  onClick={() =>
                                    setShowWooCommerceSettingsModal(true)
                                  }
                                  className="btn btn-sm btn-primary px-4 py-2 text-xs"
                                >
                                  Connect
                                </button>
                              </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center px-0">
                              <div>
                                <a
                                  href="/HeartlandRetail/InstallFromSettings"
                                  className="avatar"
                                >
                                  <img
                                    alt="Connect Heartland Retail"
                                    src="/img/integrations/heartland-icon.png"
                                  />
                                </a>
                              </div>
                              <div className="flex-fill ms-4">
                                <a
                                  href="/HeartlandRetail/InstallFromSettings"
                                  className="d-block h6 mb-1"
                                >
                                  Heartland Retail
                                </a>
                                <div>
                                  <span className="text-xs text-muted">
                                    POS
                                  </span>
                                </div>
                              </div>
                              <div className="">
                                <a
                                  href="/HeartlandRetail/InstallFromSettings"
                                  className="btn btn-sm btn-primary px-4 py-2 text-xs"
                                >
                                  Connect
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </Modal.Body>
                  </Modal>
                  <Modal
                    show={showKSeriesSettingsModal}
                    onHide={() => setShowKSeriesSettingsModal(false)}
                  >
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleKSeriesSettingsSubmit}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          Lightspeed Restaurant (K-Series) Settings
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p className="text-muted mb-3">
                          Please follow the setup instructions{" "}
                          <a
                            href="https://help.wrappedgiftcards.com/en/articles/8508034-connecting-lightspeed-k-series-pos-to-wrapped"
                            target="_blank"
                          >
                            here
                          </a>
                          .
                        </p>
                        <Row className="mb-3">
                          <Form.Label>Sales Channel Display Name</Form.Label>

                          <Form.Control
                            required
                            name={"salesChannelDisplayName"}
                            className="form-control"
                            type="text"
                            value={kseriesSettings.salesChannelDisplayName ?? ""}
                            onChange={handleKSeriesSettingsChange}
                          />
                        </Row>
                        <Row className="mb-3">
                          <Form.Label>Business Location Id</Form.Label>

                          <Form.Control
                            required
                            name={"businessLocationId"}
                            className="form-control"
                            type="text"
                            value={kseriesSettings.businessLocationId ?? ""}
                            onChange={handleKSeriesSettingsChange}
                          />
                        </Row>
                        <Row className="mb-3">
                          <Form.Label>Gift Card Product Id</Form.Label>
                          <Form.Control
                            required
                            name={"giftCardSourceProductId"}
                            className="form-control"
                            type="text"
                            value={
                              kseriesSettings.giftCardSourceProductId ?? ""
                            }
                            onChange={handleKSeriesSettingsChange}
                          />
                        </Row>
                        <div className="row mb-3">
                          <Form.Label>Web extension URL:</Form.Label>
                          <InputGroup className="mt-2">
                            <Form.Control
                              required
                              name={"webExtensionsUrl"}
                              className="form-control text-sm"
                              type="text"
                              value={webExtensionsUrl ?? ""}
                              placeholder="Web extensions url"
                            />
                            <Button
                              variant="outline-secondary"
                              title="copy to clipboard"
                              id="webExtensionsUrl-copy-key"
                              onClick={() =>
                                copyToClipboard(webExtensionsUrl ?? "")
                              }
                            >
                              <i className="bi bi-clipboard"></i>
                            </Button>
                          </InputGroup>
                        </div>
                        <div className="row mb-3">
                          <Form.Label>Order webhook URL:</Form.Label>
                          <InputGroup className="mt-2">
                            <Form.Control
                              required
                              name={"orderWebhookUrl"}
                              className="form-control text-sm"
                              type="text"
                              value={orderWebhookUrl ?? ""}
                              placeholder="Web extensions url"
                            />
                            <Button
                              variant="outline-secondary"
                              title="copy to clipboard"
                              id="orderWebhookUrl-copy-key"
                              onClick={() =>
                                copyToClipboard(orderWebhookUrl ?? "")
                              }
                            >
                              <i className="bi bi-clipboard"></i>
                            </Button>
                          </InputGroup>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={() => setShowKSeriesSettingsModal(false)}
                        >
                          Close
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Save
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </Modal>
                  <Modal
                    show={showRSeriesSettingsModal}
                    onHide={() => setShowRSeriesSettingsModal(false)}
                  >
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleRSeriesSettingsSubmit}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          Lightspeed Retail (R-Series) Settings
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p className="text-muted mb-3">
                          Please follow the setup instructions{" "}
                          <a target="_blank" href="https://help.wrappedgiftcards.com/en/articles/9982003-connecting-lightspeed-retail-pos-r-series">here</a>.
                        </p>
                        <div className="row mb-3">
                          <p className="form-label mb-3">
                            Select your implementation:
                          </p>
                          <div className="d-flex mb-3">
                            <div className="me-3">
                              <Form.Check
                                name="useNativeGiftCards"
                                type={"radio"}
                                id={`useNativeGiftCardsTrue`}
                                checked={
                                  rseriesSettings.useNativeGiftCards === true
                                }
                                onChange={
                                  handleRSeriesSettingsChange
                                }
                                value={"true"}
                              />
                            </div>
                            <div>
                              <label
                                className="form-label mb-0"
                                htmlFor="useNativeGiftCardsTrue"
                              >
                                In-built gift cards
                              </label>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="me-3">
                              <Form.Check
                                name="useNativeGiftCards"
                                type={"radio"}
                                id={`useNativeGiftCardsFalse`}
                                checked={rseriesSettings.useNativeGiftCards === false}
                                onChange={
                                  handleRSeriesSettingsChange
                                }
                                value={"false"}
                              />
                            </div>
                            <div>
                              <label
                                className="form-label mb-0"
                                htmlFor="useNativeGiftCardsFalse"
                              >
                                Custom button
                              </label>
                            </div>
                          </div>
                        </div>
                        {rseriesSettings && !rseriesSettings.useNativeGiftCards &&
                          <div className="row mb-3">
                            <Form.Label>Custom Menu Button URL:</Form.Label>
                            <InputGroup className="mt-2">
                              <Form.Control
                                required
                                name={"webExtensionsUrl"}
                                className="form-control text-sm"
                                type="text"
                                value={webExtensionsUrl ?? ""}
                                placeholder="Web extensions url"
                              />
                              <Button
                                variant="outline-secondary"
                                title="copy to clipboard"
                                id="webExtensionsUrl-copy-key"
                                onClick={() =>
                                  copyToClipboard(webExtensionsUrl ?? "")
                                }
                              >
                                <i className="bi bi-clipboard"></i>
                              </Button>
                            </InputGroup>
                          </div>
                          }
                        <Row className="mb-3">
                          <Form.Label>Gift Card Product Id</Form.Label>
                          <Form.Control
                            required
                            name={"giftCardSourceProductId"}
                            className="form-control"
                            type="text"
                            value={
                              rseriesSettings.giftCardSourceProductId ?? ""
                            }
                            onChange={handleRSeriesSettingsChange}
                          />
                        </Row>
                        <Row className="mb-3">
                          <Form.Label>Wrapped Gift Card Payment Type Id</Form.Label>
                          <Form.Control
                            required
                            name={"wrappedGiftCardPaymentTypeId"}
                            className="form-control"
                            type="text"
                            value={
                              rseriesSettings.wrappedGiftCardPaymentTypeId ?? ""
                            }
                            onChange={handleRSeriesSettingsChange}
                          />
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col}>
                            <Form.Label>Lightspeed location for external gift card adjustments</Form.Label>
                            <Form.Select
                              name="shopId"
                              onChange={handleRSeriesSettingsChange}
                              value={rseriesSettings.shopId ?? ""}
                            >
                              {rseriesLocations &&
                                rseriesLocations?.map((location, i) =>
                                  <option key={"location-" + location.id} value={location.id}>{location.name}</option>
                                )}
                            </Form.Select>
                          </Form.Group>
                          <p className="mt-2 text-sm">Lightspeed requires all gift card adjustments to be assigned to a sale. We create sales against this selected location.</p>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col}>
                            <Form.Label>Lightspeed employee for external gift card adjustments</Form.Label>
                            <Form.Select
                              name="employeeId"
                              onChange={handleRSeriesSettingsChange}
                              value={rseriesSettings.employeeId ?? "1"}
                            >
                              {rseriesEmployees &&
                                rseriesEmployees?.map((employee, i) =>
                                  <option key={"employee-" + employee.id} value={employee.id}>{employee.name}</option>
                                )}
                            </Form.Select>
                          </Form.Group>
                          <p className="mt-2 text-sm">Lightspeed requires all gift card adjustments to be assigned to a sale. We create sales against this selected employee.</p>
                        </Row>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={() => setShowRSeriesSettingsModal(false)}
                        >
                          Close
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Save
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </Modal>

                  <Modal
                    show={showSquareSettingsModal}
                    onHide={() => setShowSquareSettingsModal(false)}
                  >
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSquareSettingsSubmit}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          Square Settings
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {/* <p className="text-muted mb-3">
                          Please follow the setup instructions{" "}
                          <a target="_blank" href="https://help.wrappedgiftcards.com/en/articles/8846508-connecting-lightspeed-retail-pos-r-series">here</a>.
                        </p> */}

                        <Row className="mb-3">
                          <Form.Group as={Col}>
                            <Form.Label>Location which gift card activities will be placed against</Form.Label>
                            <Form.Select
                              name="locationId"
                              onChange={handleSquareSettingsChange}
                              value={squareSettings.locationId ?? ""}
                            >
                              {squareLocations &&
                                squareLocations?.map((location, i) =>
                                  <option value={location.id}>{location.name}</option>
                                )}
                            </Form.Select>
                          </Form.Group>
                          <p className="mt-2 text-sm">Gift cards will be available at all locations.</p>
                        </Row>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={() => setShowSquareSettingsModal(false)}
                        >
                          Close
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Save
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </Modal>

                  <Modal
                    show={showCSeriesSettingsModal}
                    onHide={() => setShowCSeriesSettingsModal(false)}
                  >
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleCSeriesSettingsSubmit}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          Lightspeed eCommerce (C-Series) Settings
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p className="text-muted mb-3">
                          Please follow the setup instructions{" "}
                          <a target="_blank" href="https://help.wrappedgiftcards.com/en/articles/8823901-connecting-lightspeed-ecommerce-c-series">here</a>.
                        </p>

                        <Row className="mb-3">
                          <Form.Label>API Key</Form.Label>
                          <Form.Control
                            required
                            name={"apiKey"}
                            className="form-control"
                            type="text"
                            value={
                              cSeriesSettings.apiKey ?? ""
                            }
                            onChange={handleCSeriesSettingsChange}
                          />
                        </Row>
                        <Row className="mb-3">
                          <Form.Label>API Secret</Form.Label>
                          <Form.Control
                            required
                            name={"apiSecret"}
                            className="form-control"
                            type="text"
                            value={
                              cSeriesSettings.apiSecret ?? ""
                            }
                            onChange={handleCSeriesSettingsChange}
                          />
                        </Row>
                        <Row className="mb-3">
                          <Form.Label>Lightspeed admin domain (Cluster ID)</Form.Label>
                          <Form.Control
                            required
                            name={"clusterId"}
                            className="form-control"
                            type="text"
                            value={
                              cSeriesSettings.clusterId ?? ""
                            }
                            onChange={handleCSeriesSettingsChange}
                          />
                        </Row>
                        <Row className="mb-3">
                          <Form.Label>Gift Card Product Id (optional)</Form.Label>
                          <Form.Control
                            required
                            name={"giftCardSourceProductId"}
                            className="form-control"
                            type="text"
                            value={
                              cSeriesSettings.giftCardSourceProductId ?? ""
                            }
                            onChange={handleCSeriesSettingsChange}
                          />
                        </Row>

                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={() => setShowCSeriesSettingsModal(false)}
                        >
                          Close
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Save
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </Modal>

                  <Modal
                    show={showBoppleSettingsModal}
                    onHide={() => setShowBoppleSettingsModal(false)}
                  >
                    <Form
                      noValidate
                      validated={validated}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          Bopple
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row mb-3">
                          <Form.Label>Copy and paste this API key into Bopple:</Form.Label>
                          <InputGroup className="mt-2">
                            <Form.Control
                              required
                              name={"boppleApiKey"}
                              className="form-control text-sm"
                              type="text"
                              value={boppleApiKey ?? ""}
                              placeholder="Bopple API key"
                            />
                            <Button
                              variant="outline-secondary"
                              title="copy to clipboard"
                              id="boppleApiKey-copy-key"
                              onClick={() =>
                                copyToClipboard(boppleApiKey ?? "")
                              }
                            >
                              <i className="bi bi-clipboard"></i>
                            </Button>
                          </InputGroup>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={() => setShowBoppleSettingsModal(false)}
                        >
                          Close
                        </Button>
                        {/* <Button
                          size="sm"
                          variant="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Save
                        </Button> */}
                      </Modal.Footer>
                    </Form>
                  </Modal>




                  <Modal
                    show={showWooCommerceSettingsModal}
                    onHide={() => setShowWooCommerceSettingsModal(false)}
                  >
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleWooCommerceSubmit}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>WooCommerce settings</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Row className="mb-3">
                          <Form.Group as={Col}>
                            <Form.Label>Enter WooCommerce store url</Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="basic-addon1">
                                https://
                              </InputGroup.Text>
                              <Form.Control
                                required
                                name={"WooDomain"}
                                className="form-control"
                                type="text"
                                value={wooDomain ?? ""}
                                placeholder="examplestore.com"
                                onChange={handleWooDomainChange}
                              />
                            </InputGroup>
                          </Form.Group>
                          <p>
                            <small>
                              You can <a>view instructions</a> here
                            </small>
                          </p>
                        </Row>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={() => setShowWooCommerceSettingsModal(false)}
                        >
                          Close
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Connect
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </Modal>

                  <Modal
                    show={showShopifyConnectSettingsModal}
                    onHide={() => setShowShopifyConnectSettingsModal(false)}
                  >
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleShopifyConnectSubmit}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Shopify settings</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Row className="mb-3">
                          <Form.Group as={Col}>
                            <Form.Label>Enter your Shopify admin store domain</Form.Label>
                            <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                                https://admin.shopify.com/store/
                              </InputGroup.Text>
                              <Form.Control
                                required
                                name={"ShopifyDomain"}
                                className="form-control"
                                type="text"
                                value={shopifyDomain ?? ""}
                                placeholder="examplestore"
                                onChange={handleShopifyDomainChange}
                              />
                             
                            </InputGroup>
                          </Form.Group>
                          <p>
                            <small>
                              You can{" "}
                              <a
                                href="https://help.wrappedgiftcards.com/en/articles/8151998-connecting-shopify"
                                target="_blank"
                              >
                                view instructions
                              </a>{" "}
                              here
                            </small>
                          </p>
                        </Row>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={() => setShowShopifyConnectSettingsModal(false)}
                        >
                          Close
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Connect
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </Modal>


                  <Modal
                    show={showShopifySettingsModal}
                    onHide={() => setShowShopifySettingsModal(false)}
                  >
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleShopifySettingsSubmit}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          Shopify settings
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>

                        <div className="row mb-3">
                          {/* <Form.Label>Lightspeed settings</Form.Label> */}
                          <div className="d-flex mt-3">
                            <div className="me-3">
                              <Form.Check
                                name="shouldAutoFulfillGiftCards"
                                id="shouldAutoFulfillGiftCards"
                                type={"checkbox"}
                                checked={shopifySettings.shouldAutoFulfillGiftCards}
                                onChange={handleShopifySettingsChange}
                              />
                            </div>
                            <div>
                              <label
                                className="form-label mb-0"
                                htmlFor="shouldAutoFulfillGiftCards"
                              >
                                Should automatically fulfill gift cards
                              </label>

                            </div>

                          </div>
                         
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={() => setShowShopifySettingsModal(false)}
                        >
                          Close
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Save
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </Modal>


                  <Modal
                    show={showKountaSettingsModal}
                    onHide={() => setShowKountaSettingsModal(false)}
                  >
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleKountaSettingsSubmit}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          Lightspeed Restaurant (O-Series / Kounta)
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row mb-3">
                          <Form.Label>Allowable site ids (leave blank to apply to all)</Form.Label>
                          <CreatableSelect
                            value={kountaSettings.siteIds?.map(opt => ({ value: opt, label: opt } as SelectOption))}
                            isMulti
                            placeholder="Paste site ids"
                            id="siteIds"
                            name="siteIds"
                            onChange={(e) =>
                              handleSiteIdsChange(e)
                            }
                          />
                        </div>
                        <div className="row mb-3">
                          {/* <Form.Label>Lightspeed settings</Form.Label> */}
                          <div className="d-flex mt-3">
                            <div className="me-3">
                              <Form.Check
                                name="shouldGenerateOrderOnGiftCardPurchase"
                                id="shouldGenerateOrderOnGiftCardPurchase"
                                type={"checkbox"}
                                checked={kountaSettings.shouldGenerateOrderOnGiftCardPurchase}
                                onChange={handleKountaSettingsChange}
                              />
                            </div>
                            <div>
                              <label
                                className="form-label mb-0"
                                htmlFor="shouldGenerateOrderOnGiftCardPurchase"
                              >
                                Create an order in Lightspeed on gift card top-ups. This will allow you to print receipts from Lightspeed.
                              </label>

                            </div>

                          </div>
                          <div className="alert alert-warning mt-5">
                            <small>Note: Enabling this will create an order (as a sale, not a liability) in Lightspeed for all new gift card purchases or top-ups. It will register as additional cash sales that will show in your end of day balances. Please use with caution.</small>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={() => setShowKountaSettingsModal(false)}
                        >
                          Close
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Save
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </Modal>
                  <Modal
                    show={showWooSettingsModal}
                    onHide={() => setShowWooSettingsModal(false)}
                  >
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleWooSettingsSubmit}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          WooCommerce
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row mb-3">
                          <p className="text-muted mb-3">
                            Please select your plugin. {" "}
                            <a
                              className="text-link"
                              href="https://help.wrappedgiftcards.com/en/articles/9428109-woocommerce-plugins"
                              target="_blank"
                            >
                              Learn more
                            </a>
                          </p>
                          <div className="d-flex mb-3">
                            <div className="me-3">
                              <Form.Check
                                name="giftCardType"
                                type={"radio"}
                                id={`wooGiftCardTypeGiftCard`}
                                checked={
                                  wooSettings.giftCardType === GiftCardType.GiftCard
                                }
                                onChange={
                                  handleWooSettingsChange
                                }
                                value={GiftCardType.GiftCard}
                              />
                            </div>
                            <div>
                              <label
                                className="form-label mb-0"
                                htmlFor="wooGiftCardTypeGiftCard"
                              >
                                WooCommerce Gift Cards plugin
                              </label>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="me-3">
                              <Form.Check
                                name="giftCardType"
                                type={"radio"}
                                id={`wooGiftCardTypeSmartCoupon`}
                                checked={wooSettings.giftCardType === GiftCardType.SmartCoupon}
                                onChange={
                                  handleWooSettingsChange
                                }
                                value={GiftCardType.SmartCoupon}
                              />
                            </div>
                            <div>
                              <label
                                className="form-label mb-0"
                                htmlFor="wooGiftCardTypeSmartCoupon"
                              >
                                Smart Coupons for WooCommerce plugin
                              </label>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={() => setShowWooSettingsModal(false)}
                        >
                          Close
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Save
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </Modal>
                </>
              )}

              <div className="alert alert-secondary mt-5 mb-5 text-sm">
                <strong>Don't see your eCommerce or POS listed as a sales channel?</strong><br />
                Create an <Link to={"/account/storefront-settings"}>online storefront</Link> to sell gift cards.<br />
                Use our <Link to={"/account/redeem-app"}>redeem app</Link> to redeem gift cards in-person.
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SalesChannelsPage;
