import React, { useContext, useState, useEffect } from "react";

import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import AccountPlanComponent from "../account/AccountPlanComponent";
const OnboardingChoosePlanPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const headerEl = document.getElementById("header-nav")!
    if (headerEl) {
      headerEl.scrollIntoView();
    }
  }, []);

  return (
    <React.Fragment>
        <header id="header-nav" className="top-0 overlap-10 bg-surface-primary border-bottom">
          <div className="container-fluid py-4">
            <div className="row align-items-center">
              <div className="col">
                <div className="d-flex align-items-center gap-4">
                  <div>
                    <Link
                      className="bi bi-chevron-left text-xs"
                      to={`/onboarding/complete-profile`}
                    ></Link>
                  </div>
                  <div className="vr opacity-20 my-1"></div>
                  {/* <h1 className="h4 ls-tight">{title}</h1> */}
                  <div className="">
                  <h1 className="h4 ls-tight">Choose plan</h1>
                  </div>
                </div>
              </div>
              <div className="col"></div>
              <div className="col-auto">
                <div className="hstack gap-2 justify-content-end">
                  {/* <a
                    href="https://help.wrappedgiftcard.com/en/collections/3830625-account#general"
                    target={"_blank"}
                    className="text-sm text-muted text-primary-hover font-semibold me-2 d-none d-md-block"
                  >
                    <i className="bi bi-question-circle-fill"></i>
                    <span className="d-none d-sm-inline ms-2">Need help?</span>
                  </a> */}
                  <Link
                    to={"/dashboard"}
                    className="btn btn-sm btn-neutral"
                  >
                    <span>Skip</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-7">
              <h5 className="h5 mb-5 text-uppercase text-primary"></h5>
              <h1 className="ls-tight font-bolder mb-4">
                Please choose your plan
              </h1>
              <p className="lead"> Plans include a 14-day free trial. Cancel anytime.</p>
            </div>
          </div>
        </div>
      <AccountPlanComponent stage="onboarding" />

    </React.Fragment>
  );
};

export default OnboardingChoosePlanPage;
