import React, { useState, useEffect, useRef } from "react";
import { AccountApi } from "../../apiclient";
import { ForgotPasswordViewModel, VerifyLogin2faCodeModel } from "../../apiclient/models";
import { Button, Row, Col, Form, Spinner } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { apiConfig } from "../../config";
import "./SignInPage.css";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import { useNavigate } from "react-router";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";

const Login2faPage = () => {
  let accountApi = new AccountApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const AuthInputRef = useRef<AuthCodeRef>(null);
  const [showBackupCodeBlock, setShowBackupCodeBlock] = useState(false as boolean);
  const [backupCode, setBackupCode] = useState('' as string);

  useEffect(() => {}, []);

  const handleOnChange = (res: string) => {
   
    if(res.length >= 6){
      // submit code
      setIsSubmitting(true);
      accountApi
      .accountVerifyLogin2faCodeModelPost("1", {
        codeToVerify: res,
      } as VerifyLogin2faCodeModel)
      .then((response) => {
        if (response.data && response.data.isSuccessful && response.data.redirectUrl) {
          toast.success("Login successful");
          window.location.href = response.data.redirectUrl;
        } else {
          toast.error("Unable to verify code");
          AuthInputRef.current?.clear();
        }

        setIsSubmitting(false);
      })
      .catch((error: any) => {
        toast.error("Unable to verify code");
        AuthInputRef.current?.clear();
        setIsSubmitting(false);
      });

    }
  };

  const handleBackupCodeChange = (event: any) => {
    const target = event.target;
    const value = target.value;
    setBackupCode(value);
  };

  const toggleShowBackupCodeBlock = () => {
    setShowBackupCodeBlock(!showBackupCodeBlock);
  }

  const submitBackupCode = () => {
    setIsSubmitting(true);
    accountApi
    .accountVerify2faBackupCodeModelPost("1", {
      codeToVerify: backupCode,
    } as VerifyLogin2faCodeModel)
    .then((response) => {
      if (response.data && response.data.isSuccessful && response.data.redirectUrl) {
        toast.success("Login successful");
        window.location.href = response.data.redirectUrl;
      } else {
        toast.error("Unable to verify code");
      }

      setIsSubmitting(false);
    })
    .catch((error: any) => {
      toast.error("Unable to verify code");
      setIsSubmitting(false);
    });
  }

  return (
    <React.Fragment>
        <div className="px-5 py-5 p-lg-0 min-h-screen bg-surface-secondary d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-center">
            <div className="col-lg-5 col-xl-4 p-12 p-xl-20 position-fixed start-0 top-0 h-screen overflow-y-hidden bg-dark d-none d-lg-flex flex-column">
              {/* <!-- Logo --> */}
              <a className="d-block" href="https://wrappedgiftcards.com">
                <img
                  src="/img/logos/wrapped-logo.png"
                  className="h-10"
                  alt="..."
                />
              </a>
              {/* <!-- Title --> */}
              <div className="mt-32 mb-20">
                <h1 className="ls-tight font-bolder display-7 text-white mb-5">
                  Create and manage omnichannel gift cards
                </h1>
                <p className="text-white text-opacity-80">
                  Deliver a better customer experience with gift cards that can
                  be redeemed instore or online.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-9 col-lg-7 offset-lg-5 border-left-lg min-h-screen d-flex flex-column justify-content-center position-relative">
              <div className="py-lg-16 px-lg-20">
                <div className="row">
                  <div className="col-lg-10 col-md-9 col-xl-7 mx-auto ms-xl-0">
                    <div className="mt-10 mt-lg-5 mb-6 d-lg-block">
                      <h1 className="ls-tight font-bolder h2">
                        Enter your authentication code
                      </h1>
                    </div>

                    <div className="login-box">
                      <div className="mb-5 mt-5">
                        {/* <label className="form-label" htmlFor="emailAddress">Email address</label>
                                        <input type="emailAddress" className="form-control" id="emailAddress" /> */}
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Check your authentication app for your code
                          </Form.Label>
                          <AuthCode
                            allowedCharacters="numeric"
                            onChange={handleOnChange}
                            ref={AuthInputRef}
                            length={6}
                            inputClassName="input2fa"
                          /> 
                          {isSubmitting && <Spinner className="mt-5" />}
                        </Form.Group>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-10">
                    <div className="col-8">
                      <p>
                        Unable to use authenticator?{" "}
                        <a
                          onClick={() => toggleShowBackupCodeBlock()}
                          className="text-link cursor-pointer change-login-link"
                        >
                          Use a backup code
                        </a>
                      </p>
                      {showBackupCodeBlock && 
                      <>
                      <hr />
                      <div className="login-box">
                      <div className="mb-5 mt-5">
                      
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Check the list of backup codes provided when setting up 2FA
                          </Form.Label>
                          <Form.Control
                              name={"backupCode"}
                              value={backupCode ?? ""}
                              className="form-control"
                              type="text"
                              placeholder="Backup Code"
                              onChange={handleBackupCodeChange}
                            />
                          {isSubmitting && <Spinner className="mt-5" />}
                        </Form.Group>
                      </div>
                      <div>
                          <Button
                            className="w-full"
                            variant="primary"
                            type="button"
                            disabled={isSubmitting}
                            onClick={submitBackupCode}
                          >
                            Submit backup code
                          </Button>
                        </div>
                    </div>
                      </>
                      
                      }


                    </div>
                  </div>
                </div>
              </div>
              <div className="position-lg-absolute bottom-0 end-0 my-8 mx-12 text-center text-lg-end">
                {/* <small>Don't have an account?</small> */}
                <a href="/account/login" className="text-sm font-semibold ml-5">
                  Back to login page
                </a>
              </div>
            </div>
          </div>
        </div>
    </React.Fragment>
  );
};

export default Login2faPage;
