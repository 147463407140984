import React, { useContext, useState, useEffect } from "react";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { AccountApi, GiftCardBatchApi } from "../../apiclient";
import { apiConfig } from "../../config";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Dropdown,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../../contexts/AccountContext";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { GiftcardApi } from "../../apiclient";
import {
  DeliveryStatus,
  GiftCardBatchViewModel,
  GiftCardListItemViewModel,
  GiftCardReasonType,
} from "../../apiclient/models";
import _ from "lodash";
import AnalyticsPanelComponent from "../analytics/AnalyticsPanelComponent";
import GiftCardCsvUploadModalComponent from "./GiftCardCsvUploadModalComponent";
import GridTable from "../../components/GridTable";
import LaunchBanner from "../../components/LaunchBanner";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

const GiftCardsListPage = () => {
  let giftcardApi = new GiftcardApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  let giftCardBatchApi = new GiftCardBatchApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true as boolean);
  const [showMoreFilters, setShowMoreFilters] = useState(false as boolean);
  const authContext = useContext(AuthContext);
  //const dayjs = require("dayjs");
  const [data, setData] = useState([] as GiftCardListItemViewModel[]);
  const [batchList, setBatchList] = useState([] as GiftCardBatchViewModel[]);
  const [pageCount, setPageCount] = React.useState(0);
  const [refreshCount, setRefreshCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setfilterStatus] = useState("");
  const [filterBatchId, setfilterBatchId] = useState("");
  const [filterOrderId, setfilterOrderId] = useState("");
  const [filterIssuedDateFrom, setfilterIssuedDateFrom] = useState("");
  const [filterRecipientEmail, setfilterRecipientEmail] = useState("");
  const [filterBuyerEmail, setfilterBuyerEmail] = useState("");
  const [filterRecipientName, setfilterRecipientName] = useState("");
  const [filterBuyerName, setfilterBuyerName] = useState("");
  const [showUploadGiftCardsCsvModal, setShowUploadGiftCardsCsvModal] =
    useState(false as boolean);
  const [searchButtonText, setSearchButtonText] = useState("Search");
  const [pendingShippingCount, setPendingShippingCount] = useState(0);
  const [filterDeliveryStatus, setFilterDeliveryStatus] = useState("");
  const navigate = useNavigate();

  const tableColumns = [
    {
      Header: "Code Ending",
      accessor: "orderDisplayName",
      Cell: ({ value, row }: any) => {
        var item = row.original as GiftCardListItemViewModel;
        return (
          <React.Fragment>
            <div>
              <Link
                className="nav-link font-semibold row"
                to={`/gift-cards/${item.id}`}
              >
                <div className="d-flex align-items-center col">
                  <i className="bi bi-credit-card me-2 text-lg"></i>
                  {item.code}
                </div>
                {item.notes && (
                  <div className="col align-items-center text-end text-sm pt-1">
                    <OverlayTrigger
                      key={"tooltip" + item.id}
                      placement={"top"}
                      overlay={
                        <Tooltip id={`tooltip-top`}>{item.notes}</Tooltip>
                      }
                    >
                      <i className="bi bi-card-text"></i>
                    </OverlayTrigger>
                  </div>
                )}
              </Link>
            </div>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value, row }: any) => {
        var item = row.original as GiftCardListItemViewModel;
        var badgeColor = "bg-success text-success";
        var cardStatus = "Redeemable";
        if (item.status === "Disabled") {
          cardStatus = "Disabled";
          badgeColor = "bg-danger text-danger";
        } else if (item.totalIssuedAmount == 0) {
          cardStatus = "Empty";
          badgeColor = "bg-secondary text-secondary";
        } else if (item.balance! <= 0) {
          cardStatus = "Redeemed";
          badgeColor = "bg-secondary text-secondary";
        } else if (item.balance === item.initialValue) {
          cardStatus = "Full";
        } else if (item.balance !== item.initialValue && item.balance! > 0) {
          cardStatus = "Partial";
        }


        var deliveryBadgeColor = "bg-success text-success";
        var deliveryStatus = "Sent";
        if (item.deliveryStatus === "PendingCollection") {
          deliveryStatus = "To be collected";
          deliveryBadgeColor = "bg-warning text-warning";
        } else if (item.deliveryStatus === "PendingShipping") {
          deliveryStatus = "To ship";
          deliveryBadgeColor = "bg-warning text-warning";
        } else if (item.deliveryStatus === "Collected") {
          deliveryStatus = "Shipped";
          deliveryBadgeColor = "bg-success text-success";
        } else if (item.deliveryStatus === "Shipped") {
          deliveryStatus = "Shipped";
          deliveryBadgeColor = "bg-success text-success";
        }
        return (
          <React.Fragment>
            <span
              className={`badge text-uppercase bg-opacity-30 ${badgeColor}`}
            >
              {cardStatus}
            </span>
            {(item.deliveryStatus === "PendingShipping" || item.deliveryStatus === "Shipped" || item.recipientEmailAddress && item.deliveryStatus === "Sent") &&
              <span
                className={`ml-10 badge text-uppercase bg-opacity-30 ${deliveryBadgeColor}`}
              >
                {deliveryStatus}
              </span>}

            {item.hasSyncErrors && (
              <>
                <br />
                <Link to={`/gift-cards/${item.id}`}>
                  <span className="badge text-uppercase bg-danger">
                    {" "}
                    <i className="bi bi-exclamation-circle"></i> Sync error
                  </span>
                </Link>
              </>
            )}
          </React.Fragment>
        );
      },
    },
    {
      Header: "Customer recipient",
      accessor: "RecipientEmailAddress",
      Cell: ({ value, row }: any) => {
        var item = row.original as GiftCardListItemViewModel;
        return (
          <React.Fragment>
            {item.recipientEmailAddress ? (
              <span>{item.recipientEmailAddress}</span>
            ) : (
              <span>No customer</span>
            )}
          </React.Fragment>
        );
      },
    },
    {
      Header: "Date Issued",
      accessor: "dateIssued",
      Cell: ({ value, row }: any) => {
        var item = row.original as GiftCardListItemViewModel;
        return (
          <React.Fragment>
            <span>
              {dayjs(item.dateIssued)
                .utc()
                .tz(authContext?.account?.ianaTimezone)
                .format("YYYY-MM-DD hh:mm a")}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Remaining / Value",
      Cell: ({ value, row }: any) => {
        var item = row.original as GiftCardListItemViewModel;
        return (
          <React.Fragment>
            <span className="font-semibold text-heading">
              {item.formattedBalance} / {item.formattedTotalIssuedAmount}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Revenue",
      Cell: ({ value, row }: any) => {
        var item = row.original as GiftCardListItemViewModel;
        return (
          <React.Fragment>
            <Link className="font-semibold text-heading text-link" to={`/reporting/revenue/giftcard/${item.id}`}>
              {item.revenueGeneratedFormatted}
            </Link>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Reason",
      accessor: "reasonType",
      Cell: ({ value, row }: any) => {
        var item = row.original as GiftCardListItemViewModel;
        var badgeColor = "bg-dark text-dark";
        var cardReason = item.reasonType?.toString();
        if (item.reasonType === GiftCardReasonType.Refund) {
          badgeColor = "bg-danger text-danger";
        } else if (item.reasonType === GiftCardReasonType.GeneratedFromBatch) {
          cardReason = "Batch (Generated)";
        } else if (
          item.reasonType === GiftCardReasonType.ConvertedInstoreOnly
        ) {
          cardReason = "Instore only";
        } else if (
          item.reasonType === GiftCardReasonType.ImportedFromBatchCsv
        ) {
          cardReason = "Batch (CSV)";
        } else if (item.reasonType === GiftCardReasonType.CsvImport) {
          cardReason = "Import (CSV)";
        }
        return (
          <React.Fragment>
            <span
              className={`badge text-uppercase bg-opacity-30 ${badgeColor}`}
            >
              {cardReason}
            </span>
            {item.isPromotional &&
              <><br /><span className={`badge text-uppercase bg-opacity-30 bg-primary text-primary mt-2`}>Promo</span></>}
          </React.Fragment>
        );
      },
    },
    // {
    //   Header: "Batch",
    //   accessor: "giftCardBatchId",
    //   Cell: ({ value, row }: any) => {
    //     var item = row.original as GiftCardListItemViewModel;
    //     var batchName = "";
    //     if (item.giftCardBatchId) {
    //       var batch = findBatchById(item.giftCardBatchId);
    //       if (batch) {
    //         batchName = batch.batchName!;
    //       }
    //     }
    //     return (
    //       <React.Fragment>
    //         <span>{batchName}</span>
    //       </React.Fragment>
    //     );
    //   },
    // },
  ];

  const findBatchById = (idToFind: string) => {
    return batchList.find((obj) => obj.giftCardBatchId === idToFind);
  };

  useEffect(() => {
    setfilterBatchId(searchParams.get("batchId") ?? "");
    setfilterOrderId(searchParams.get("orderId") ?? "");
    giftCardBatchApi.giftCardBatchGiftCardBatchListGet().then((response) => {
      var data = response.data as GiftCardBatchViewModel[];
      setBatchList(data);
    });

    fetchPendingShippingCount();
  }, []);

  useEffect(() => {
    setRefreshCount(refreshCount + 1);
  }, [filterStatus, filterBatchId, filterDeliveryStatus]);

  const fetchPendingShippingCount = () => {
    giftcardApi
      .giftcardGetPendingShippingCountGet()
      .then((response) => {
        var data = response.data;
        setPendingShippingCount(data);
      })
      .catch((error: any) => {
      });
  };

  const fetchData = ({ pageSize, pageIndex, search, sortBy, status }: any) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server. But for this example, we'll just fake it.

    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    // Set the loading state
    setLoading(true);
    // Only update the data if this is the latest fetch
    if (fetchId === fetchIdRef.current) {
      var isDesc = sortBy[0] ? sortBy[0].desc ?? "true" : "true";
      var sortField = sortBy[0] ? sortBy[0].id ?? "createdAt" : "createdAt";

      fetchAPIData({
        limit: pageSize,
        pageIndex: pageIndex,
        skip: pageSize * pageIndex,
        search: search,
        sortField: sortField,
        isDesc: isDesc,
        status,
      });
    }
  };

  const fetchAPIData = async ({
    limit,
    pageIndex,
    skip,
    search,
    sortField,
    isDesc,
    status,
  }: any) => {
    try {
      setLoading(true);
      if (!sortField) {
        sortField = "createdAt";
      }
      const serverSortField = sortField[0].toUpperCase() + sortField.slice(1); //capitalize first letter to match server field name
      console.log("fetchAPIData", filterBatchId);
      giftcardApi
        .giftcardListGet(
          filterStatus,
          pageIndex,
          skip,
          limit,
          serverSortField,
          isDesc,
          searchTerm,
          "",
          filterBatchId,
          filterRecipientEmail,
          filterBuyerEmail,
          filterOrderId,
          filterIssuedDateFrom,
          filterBuyerName,
          filterRecipientName,
          filterDeliveryStatus
        )
        .then((response) => {
          const list = response.data.result;
          var pageSize = limit;
          var TotalCount = response.data.totalCount ?? 0;
          var pageCount = ((TotalCount + pageSize - 1) / pageSize) | 0;

          setData(list ?? ([] as GiftCardListItemViewModel[]));
          setPageCount(pageCount);
          setLoading(false);
          setSearchButtonText('Search');
        });
    } catch (e) {
      setLoading(false);
      setSearchButtonText('Search');
    }
  };

  const _handleSearch = _.debounce(
    (search: string) => {
      setSearchTerm(search);
    },
    1500,
    {
      maxWait: 1500,
    }
  );

  const handleBatchChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setfilterBatchId(value);
  };

  const handleDeliveryStatusChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setFilterDeliveryStatus(value);
  };

  const handleRecipientEmailChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setfilterRecipientEmail(value);
  };

  const handleRecipientNameChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setfilterRecipientName(value);
  };

  const handleBuyerEmailChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setfilterBuyerEmail(value);
  };

  const handleBuyerNameChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setfilterBuyerName(value);
  };



  const handleIssuedDateFromChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setfilterIssuedDateFrom(value);
  };

  const handleOrderIdChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setfilterOrderId(value);
  };

  const handleSearchSubmit = (event: any) => {
    setSearchButtonText('Searching..');
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setRefreshCount(refreshCount + 1);
    console.log("search submit");
  };

  var pendingShippingOrderText = (pendingShippingCount === 1) ? "order" : "orders";
  return (
    <React.Fragment>
      <LaunchBanner />
      <div className="container">
        <header>
          <div className="container-fluid">
            <div className="border-bottom pt-6">
              <div className="row align-items-center">
                <div className="col-sm-12 col-12">
                  {(authContext.account?.userRole == "Admin" ||
                    authContext.account?.userRole == "Owner") && (
                      <>
                        <Dropdown as={ButtonGroup} className="float-end ms-2">
                          <Link
                            className="btn btn-primary btn-sm"
                            to={`/gift-cards/new`}
                          >
                            Issue gift card
                          </Link>

                          <Dropdown.Toggle
                            split
                            variant="primary"
                            id="dropdown-split-basic"
                            size="sm"
                          />

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => navigate("/bulk-gift-cards/new")}
                            >
                              Import from CSV file
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                window.open(
                                  `/Giftcard/ExportGiftCardsToCsv`,
                                  "_blank"
                                )
                              }
                            >
                              Export gift cards
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Link
                          className="btn btn-sm btn-neutral font-semibold me-2 d-none d-md-block float-end ms2"
                          to={`/gift-cards/settings`}
                        >
                          Settings
                        </Link>
                      </>
                    )}

                  {/* <Link
                    className="pt-75rem link-primary font-semibold me-2 d-none d-md-block float-end ms-2"
                    to={`/gift-cards`}
                  >
                    Export
                  </Link> */}
                  <h1 className="h2 ls-tight">Gift cards</h1>
                </div>
                <p className="text-sm pb-5">
                  Manage your omnichannel gift cards.
                </p>
              </div>
              <ul className="nav nav-tabs overflow-x border-0">
                <li className="nav-item">
                  <Link className="nav-link active" to={`/gift-cards`}>
                    All gift cards
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={`/bulk-gift-cards`}>
                    Batches
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </header>
        <main className="py-6 bg-surface-secondary">
          <div className="container-fluid vstack gap-3">
            <Form noValidate onSubmit={handleSearchSubmit}>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <label className="form-label" htmlFor="searchTerm">
                        Gift code
                      </label>
                      <div className="input-group input-group-sm input-group-inline">
                        <span className="input-group-text pe-2">
                          <i className="bi bi-search"></i>{" "}
                        </span>
                        <input
                          id="searchTerm"
                          name="searchTerm"
                          type="text"
                          className="form-control"
                          placeholder="Search full gift card code or last 4 digits"
                          aria-label="Search"
                          onChange={(e) => _handleSearch(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <label className="form-label" htmlFor="filterOrderId">
                        Order #
                      </label>
                      <div className="input-group input-group-sm input-group-inline">
                        <span className="input-group-text pe-2">
                          <i className="bi bi-note"></i>{" "}
                        </span>
                        <Form.Control
                          name={"filterOrderId"}
                          className="form-control"
                          type="text"
                          value={filterOrderId}
                          onChange={handleOrderIdChange}
                        />
                      </div>
                    </div>
                    {!showMoreFilters && (
                      <div className="col-3">
                        <label className="form-label" htmlFor="orderId"></label>
                        <div>
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary float-end mr-10"
                          >
                            {searchButtonText}
                          </button>
                          <button
                            className="btn btn-link float-end mr-10 text-sm"
                            type="button"
                            onClick={() => setShowMoreFilters(true)}
                          >
                            More filters
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  {showMoreFilters && (
                    <>
                      <div className="row mt-5">
                        <div className="col-3">
                          <label className="form-label" htmlFor="filterRecipientEmail">
                            Recipient email
                          </label>
                          <div className="input-group input-group-sm input-group-inline">
                            <span className="input-group-text pe-2">
                              <i className="bi bi-envelope"></i>{" "}
                            </span>
                            <Form.Control
                              name={"filterRecipientEmail"}
                              placeholder="e.g. jane@email.com"
                              className="form-control"
                              type="text"
                              value={filterRecipientEmail}
                              onChange={handleRecipientEmailChange}
                            />
                          </div>
                        </div>

                        <div className="col-3">
                          <label className="form-label" htmlFor="filterRecipientName">
                            Recipient name
                          </label>
                          <div className="input-group input-group-sm input-group-inline">
                            <span className="input-group-text pe-2">
                              <i className="bi bi-person"></i>{" "}
                            </span>
                            <Form.Control
                              name={"filterRecipientName"}
                              placeholder="e.g. Jane Doe"
                              className="form-control"
                              type="text"
                              value={filterRecipientName}
                              onChange={handleRecipientNameChange}
                            />
                          </div>
                        </div>

                        <div className="col-3">
                          <label className="form-label" htmlFor="filterBuyerEmail">
                            Gifted from email
                          </label>
                          <div className="input-group input-group-sm input-group-inline">
                            <span className="input-group-text pe-2">
                              <i className="bi bi-envelope"></i>{" "}
                            </span>
                            <Form.Control
                              name={"filterBuyerEmail"}
                              placeholder="e.g. john@email.com"
                              className="form-control"
                              type="text"
                              value={filterBuyerEmail}
                              onChange={handleBuyerEmailChange}
                            />
                          </div>
                        </div>

                        <div className="col-3">
                          <label className="form-label" htmlFor="filterBuyerName">
                            Gifted from name
                          </label>
                          <div className="input-group input-group-sm input-group-inline">
                            <span className="input-group-text pe-2">
                              <i className="bi bi-person"></i>{" "}
                            </span>
                            <Form.Control
                              name={"filterBuyerName"}
                              placeholder="e.g. John Doe"
                              className="form-control"
                              type="text"
                              value={filterBuyerName}
                              onChange={handleBuyerNameChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-3">
                          <label className="form-label" htmlFor="filterIssuedDateFrom">
                            Issued since
                          </label>
                          <div className="input-group input-group-sm input-group-inline">
                            <span className="input-group-text pe-2">
                              <i className="bi bi-calendar"></i>{" "}
                            </span>
                            <Form.Control
                              name={"filterIssuedDateFrom"}
                              className="form-control"
                              type="date"
                              value={filterIssuedDateFrom}
                              onChange={handleIssuedDateFromChange}
                            />
                          </div>
                        </div>

                        <div className="col-3">
                          <label className="form-label" htmlFor="delayType">
                            Batch
                          </label>
                          <div className="">
                            <Form.Select
                              size="sm"
                              className="form-control"
                              name="delayType"
                              onChange={(e) => handleBatchChange(e)}
                              value={filterBatchId}
                            >
                              <option key="empty" value={""}>
                                Showing all batches
                              </option>
                              {batchList.map((option) => (
                                <option
                                  key={option.giftCardBatchId}
                                  value={option.giftCardBatchId}
                                >
                                  {option.batchName}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                        <div className="col-3">
                          <label className="form-label" htmlFor="filterDeliveryStatusType">
                            Delivery status
                          </label>
                          <div className="">
                            <Form.Select
                              size="sm"
                              className="form-control"
                              name="filterDeliveryStatusType"
                              onChange={(e) => handleDeliveryStatusChange(e)}
                              value={filterDeliveryStatus}
                            >
                              <option key="empty" value={""}>
                                Showing all delivery methods
                              </option>
                              <option
                                  value={DeliveryStatus.Sent}
                                >
                                  Sent
                                </option>
                                <option
                                  value={DeliveryStatus.PendingShipping}
                                >
                                  Pending Shipping
                                </option>
                                <option
                                  value={DeliveryStatus.Shipped}
                                >
                                  Shipped
                                </option>
                               
                            </Form.Select>
                          </div>
                        </div>
                        <div className="col-3">
                          <label
                            className="form-label"
                            htmlFor="searchTerm"
                          ></label>
                          <div>
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary float-end mr-10"
                            >
                              {searchButtonText}
                            </button>
                            <button
                              className="btn btn-link float-end mr-10 text-sm"
                              type="button"
                              onClick={() => setShowMoreFilters(false)}
                            >
                              Less filters
                            </button>
                          </div>
                        </div>
                        {/* <div className="col-3">
                  <label className="form-label" htmlFor="searchTerm">
                        Reason
                      </label>
                    <div className="">
                      <Form.Select
                        size="sm"
                        className="form-control"
                        name="delayType"
                        onChange={(e) => handleBatchChange(e)}
                        value={filterBatchId}
                      >
                        <option key="empty" value={""}>
                          Showing all batches
                        </option>
                        {batchList.map((option) => (
                          <option
                            key={option.giftCardBatchId}
                            value={option.giftCardBatchId}
                          >
                            {option.batchName}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </div> */}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Form>
            {pendingShippingCount > 0 && (
              <div className="alert alert-warning" role="alert">
                <div className="row align-items-center">
                  <div className="col font-bold">
                    There is {pendingShippingCount} {pendingShippingOrderText} that need shipping
                  </div>

                  <div className="col-auto">
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="button"
                        onClick={() => setFilterDeliveryStatus("PendingShipping")}
                        className="btn d-inline-flex btn-sm btn-primary mx-1"
                      >
                        <span>View {pendingShippingOrderText}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="card">
              <div className="col-sm-12 col-12">

                <ul className="nav nav-pills py-3 pl-20">
                  <li className="nav-item">
                    <button
                      className={`nav-link ${filterStatus === "" ? "active" : ""
                        }`}
                      onClick={() => setfilterStatus("")}
                    >
                      All
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${filterStatus === "redeemable" ? "active" : ""
                        }`}
                      onClick={() => setfilterStatus("redeemable")}
                    >
                      Redeemable
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${filterStatus === "full" ? "active" : ""
                        }`}
                      onClick={() => setfilterStatus("full")}
                    >
                      Full
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${filterStatus === "partial" ? "active" : ""
                        }`}
                      onClick={() => setfilterStatus("partial")}
                    >
                      Partial
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${filterStatus === "redeemed" ? "active" : ""
                        }`}
                      onClick={() => setfilterStatus("redeemed")}
                    >
                      Redeemed
                    </button>
                  </li>

                  <li className="nav-item">
                    <button
                      className={`nav-link ${filterStatus === "disabled" ? "active" : ""
                        }`}
                      onClick={() => setfilterStatus("disabled")}
                    >
                      Disabled
                    </button>
                  </li>
                </ul>
              </div>
              <div className="p-5 pt-0">
                <GridTable
                  columns={tableColumns}
                  data={data}
                  fetchData={fetchData}
                  loading={loading}
                  pageCount={pageCount}
                  search={searchTerm}
                  goToFirstPage={undefined}
                  status={refreshCount} //hack to support other filters
                />
              </div>
              <div></div>
            </div>
          </div>
        </main>
      </div>
      <GiftCardCsvUploadModalComponent
        show={showUploadGiftCardsCsvModal}
        onHide={() => setShowUploadGiftCardsCsvModal(false)}
      />
    </React.Fragment>
  );
};

export default GiftCardsListPage;
