import React, { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "../contexts/AccountContext";
import { IntercomProvider, useIntercom } from "react-use-intercom";

export interface IntercomChatProps {
  openChat: () => void;
  pushMessageText: (textMessage: string) => void;
}
const initialState = {
  openChat: () => {},
  pushMessageText: (textMessage: string) => {
    console.log("pushMessageText", textMessage);
  },
};

const IntercomChatContext = createContext(initialState);

const IntercomChat = () => {
  const {
    boot,
    shutdown,
    hardShutdown,
    update,
    hide,
    show,
    showMessages,
    showNewMessages,
    getVisitorId,
    startTour,
    trackEvent,
    showArticle,
  } = useIntercom();

  const authContext = useContext(AuthContext);
  useEffect(() => {
    var email = authContext.account?.emailAddress;
    var name = authContext.account?.name;
    var accountId = authContext.account?.accountId;


    var isWrappedAdmin = authContext.account?.isWrappedAdmin ?? false;
    if (authContext && authContext.account && !isWrappedAdmin) {
      boot({
        name: name,
        email: email,
        userHash: authContext.account?.intercomUserHash,
        phone: authContext.account?.phone || "",
        userId: authContext.account?.userId,
        customAttributes: {
          is_owner: authContext.account?.isOwner,
          last_request_at: parseInt((new Date().getTime() / 1000).toString()),
          wrapped_user_role: authContext.account?.userRole,
          wrapped_account_user_id: authContext.account?.userId,
          wrapped_account_created_at: authContext.account?.createdAtUnixTimestamp
        },
        company: {
          companyId: accountId || "",
          name: authContext.account?.companyName,
          monthlySpend: authContext.account?.billingSubscriptionPrice,
          plan: authContext.account?.billingPlan,
          website: authContext.account?.integrationDomain,
          industry: authContext.account?.businessCategory,
          customAttributes: {
            business_category: authContext.account?.businessCategory,
            business_type: authContext.account?.businessType,
            platform: authContext.account?.integration,
            account_status: authContext.account?.accountStatus,
            integration_plan_name: authContext.account?.integraitonPlanName,
            stripe_billing_subscription_id: authContext.account?.billingSubscriptionId,
            hdyhau: authContext.account?.hdyhau,
            onboarding_num_locations_range: authContext.account?.numOfLocationsRange
          },
        },
      });
    }
  });

  return <React.Fragment></React.Fragment>;
};

export default IntercomChat;
export { IntercomChatContext };
