import React, { useContext, useState, useEffect } from "react";
import AccountPlanComponent from "./AccountPlanComponent";
import { Link, useSearchParams } from 'react-router-dom';
import { AuthContext } from "../../contexts/AccountContext";
import "./AccountPlanPage.css";
import toast, { Toaster } from "react-hot-toast";

const AccountPlanPage = () => {
  const authContext = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const isUpgradeRequired = searchParams.get("upgradeRequired") ?? "";
    if(authContext.account && isUpgradeRequired){
      toast("Please upgrade your plan to use this feature", {
        icon: '🔒',
      });
    }
  });

  return (
    <React.Fragment>
      {authContext.account?.billingPlan === "Free" &&
       <div className="container">
       <div className="row justify-content-center text-center">
         <div className="col-lg-7">
           <h5 className="h5 mb-5 text-uppercase text-primary"></h5>
           <h1 className="ls-tight font-bolder mb-4">
             Please choose your plan
           </h1>
           <p className="lead"> Plans include a 14-day free trial. Cancel anytime.</p>
         </div>
       </div>
     </div>}
      <AccountPlanComponent />
      
    </React.Fragment>
  );
};

export default AccountPlanPage;
