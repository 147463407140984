import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AccountContext";
import SwitchAccountModal from "../pages/account/SwitchAccountModal";
import { CrispChatContext } from "./CrispChat";

const SideBar = () => {
  const authContext = useContext(AuthContext);
  const crispChatContext = useContext(CrispChatContext);
  const [isSwitchAccountModalShown, setIsSwitchAccountModalShown] = useState(false);
  const numProfiles = authContext.account?.associatedAccountIds?.length ?? 1;
  //let navigate = useNavigate();

  function openHelpChat() {
    console.log("loadHelpChat");
    crispChatContext.openChat();
  }

  const logout = (event: any) => {
    (window as any).Intercom('shutdown');
    window.location.href = '/Account/Logout';
  }


  const renderEnabledBadge = (isEnabled: boolean) => {
    
    return (
      <>
      {isEnabled ? (
        <span className="badge bg-opacity-30 bg-success text-success rounded-pill d-inline-flex align-items-center ms-auto">
          enabled
        </span>) :(
          <span className="badge bg-opacity-30 bg-danger text-danger rounded-pill d-inline-flex align-items-center ms-auto">
          disabled
        </span>
        )}
        </>
    );
  };

  return (
    <React.Fragment>
      <nav
        className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-light border-bottom border-bottom-lg-0 border-end-lg"
        id="navbarVertical"
      >
        <div className="container-fluid">
          {/* <!-- Toggler --> */}
          <button
            className="navbar-toggler ms-n2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapse"
            aria-controls="sidebarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* <!-- Brand --> */}
          {/* <a className="navbar-brand py-lg-2 mb-lg-5 px-lg-6 me-0" href="#">
      <img src="https://preview.webpixels.io/web/img/logos/clever-primary.svg" alt="..." />
    </a> */}
          <Link
            className="navbar-brand d-flex align-items-center gap-2 py-lg-2 mb-lg-5 px-lg-6 me-0"
            to={`/`}
          >
            <div className="w-md-auto  text-dark">
              <img src="/img/logos/gift-card-white-512.png" alt="..." />
            </div>
            <span className="text-heading h3 font-bold ls-tight">
              Wrapped
            </span>
          </Link>
          {/* <!-- Collapse --> */}
          <div className="collapse navbar-collapse" id="sidebarCollapse">
            {/* <!-- Navigation --> */}
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to={`/`}>
                  <i className="bi bi-bi bi-card-checklist"></i> Survey
                  {renderEnabledBadge(authContext.account?.surveyEnabled ?? false)}
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to={`/email`}>
                  <i className="bi bi-bi bi-envelope"></i> Follow-up Email
                  {renderEnabledBadge(authContext.account?.emailEnabled ?? false)}
                </Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to={`/surveys/analytics`}>
                  <i className="bi bi-bar-chart"></i> Analytics
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={`/templates`}>
                  <i className="bi bi-collection"></i> Question Library
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={`/manage-integrations`}>
                  <i className="bi bi-plug"></i> Integrations
                </Link>
              </li>
            </ul>
            {/* <!-- Divider --> */}
            <hr className="navbar-divider my-5 opacity-20" />
            {/* <!-- Navigation --> */}
            <ul className="navbar-nav mb-md-4">
              <li className="nav-item">
              <Link className="nav-link" to={`/account`}>
                  <i className="bi bi-person-square"></i> Account
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  target={"_blank"}
                  //onClick={openHelpChat}
                  href="https://help.wrappedgiftcard.com"
                >
                  <i className="bi bi-question-circle"></i> Help
                </a>
              </li>
            </ul>
            {/* <!-- Push content down --> */}
            <div className="mt-auto"></div>
            {/* <!-- Notification box --> */}
            {/* {authContext.account?.billingPlan === "Free" &&
            <div className="card bg-primary text-center text-white border-0 mx-6 mb-16 d-none d-lg-block rounded-5 shadow-3">
              <div className="px-5 pt-10 pb-7">
                <h4 className="ls-tight text-white font-semibold mb-1">
                <i className="bi bi-stars"></i> Upgrade your results
                </h4>
                <div className=""></div>
                <p className="text-sm text-white text-opacity-75">
                Customize your survey and integrate with your marketing stack.
                </p>
                <div className="mt-7 mb-2 ">
                  <Link className="btn btn-sm btn-white" to={`/billingplan`}>
                    Upgrade
                    </Link>
                </div>
              </div>
            </div>} */}

            {/* <!-- User (md) --> */}
            <div className="navbar-nav my-5">
              <div className="dropup w-full px-6">
                <button
                  className="btn-white d-flex w-full py-2 ps-2 pe-4 align-items-center shadow shadow-3-hover rounded-3 dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {/* <div className="me-4">
              <img alt="..." src="https://preview.webpixels.io/web/img/brand/webpixels-icon-dark.png" className="avatar rounded-3" />
            </div> */}
                  <div className="flex-fill text-start">
                    <span className="d-block text-sm font-semibold">
                      {authContext.account?.companyName}
                    </span>
                      <p className="text-nowrap text-xs text-muted">{authContext.account?.emailAddress?.toString()}</p>
                    <p className="text-nowrap text-xs text-muted">Plan: {authContext.account?.billingPlan?.toString()}</p>
                  {authContext.account?.isTest && <p className="text-nowrap text-xs text-muted"><span className="badge rounded-pill bg-secondary text-dark">Test account</span></p>}
                  </div>
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-centered"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                  <Link className="dropdown-item" to={`/account`}>
                  <i className="bi bi-person-square"></i> Account settings
                </Link>
                  </li>
                  {numProfiles > 1 &&
                  <li>
                    <a className="dropdown-item cursor-pointer" onClick={()=>setIsSwitchAccountModalShown(true)}>
                      <i className="bi bi-arrow-left-right"></i> Switch account
                    </a>
                  </li>
                  }
                  <li>
                    <a className="dropdown-item  cursor-pointer" onClick={logout}>
                      <i className="bi bi-box-arrow-left"></i> Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- Push content down --> */}
            {/* <div className="mt-auto"></div> */}
            {/* <!-- User (md) --> */}
            {/* <ul className="navbar-nav mb-5">
          <li className="nav-item">
            <a className="nav-link" href="#">
              <i className="bi bi-person-square"></i> Account
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              <i className="bi bi-box-arrow-left"></i> Logout
            </a>
          </li>
        </ul> */}
          </div>
        </div>
      </nav>
      <SwitchAccountModal show={isSwitchAccountModalShown} onHide={()=>setIsSwitchAccountModalShown(false)}  />
    </React.Fragment>
  );
};

export default SideBar;
