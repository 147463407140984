import React, { useState, useEffect } from "react";
import { AccountApi } from "../../apiclient";
import {
  ResetPasswordViewModel
} from "../../apiclient/models";
import { Button, Row, Col, Form } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { apiConfig } from "../../config";
import "./SignInPage.css";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import { Link, useSearchParams, useNavigate } from "react-router-dom";


const ResetPasswordPage = () => {
  let accountApi = new AccountApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [model, setModel] = useState({} as ResetPasswordViewModel);
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const [loginButtonText, setLoginButtonText] = useState("Set password" as string);
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code") ?? "";
  const userId = searchParams.get("userId") ?? "";

  useEffect(() => {
    if(!code || !userId){
      navigate('/account/login?t=pw');
    }
    setModel((values: ResetPasswordViewModel) => ({
      ...values,
      ['code']: code,
      ['userId']: userId
    }));
  }, []);

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setLoginButtonText("Sending..");
        accountApi
          .accountResetPasswordPost("1", model)
          .then((response) => {
            toast.success("Your password has been reset. Please log in", {
              duration: 10000,
            });
            setIsSubmitting(false);
            setLoginButtonText("Set password");
            navigate("/account/login?t=pw");
          })
          .catch((error: any) => {

            var errorMessage = error.response.data.error ?? "Please try again with a stronger password - min. 12 alphanumeric characters";
            toast.error(errorMessage, {
              duration: 10000,
            });
            setLoginButtonText("Set password");
            setIsSubmitting(false);
          });
      }
    
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;

   
      setModel((values: ResetPasswordViewModel) => ({
        ...values,
        [name]: value,
      }));
    
  };

  return (
    <React.Fragment>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="px-5 py-5 p-lg-0 min-h-screen bg-surface-secondary d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-center">
            <div className="col-lg-5 col-xl-4 p-12 p-xl-20 position-fixed start-0 top-0 h-screen overflow-y-hidden bg-dark d-none d-lg-flex flex-column">
              {/* <!-- Logo --> */}
              <a className="d-block" href="https://wrappedgiftcards.com">
                <img
                  src="/img/logos/wrapped-logo.png"
                  className="h-10"
                  alt="..."
                />
              </a>
              {/* <!-- Title --> */}
              <div className="mt-32 mb-20">
                <h1 className="ls-tight font-bolder display-7 text-white mb-5">
                  Create and manage omnichannel gift cards
                </h1>
                <p className="text-white text-opacity-80">
                  Deliver a better customer experience with gift cards that can
                  be redeemed instore or online.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-9 col-lg-7 offset-lg-5 border-left-lg min-h-screen d-flex flex-column justify-content-center position-relative">
              <div className="py-lg-16 px-lg-20">
                <div className="row">
                  <div className="col-lg-10 col-md-9 col-xl-7 mx-auto ms-xl-0">
                    <div className="mt-10 mt-lg-5 mb-6 d-lg-block">
                      <h1 className="ls-tight font-bolder h2">
                        Reset Your Password
                      </h1>
                    </div>


                      <div className="login-box">
                        <div className="mb-5 mt-5">
                          <Form.Group className="mb-3">
                            <Form.Label htmlFor="password">New Password (min. 12 alphanumeric characters) </Form.Label> 
                            <Form.Control
                              required
                              name={"password"}
                              value={model.password ?? ""}
                              className="form-control"
                              type="password"
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label htmlFor="confirmPassword">Confirm Password </Form.Label> 
                            <Form.Control
                              required
                              name={"confirmPassword"}
                              value={model.confirmPassword ?? ""}
                              className="form-control"
                              type="password"
                              onChange={handleChange}
                            />
                          </Form.Group>
                         
                        </div>
                        <div>
                          <Button
                            className="w-full"
                            variant="primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {loginButtonText}
                          </Button>
                        </div>
                      </div>
                    
                  </div>

                
                <div className="row mt-10">
                  <div className="col-12">
                  <a onClick={()=>navigate("/account/login?t=pw")} className="text-link cursor-pointer change-login-link">Back to log in</a>
                  </div>
                </div>
                </div>
    
              </div>
              <div className="position-lg-absolute bottom-0 end-0 my-8 mx-12 text-center text-lg-end">
                <small>Don't have an account?</small>
                <a
                  href="/account/signup"
                  className="text-sm font-semibold ml-5"
                >
                  Create a free account
                </a>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default ResetPasswordPage;
