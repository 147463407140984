import React, { useState, useEffect } from "react";
import { StorefrontApi, StorefrontShippingOption } from "../../apiclient";
import Form from "react-bootstrap/Form";
import toast, { Toaster } from "react-hot-toast";
import { apiConfig } from "../../config";
import Modal from "react-bootstrap/Modal";
import { Button, Row, Col, InputGroup, Spinner } from "react-bootstrap";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import Select from "react-select";

const EditStorefrontShippingOptionModal = (props: any) => {
  let storefrontApi = new StorefrontApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [model, setModel] = useState({} as StorefrontShippingOption);
  const [countryOptions, setCountryOptions] = useState<{ value: string; label: string }[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<{ value: string; label: string }[]>([]);

  // Fetch the list of countries from the REST Countries API
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();
        const formattedCountries = data.map((country: any) => ({
          value: country.cca2, // ISO 3166-1 alpha-2 code
          label: country.name.common, // Country name
        }));
        setCountryOptions(formattedCountries);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  const onModalShow = () => {
    console.log("props.shippingOption", props.shippingOption);
    if (props.shippingOption) {
      setModel(props.shippingOption);
    }
    setValidated(false);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    //setIsSubmitting(true);
    const form = event.currentTarget;

    setValidated(true);
    if (form.checkValidity() === true) {
      //setIsSubmitting(false);
      //callback to insert/update collection
      props.updated(model);
    }
  };

  const handleChange = (event: any) => {
    const target = event.target;
    console.log(
      event.target.type,
      event.target.name,
      event.currentTarget.value
    );
    var value =
      target.type === "checkbox" ||
        target.type === "radio" ||
        target.type === "switch"
        ? target.checked
        : target.value;
    const name = event.target.name;
    console.log("handleChange", name, value);
    setModel((values: StorefrontShippingOption) => ({
      ...values,
      [name]: value,
    }));
  };
  return (
    <React.Fragment>
      <Modal show={props.show} fullscreen onHide={props.onHide} onShow={onModalShow}>
        <Modal.Header closeButton>
          <Modal.Title>Shipping option</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row pb-10">
              <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                <h4 className="font-semibold mb-2">Name & price</h4>
                <p className="text-sm">
                  This is what your customers will see and be charged during checkout
                </p>
              </div>
              <div className="col-lg-8">
                <Form.Group className="mt-5">
                  <Form.Label
                  >
                    Shipping option name
                  </Form.Label>
                  <Form.Control
                    name="name"
                    value={model.name || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mt-5">
                  <Form.Label
                  >
                    Description (optional)
                  </Form.Label>

                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder=""
                    name="description"
                    value={model.description || ""}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mt-5">
                  <Form.Label
                  >
                    Price to charge purchaser
                  </Form.Label>
                  <Form.Control
                    name="price"
                    value={model.price || ""}
                    onChange={handleChange}
                    type="number"
                    required
                  />
                </Form.Group>
              </div>
            </div>
            {/* <div className="row mb-10">
              <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                <h4 className="font-semibold mb-2">Applies to these countries</h4>
                <p className="text-sm">
                  Please select which country/countries this option applies to. If you just ship to New Zealand, select only New Zealand.
                </p>
              </div>
              <div className="col-lg-8">
                <Form.Group className="mt-5">
                  <Select
                    options={countryOptions}
                    isMulti
                    value={selectedCountries}
                    onChange={handleChange}
                    placeholder="Search and select countries"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isLoading={countryOptions.length === 0} // Show loading spinner while fetching
                  />
                </Form.Group>
              </div>
            </div> */}
            <Modal.Footer>
              <Button
                className="btn btn-sm btn-neutral"
                variant={"secondary"}
                onClick={props.onHide}
              >
                Close
              </Button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-sm btn-primary"
              >
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>

      </Modal>
    </React.Fragment>
  );
};

export default EditStorefrontShippingOptionModal;
