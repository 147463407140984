/* tslint:disable */
/* eslint-disable */
/**
 * GiftKard API
 * An API for GiftKard Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@wrappedgiftcards.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddToCartSendGiftCardToFriendViewModel } from '../models';
// @ts-ignore
import { CSeriesSettingPostViewModel } from '../models';
// @ts-ignore
import { EditGiftCardViewModel } from '../models';
// @ts-ignore
import { EmailNotificationViewModel } from '../models';
// @ts-ignore
import { GiftCardCodeFormattingViewModel } from '../models';
// @ts-ignore
import { GiftCardDetailViewModel } from '../models';
// @ts-ignore
import { GiftCardExpiryViewModel } from '../models';
// @ts-ignore
import { GiftCardListItemViewModelListAndCountResult } from '../models';
// @ts-ignore
import { GiftCardManualAdjustmentViewModel } from '../models';
// @ts-ignore
import { GiftCardMarkAsShippedPostViewModel } from '../models';
// @ts-ignore
import { GiftCardNotesViewModel } from '../models';
// @ts-ignore
import { GiftCardProductIdAndAccountId } from '../models';
// @ts-ignore
import { GiftCardProductViewModel } from '../models';
// @ts-ignore
import { GiftCardSaveNotesModel } from '../models';
// @ts-ignore
import { GiftCardShippingAddressViewModel } from '../models';
// @ts-ignore
import { GiftCardSyncErrorsViewModel } from '../models';
// @ts-ignore
import { GiftCardTimelineEventViewModelListAndCountResult } from '../models';
// @ts-ignore
import { GiftCardViewModel } from '../models';
// @ts-ignore
import { KSeriesSettingPostViewModel } from '../models';
// @ts-ignore
import { KountaSettingsPostViewModel } from '../models';
// @ts-ignore
import { PerformSyncGiftCardViewModel } from '../models';
// @ts-ignore
import { RSeriesEmployeeViewModel } from '../models';
// @ts-ignore
import { RSeriesSettingPostViewModel } from '../models';
// @ts-ignore
import { RSeriesShopLocationViewModel } from '../models';
// @ts-ignore
import { RegisterInstoreGiftCardViewModel } from '../models';
// @ts-ignore
import { SalesChannelListItemViewModel } from '../models';
// @ts-ignore
import { SalesChannelSourceProductUpdatePostPayload } from '../models';
// @ts-ignore
import { SendGiftCardToFriendViewModel } from '../models';
// @ts-ignore
import { SendgridEmailEventListItemModelListAndCountResult } from '../models';
// @ts-ignore
import { ShopifySettingsPostViewModel } from '../models';
// @ts-ignore
import { SquareLocationViewModel } from '../models';
// @ts-ignore
import { SquareSettingPostViewModel } from '../models';
// @ts-ignore
import { UploadGiftCardsVideoMessageResultPayload } from '../models';
// @ts-ignore
import { WooCommerceSettingsPostViewModel } from '../models';
/**
 * GiftcardApi - axios parameter creator
 * @export
 */
export const GiftcardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardAddToCartGiftCardToFriendIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('giftcardAddToCartGiftCardToFriendIdGet', 'id', id)
            const localVarPath = `/Giftcard/AddToCartGiftCardToFriend/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardCodeFormattingSettingsGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/CodeFormattingSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardViewModel} [giftCardViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardCreateGiftCardPost: async (apiVersion?: string, giftCardViewModel?: GiftCardViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/CreateGiftCard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(giftCardViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardDeleteGiftCardByIdIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('giftcardDeleteGiftCardByIdIdGet', 'id', id)
            const localVarPath = `/Giftcard/DeleteGiftCardById/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardDeleteSalesChannelIdDelete: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('giftcardDeleteSalesChannelIdDelete', 'id', id)
            const localVarPath = `/Giftcard/DeleteSalesChannel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardEmailSuppressionSettingsGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/EmailSuppressionSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardExpirySettingsGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/ExpirySettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardExportGiftCardsToCsvGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/ExportGiftCardsToCsv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGenerateBoppleSalesChannelPost: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/GenerateBoppleSalesChannel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGenerateKSeriesSalesChannelPost: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/GenerateKSeriesSalesChannel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGenerateUniqueCodeByAccountIdGet: async (accountId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/GenerateUniqueCodeByAccountId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGenerateWrappedStorefrontSalesChannelPost: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/GenerateWrappedStorefrontSalesChannel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGetByCodeCodeGet: async (code: string, accountId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('giftcardGetByCodeCodeGet', 'code', code)
            const localVarPath = `/Giftcard/GetByCode/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGetByIdIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('giftcardGetByIdIdGet', 'id', id)
            const localVarPath = `/Giftcard/GetById/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [giftCardId] 
         * @param {string} [eventType] 
         * @param {number} [page] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortField] 
         * @param {boolean} [isDesc] 
         * @param {string} [search] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGetEmailEventsPageByGiftCardGet: async (giftCardId?: string, eventType?: string, page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/GetEmailEventsPageByGiftCard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (giftCardId !== undefined) {
                localVarQueryParameter['giftCardId'] = giftCardId;
            }

            if (eventType !== undefined) {
                localVarQueryParameter['eventType'] = eventType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (isDesc !== undefined) {
                localVarQueryParameter['isDesc'] = isDesc;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountPlatformId 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGetGiftCardProductIdAccountPlatformIdGet: async (accountPlatformId: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountPlatformId' is not null or undefined
            assertParamExists('giftcardGetGiftCardProductIdAccountPlatformIdGet', 'accountPlatformId', accountPlatformId)
            const localVarPath = `/Giftcard/GetGiftCardProductId/{accountPlatformId}`
                .replace(`{${"accountPlatformId"}}`, encodeURIComponent(String(accountPlatformId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [giftCardId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGetGiftCardSyncErrorsViewModelGet: async (giftCardId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/GetGiftCardSyncErrorsViewModel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (giftCardId !== undefined) {
                localVarQueryParameter['giftCardId'] = giftCardId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGetPendingShippingCountGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/GetPendingShippingCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [giftcardId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGetTimelineEventsByGiftCardIdGet: async (giftcardId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/GetTimelineEventsByGiftCardId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (giftcardId !== undefined) {
                localVarQueryParameter['giftcardId'] = giftcardId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGiftCardToFriendIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('giftcardGiftCardToFriendIdGet', 'id', id)
            const localVarPath = `/Giftcard/GiftCardToFriend/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGiftToFriendEmailHtmlGet: async (id?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/GiftToFriendEmailHtml`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardHasShopifyChannelConnectedGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/HasShopifyChannelConnected`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardInitCreateGiftCardGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/InitCreateGiftCard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [status] 
         * @param {number} [page] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortField] 
         * @param {boolean} [isDesc] 
         * @param {string} [search] 
         * @param {string} [reasonType] 
         * @param {string} [batchId] 
         * @param {string} [recipientEmail] 
         * @param {string} [buyerEmail] 
         * @param {string} [orderNumber] 
         * @param {string} [issuedSince] 
         * @param {string} [buyerName] 
         * @param {string} [recipientName] 
         * @param {string} [deliveryStatus] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardListGet: async (status?: string, page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, reasonType?: string, batchId?: string, recipientEmail?: string, buyerEmail?: string, orderNumber?: string, issuedSince?: string, buyerName?: string, recipientName?: string, deliveryStatus?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (isDesc !== undefined) {
                localVarQueryParameter['isDesc'] = isDesc;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (reasonType !== undefined) {
                localVarQueryParameter['reasonType'] = reasonType;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            if (recipientEmail !== undefined) {
                localVarQueryParameter['recipientEmail'] = recipientEmail;
            }

            if (buyerEmail !== undefined) {
                localVarQueryParameter['buyerEmail'] = buyerEmail;
            }

            if (orderNumber !== undefined) {
                localVarQueryParameter['orderNumber'] = orderNumber;
            }

            if (issuedSince !== undefined) {
                localVarQueryParameter['issuedSince'] = issuedSince;
            }

            if (buyerName !== undefined) {
                localVarQueryParameter['buyerName'] = buyerName;
            }

            if (recipientName !== undefined) {
                localVarQueryParameter['recipientName'] = recipientName;
            }

            if (deliveryStatus !== undefined) {
                localVarQueryParameter['deliveryStatus'] = deliveryStatus;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardMarkAsShippedPostViewModel} [giftCardMarkAsShippedPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardMarkAsShippedPost: async (apiVersion?: string, giftCardMarkAsShippedPostViewModel?: GiftCardMarkAsShippedPostViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/MarkAsShipped`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(giftCardMarkAsShippedPostViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [primaryGiftCardId] 
         * @param {string} [mergeFromGiftCardId] 
         * @param {boolean} [sendEmail] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardMergeGiftcardGet: async (accountId?: string, primaryGiftCardId?: string, mergeFromGiftCardId?: string, sendEmail?: boolean, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/MergeGiftcard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (primaryGiftCardId !== undefined) {
                localVarQueryParameter['primaryGiftCardId'] = primaryGiftCardId;
            }

            if (mergeFromGiftCardId !== undefined) {
                localVarQueryParameter['mergeFromGiftCardId'] = mergeFromGiftCardId;
            }

            if (sendEmail !== undefined) {
                localVarQueryParameter['sendEmail'] = sendEmail;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardPdfIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('giftcardPdfIdGet', 'id', id)
            const localVarPath = `/Giftcard/Pdf/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardManualAdjustmentViewModel} [giftCardManualAdjustmentViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardPerformGiftCardManualAdjustmentPost: async (apiVersion?: string, giftCardManualAdjustmentViewModel?: GiftCardManualAdjustmentViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/PerformGiftCardManualAdjustment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(giftCardManualAdjustmentViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {PerformSyncGiftCardViewModel} [performSyncGiftCardViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardPerformSyncGiftCardPost: async (apiVersion?: string, performSyncGiftCardViewModel?: PerformSyncGiftCardViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/PerformSyncGiftCard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(performSyncGiftCardViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardProductSettingsGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/ProductSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [accountPlatformId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardRSeriesEmployeeListGet: async (accountPlatformId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/RSeriesEmployeeList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountPlatformId !== undefined) {
                localVarQueryParameter['accountPlatformId'] = accountPlatformId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [accountPlatformId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardRSeriesLocationListGet: async (accountPlatformId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/RSeriesLocationList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountPlatformId !== undefined) {
                localVarQueryParameter['accountPlatformId'] = accountPlatformId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {RegisterInstoreGiftCardViewModel} [registerInstoreGiftCardViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardRegisterInstoreCardPost: async (apiVersion?: string, registerInstoreGiftCardViewModel?: RegisterInstoreGiftCardViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/RegisterInstoreCard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerInstoreGiftCardViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardRemoveVideoMessageIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('giftcardRemoveVideoMessageIdGet', 'id', id)
            const localVarPath = `/Giftcard/RemoveVideoMessage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [emailType] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardResendEmailByTypeGet: async (id?: string, emailType?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/ResendEmailByType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (emailType !== undefined) {
                localVarQueryParameter['emailType'] = emailType;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardResendEmailIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('giftcardResendEmailIdGet', 'id', id)
            const localVarPath = `/Giftcard/ResendEmail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSalesChannelListGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/SalesChannelList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CSeriesSettingPostViewModel} [cSeriesSettingPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveCSeriesChannelSettingsPost: async (apiVersion?: string, cSeriesSettingPostViewModel?: CSeriesSettingPostViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/SaveCSeriesChannelSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cSeriesSettingPostViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardCodeFormattingViewModel} [giftCardCodeFormattingViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveCodeFormattingSettingsPost: async (apiVersion?: string, giftCardCodeFormattingViewModel?: GiftCardCodeFormattingViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/SaveCodeFormattingSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(giftCardCodeFormattingViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {EditGiftCardViewModel} [editGiftCardViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveEditGiftCardPost: async (apiVersion?: string, editGiftCardViewModel?: EditGiftCardViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/SaveEditGiftCard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editGiftCardViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardShippingAddressViewModel} [giftCardShippingAddressViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveEditShippingAddressPost: async (apiVersion?: string, giftCardShippingAddressViewModel?: GiftCardShippingAddressViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/SaveEditShippingAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(giftCardShippingAddressViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [strEmailsToSuppress] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveEmailSuppressionSettingsPost: async (strEmailsToSuppress?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/SaveEmailSuppressionSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (strEmailsToSuppress !== undefined) {
                localVarQueryParameter['strEmailsToSuppress'] = strEmailsToSuppress;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardExpiryViewModel} [giftCardExpiryViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveExpirySettingsPost: async (apiVersion?: string, giftCardExpiryViewModel?: GiftCardExpiryViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/SaveExpirySettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(giftCardExpiryViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardNotesViewModel} [giftCardNotesViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveGifCardNotesPost: async (apiVersion?: string, giftCardNotesViewModel?: GiftCardNotesViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/SaveGifCardNotes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(giftCardNotesViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardSaveNotesModel} [giftCardSaveNotesModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveGiftCardNotesPost: async (apiVersion?: string, giftCardSaveNotesModel?: GiftCardSaveNotesModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/SaveGiftCardNotes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(giftCardSaveNotesModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {KSeriesSettingPostViewModel} [kSeriesSettingPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveKSeriesChannelSettingsPost: async (apiVersion?: string, kSeriesSettingPostViewModel?: KSeriesSettingPostViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/SaveKSeriesChannelSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kSeriesSettingPostViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {KountaSettingsPostViewModel} [kountaSettingsPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveKountaChannelSettingsPost: async (apiVersion?: string, kountaSettingsPostViewModel?: KountaSettingsPostViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/SaveKountaChannelSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kountaSettingsPostViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {string} [imageUrl] 
         * @param {any} [image] 
         * @param {string} [variants] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveProductSettingsPost: async (apiVersion?: string, title?: string, description?: string, imageUrl?: string, image?: any, variants?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/SaveProductSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


            if (title !== undefined) { 
                localVarFormParams.append('title', title as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
            if (imageUrl !== undefined) { 
                localVarFormParams.append('imageUrl', imageUrl as any);
            }
    
            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
            if (variants !== undefined) { 
                localVarFormParams.append('variants', variants as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {RSeriesSettingPostViewModel} [rSeriesSettingPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveRSeriesChannelSettingsPost: async (apiVersion?: string, rSeriesSettingPostViewModel?: RSeriesSettingPostViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/SaveRSeriesChannelSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rSeriesSettingPostViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SalesChannelListItemViewModel} [salesChannelListItemViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveSalesChannelPost: async (apiVersion?: string, salesChannelListItemViewModel?: SalesChannelListItemViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/SaveSalesChannel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salesChannelListItemViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ShopifySettingsPostViewModel} [shopifySettingsPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveShopifyChannelSettingsPost: async (apiVersion?: string, shopifySettingsPostViewModel?: ShopifySettingsPostViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/SaveShopifyChannelSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shopifySettingsPostViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SquareSettingPostViewModel} [squareSettingPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveSquareChannelSettingsPost: async (apiVersion?: string, squareSettingPostViewModel?: SquareSettingPostViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/SaveSquareChannelSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(squareSettingPostViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {WooCommerceSettingsPostViewModel} [wooCommerceSettingsPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveWooCommerceChannelSettingsPost: async (apiVersion?: string, wooCommerceSettingsPostViewModel?: WooCommerceSettingsPostViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/SaveWooCommerceChannelSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wooCommerceSettingsPostViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SendGiftCardToFriendViewModel} [sendGiftCardToFriendViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSendGiftCardToFriendPost: async (apiVersion?: string, sendGiftCardToFriendViewModel?: SendGiftCardToFriendViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/SendGiftCardToFriend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendGiftCardToFriendViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [accountPlatformId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSquareLocationListGet: async (accountPlatformId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/SquareLocationList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountPlatformId !== undefined) {
                localVarQueryParameter['accountPlatformId'] = accountPlatformId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardToggleIsPromotionalGiftCardByIdIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('giftcardToggleIsPromotionalGiftCardByIdIdGet', 'id', id)
            const localVarPath = `/Giftcard/ToggleIsPromotionalGiftCardById/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SalesChannelSourceProductUpdatePostPayload} [salesChannelSourceProductUpdatePostPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardUpdateSalesChannelGiftCardSourceProductIdPost: async (apiVersion?: string, salesChannelSourceProductUpdatePostPayload?: SalesChannelSourceProductUpdatePostPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/UpdateSalesChannelGiftCardSourceProductId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salesChannelSourceProductUpdatePostPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {string} [giftCardId] 
         * @param {any} [videoMessageFile] 
         * @param {any} [videoMessageThumbnailFile] 
         * @param {number} [videoMessageSize] 
         * @param {number} [videoMessageThumbnailSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardUploadGiftCardVideoMessagePost: async (apiVersion?: string, giftCardId?: string, videoMessageFile?: any, videoMessageThumbnailFile?: any, videoMessageSize?: number, videoMessageThumbnailSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/UploadGiftCardVideoMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


            if (giftCardId !== undefined) { 
                localVarFormParams.append('giftCardId', giftCardId as any);
            }
    
            if (videoMessageFile !== undefined) { 
                localVarFormParams.append('videoMessageFile', videoMessageFile as any);
            }
    
            if (videoMessageThumbnailFile !== undefined) { 
                localVarFormParams.append('videoMessageThumbnailFile', videoMessageThumbnailFile as any);
            }
    
            if (videoMessageSize !== undefined) { 
                localVarFormParams.append('videoMessageSize', videoMessageSize as any);
            }
    
            if (videoMessageThumbnailSize !== undefined) { 
                localVarFormParams.append('videoMessageThumbnailSize', videoMessageThumbnailSize as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {any} [csvFile] 
         * @param {Array<string>} [syncToSelectAccountPlatformsOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardUploadGiftCardsCsvPost: async (apiVersion?: string, csvFile?: any, syncToSelectAccountPlatformsOnly?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Giftcard/UploadGiftCardsCsv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


            if (csvFile !== undefined) { 
                localVarFormParams.append('csvFile', csvFile as any);
            }
                if (syncToSelectAccountPlatformsOnly) {
                localVarFormParams.append('syncToSelectAccountPlatformsOnly', syncToSelectAccountPlatformsOnly.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardVoidGiftCardByIdIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('giftcardVoidGiftCardByIdIdGet', 'id', id)
            const localVarPath = `/Giftcard/VoidGiftCardById/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GiftcardApi - functional programming interface
 * @export
 */
export const GiftcardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GiftcardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardAddToCartGiftCardToFriendIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddToCartSendGiftCardToFriendViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardAddToCartGiftCardToFriendIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardCodeFormattingSettingsGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardCodeFormattingViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardCodeFormattingSettingsGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardViewModel} [giftCardViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardCreateGiftCardPost(apiVersion?: string, giftCardViewModel?: GiftCardViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardCreateGiftCardPost(apiVersion, giftCardViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardDeleteGiftCardByIdIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardDeleteGiftCardByIdIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardDeleteSalesChannelIdDelete(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardDeleteSalesChannelIdDelete(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardEmailSuppressionSettingsGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardEmailSuppressionSettingsGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardExpirySettingsGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardExpiryViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardExpirySettingsGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardExportGiftCardsToCsvGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardExportGiftCardsToCsvGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardGenerateBoppleSalesChannelPost(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesChannelListItemViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardGenerateBoppleSalesChannelPost(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardGenerateKSeriesSalesChannelPost(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesChannelListItemViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardGenerateKSeriesSalesChannelPost(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardGenerateUniqueCodeByAccountIdGet(accountId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardGenerateUniqueCodeByAccountIdGet(accountId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardGenerateWrappedStorefrontSalesChannelPost(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesChannelListItemViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardGenerateWrappedStorefrontSalesChannelPost(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardGetByCodeCodeGet(code: string, accountId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardDetailViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardGetByCodeCodeGet(code, accountId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardGetByIdIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardDetailViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardGetByIdIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [giftCardId] 
         * @param {string} [eventType] 
         * @param {number} [page] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortField] 
         * @param {boolean} [isDesc] 
         * @param {string} [search] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardGetEmailEventsPageByGiftCardGet(giftCardId?: string, eventType?: string, page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendgridEmailEventListItemModelListAndCountResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardGetEmailEventsPageByGiftCardGet(giftCardId, eventType, page, skip, limit, sortField, isDesc, search, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountPlatformId 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardGetGiftCardProductIdAccountPlatformIdGet(accountPlatformId: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardProductIdAndAccountId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardGetGiftCardProductIdAccountPlatformIdGet(accountPlatformId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [giftCardId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardGetGiftCardSyncErrorsViewModelGet(giftCardId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardSyncErrorsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardGetGiftCardSyncErrorsViewModelGet(giftCardId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardGetPendingShippingCountGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardGetPendingShippingCountGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [giftcardId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardGetTimelineEventsByGiftCardIdGet(giftcardId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardTimelineEventViewModelListAndCountResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardGetTimelineEventsByGiftCardIdGet(giftcardId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardGiftCardToFriendIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendGiftCardToFriendViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardGiftCardToFriendIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardGiftToFriendEmailHtmlGet(id?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailNotificationViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardGiftToFriendEmailHtmlGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardHasShopifyChannelConnectedGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardHasShopifyChannelConnectedGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardInitCreateGiftCardGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardInitCreateGiftCardGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [status] 
         * @param {number} [page] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortField] 
         * @param {boolean} [isDesc] 
         * @param {string} [search] 
         * @param {string} [reasonType] 
         * @param {string} [batchId] 
         * @param {string} [recipientEmail] 
         * @param {string} [buyerEmail] 
         * @param {string} [orderNumber] 
         * @param {string} [issuedSince] 
         * @param {string} [buyerName] 
         * @param {string} [recipientName] 
         * @param {string} [deliveryStatus] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardListGet(status?: string, page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, reasonType?: string, batchId?: string, recipientEmail?: string, buyerEmail?: string, orderNumber?: string, issuedSince?: string, buyerName?: string, recipientName?: string, deliveryStatus?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardListItemViewModelListAndCountResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardListGet(status, page, skip, limit, sortField, isDesc, search, reasonType, batchId, recipientEmail, buyerEmail, orderNumber, issuedSince, buyerName, recipientName, deliveryStatus, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardMarkAsShippedPostViewModel} [giftCardMarkAsShippedPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardMarkAsShippedPost(apiVersion?: string, giftCardMarkAsShippedPostViewModel?: GiftCardMarkAsShippedPostViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardMarkAsShippedPost(apiVersion, giftCardMarkAsShippedPostViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [primaryGiftCardId] 
         * @param {string} [mergeFromGiftCardId] 
         * @param {boolean} [sendEmail] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardMergeGiftcardGet(accountId?: string, primaryGiftCardId?: string, mergeFromGiftCardId?: string, sendEmail?: boolean, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardMergeGiftcardGet(accountId, primaryGiftCardId, mergeFromGiftCardId, sendEmail, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardPdfIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardPdfIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardManualAdjustmentViewModel} [giftCardManualAdjustmentViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardPerformGiftCardManualAdjustmentPost(apiVersion?: string, giftCardManualAdjustmentViewModel?: GiftCardManualAdjustmentViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardPerformGiftCardManualAdjustmentPost(apiVersion, giftCardManualAdjustmentViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {PerformSyncGiftCardViewModel} [performSyncGiftCardViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardPerformSyncGiftCardPost(apiVersion?: string, performSyncGiftCardViewModel?: PerformSyncGiftCardViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardPerformSyncGiftCardPost(apiVersion, performSyncGiftCardViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardProductSettingsGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardProductViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardProductSettingsGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [accountPlatformId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardRSeriesEmployeeListGet(accountPlatformId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RSeriesEmployeeViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardRSeriesEmployeeListGet(accountPlatformId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [accountPlatformId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardRSeriesLocationListGet(accountPlatformId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RSeriesShopLocationViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardRSeriesLocationListGet(accountPlatformId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {RegisterInstoreGiftCardViewModel} [registerInstoreGiftCardViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardRegisterInstoreCardPost(apiVersion?: string, registerInstoreGiftCardViewModel?: RegisterInstoreGiftCardViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardDetailViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardRegisterInstoreCardPost(apiVersion, registerInstoreGiftCardViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardRemoveVideoMessageIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardRemoveVideoMessageIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [emailType] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardResendEmailByTypeGet(id?: string, emailType?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardResendEmailByTypeGet(id, emailType, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardResendEmailIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardResendEmailIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardSalesChannelListGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SalesChannelListItemViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardSalesChannelListGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CSeriesSettingPostViewModel} [cSeriesSettingPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardSaveCSeriesChannelSettingsPost(apiVersion?: string, cSeriesSettingPostViewModel?: CSeriesSettingPostViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardSaveCSeriesChannelSettingsPost(apiVersion, cSeriesSettingPostViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardCodeFormattingViewModel} [giftCardCodeFormattingViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardSaveCodeFormattingSettingsPost(apiVersion?: string, giftCardCodeFormattingViewModel?: GiftCardCodeFormattingViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardSaveCodeFormattingSettingsPost(apiVersion, giftCardCodeFormattingViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {EditGiftCardViewModel} [editGiftCardViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardSaveEditGiftCardPost(apiVersion?: string, editGiftCardViewModel?: EditGiftCardViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardSaveEditGiftCardPost(apiVersion, editGiftCardViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardShippingAddressViewModel} [giftCardShippingAddressViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardSaveEditShippingAddressPost(apiVersion?: string, giftCardShippingAddressViewModel?: GiftCardShippingAddressViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardSaveEditShippingAddressPost(apiVersion, giftCardShippingAddressViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [strEmailsToSuppress] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardSaveEmailSuppressionSettingsPost(strEmailsToSuppress?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardSaveEmailSuppressionSettingsPost(strEmailsToSuppress, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardExpiryViewModel} [giftCardExpiryViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardSaveExpirySettingsPost(apiVersion?: string, giftCardExpiryViewModel?: GiftCardExpiryViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardSaveExpirySettingsPost(apiVersion, giftCardExpiryViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardNotesViewModel} [giftCardNotesViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardSaveGifCardNotesPost(apiVersion?: string, giftCardNotesViewModel?: GiftCardNotesViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardSaveGifCardNotesPost(apiVersion, giftCardNotesViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardSaveNotesModel} [giftCardSaveNotesModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardSaveGiftCardNotesPost(apiVersion?: string, giftCardSaveNotesModel?: GiftCardSaveNotesModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardSaveGiftCardNotesPost(apiVersion, giftCardSaveNotesModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {KSeriesSettingPostViewModel} [kSeriesSettingPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardSaveKSeriesChannelSettingsPost(apiVersion?: string, kSeriesSettingPostViewModel?: KSeriesSettingPostViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardSaveKSeriesChannelSettingsPost(apiVersion, kSeriesSettingPostViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {KountaSettingsPostViewModel} [kountaSettingsPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardSaveKountaChannelSettingsPost(apiVersion?: string, kountaSettingsPostViewModel?: KountaSettingsPostViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardSaveKountaChannelSettingsPost(apiVersion, kountaSettingsPostViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {string} [imageUrl] 
         * @param {any} [image] 
         * @param {string} [variants] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardSaveProductSettingsPost(apiVersion?: string, title?: string, description?: string, imageUrl?: string, image?: any, variants?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardSaveProductSettingsPost(apiVersion, title, description, imageUrl, image, variants, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {RSeriesSettingPostViewModel} [rSeriesSettingPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardSaveRSeriesChannelSettingsPost(apiVersion?: string, rSeriesSettingPostViewModel?: RSeriesSettingPostViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardSaveRSeriesChannelSettingsPost(apiVersion, rSeriesSettingPostViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SalesChannelListItemViewModel} [salesChannelListItemViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardSaveSalesChannelPost(apiVersion?: string, salesChannelListItemViewModel?: SalesChannelListItemViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardSaveSalesChannelPost(apiVersion, salesChannelListItemViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ShopifySettingsPostViewModel} [shopifySettingsPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardSaveShopifyChannelSettingsPost(apiVersion?: string, shopifySettingsPostViewModel?: ShopifySettingsPostViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardSaveShopifyChannelSettingsPost(apiVersion, shopifySettingsPostViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SquareSettingPostViewModel} [squareSettingPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardSaveSquareChannelSettingsPost(apiVersion?: string, squareSettingPostViewModel?: SquareSettingPostViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardSaveSquareChannelSettingsPost(apiVersion, squareSettingPostViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {WooCommerceSettingsPostViewModel} [wooCommerceSettingsPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardSaveWooCommerceChannelSettingsPost(apiVersion?: string, wooCommerceSettingsPostViewModel?: WooCommerceSettingsPostViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardSaveWooCommerceChannelSettingsPost(apiVersion, wooCommerceSettingsPostViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SendGiftCardToFriendViewModel} [sendGiftCardToFriendViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardSendGiftCardToFriendPost(apiVersion?: string, sendGiftCardToFriendViewModel?: SendGiftCardToFriendViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardSendGiftCardToFriendPost(apiVersion, sendGiftCardToFriendViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [accountPlatformId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardSquareLocationListGet(accountPlatformId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SquareLocationViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardSquareLocationListGet(accountPlatformId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardToggleIsPromotionalGiftCardByIdIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardToggleIsPromotionalGiftCardByIdIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SalesChannelSourceProductUpdatePostPayload} [salesChannelSourceProductUpdatePostPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardUpdateSalesChannelGiftCardSourceProductIdPost(apiVersion?: string, salesChannelSourceProductUpdatePostPayload?: SalesChannelSourceProductUpdatePostPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardUpdateSalesChannelGiftCardSourceProductIdPost(apiVersion, salesChannelSourceProductUpdatePostPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {string} [giftCardId] 
         * @param {any} [videoMessageFile] 
         * @param {any} [videoMessageThumbnailFile] 
         * @param {number} [videoMessageSize] 
         * @param {number} [videoMessageThumbnailSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardUploadGiftCardVideoMessagePost(apiVersion?: string, giftCardId?: string, videoMessageFile?: any, videoMessageThumbnailFile?: any, videoMessageSize?: number, videoMessageThumbnailSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadGiftCardsVideoMessageResultPayload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardUploadGiftCardVideoMessagePost(apiVersion, giftCardId, videoMessageFile, videoMessageThumbnailFile, videoMessageSize, videoMessageThumbnailSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {any} [csvFile] 
         * @param {Array<string>} [syncToSelectAccountPlatformsOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardUploadGiftCardsCsvPost(apiVersion?: string, csvFile?: any, syncToSelectAccountPlatformsOnly?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardUploadGiftCardsCsvPost(apiVersion, csvFile, syncToSelectAccountPlatformsOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftcardVoidGiftCardByIdIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftcardVoidGiftCardByIdIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GiftcardApi - factory interface
 * @export
 */
export const GiftcardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GiftcardApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardAddToCartGiftCardToFriendIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<AddToCartSendGiftCardToFriendViewModel> {
            return localVarFp.giftcardAddToCartGiftCardToFriendIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardCodeFormattingSettingsGet(apiVersion?: string, options?: any): AxiosPromise<GiftCardCodeFormattingViewModel> {
            return localVarFp.giftcardCodeFormattingSettingsGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardViewModel} [giftCardViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardCreateGiftCardPost(apiVersion?: string, giftCardViewModel?: GiftCardViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardCreateGiftCardPost(apiVersion, giftCardViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardDeleteGiftCardByIdIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardDeleteGiftCardByIdIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardDeleteSalesChannelIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardDeleteSalesChannelIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardEmailSuppressionSettingsGet(apiVersion?: string, options?: any): AxiosPromise<string> {
            return localVarFp.giftcardEmailSuppressionSettingsGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardExpirySettingsGet(apiVersion?: string, options?: any): AxiosPromise<GiftCardExpiryViewModel> {
            return localVarFp.giftcardExpirySettingsGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardExportGiftCardsToCsvGet(apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.giftcardExportGiftCardsToCsvGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGenerateBoppleSalesChannelPost(apiVersion?: string, options?: any): AxiosPromise<SalesChannelListItemViewModel> {
            return localVarFp.giftcardGenerateBoppleSalesChannelPost(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGenerateKSeriesSalesChannelPost(apiVersion?: string, options?: any): AxiosPromise<SalesChannelListItemViewModel> {
            return localVarFp.giftcardGenerateKSeriesSalesChannelPost(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGenerateUniqueCodeByAccountIdGet(accountId?: string, apiVersion?: string, options?: any): AxiosPromise<string> {
            return localVarFp.giftcardGenerateUniqueCodeByAccountIdGet(accountId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGenerateWrappedStorefrontSalesChannelPost(apiVersion?: string, options?: any): AxiosPromise<SalesChannelListItemViewModel> {
            return localVarFp.giftcardGenerateWrappedStorefrontSalesChannelPost(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGetByCodeCodeGet(code: string, accountId?: string, apiVersion?: string, options?: any): AxiosPromise<GiftCardDetailViewModel> {
            return localVarFp.giftcardGetByCodeCodeGet(code, accountId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGetByIdIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<GiftCardDetailViewModel> {
            return localVarFp.giftcardGetByIdIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [giftCardId] 
         * @param {string} [eventType] 
         * @param {number} [page] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortField] 
         * @param {boolean} [isDesc] 
         * @param {string} [search] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGetEmailEventsPageByGiftCardGet(giftCardId?: string, eventType?: string, page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, apiVersion?: string, options?: any): AxiosPromise<SendgridEmailEventListItemModelListAndCountResult> {
            return localVarFp.giftcardGetEmailEventsPageByGiftCardGet(giftCardId, eventType, page, skip, limit, sortField, isDesc, search, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountPlatformId 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGetGiftCardProductIdAccountPlatformIdGet(accountPlatformId: string, apiVersion?: string, options?: any): AxiosPromise<GiftCardProductIdAndAccountId> {
            return localVarFp.giftcardGetGiftCardProductIdAccountPlatformIdGet(accountPlatformId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [giftCardId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGetGiftCardSyncErrorsViewModelGet(giftCardId?: string, apiVersion?: string, options?: any): AxiosPromise<GiftCardSyncErrorsViewModel> {
            return localVarFp.giftcardGetGiftCardSyncErrorsViewModelGet(giftCardId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGetPendingShippingCountGet(apiVersion?: string, options?: any): AxiosPromise<number> {
            return localVarFp.giftcardGetPendingShippingCountGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [giftcardId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGetTimelineEventsByGiftCardIdGet(giftcardId?: string, apiVersion?: string, options?: any): AxiosPromise<GiftCardTimelineEventViewModelListAndCountResult> {
            return localVarFp.giftcardGetTimelineEventsByGiftCardIdGet(giftcardId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGiftCardToFriendIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<SendGiftCardToFriendViewModel> {
            return localVarFp.giftcardGiftCardToFriendIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardGiftToFriendEmailHtmlGet(id?: string, apiVersion?: string, options?: any): AxiosPromise<EmailNotificationViewModel> {
            return localVarFp.giftcardGiftToFriendEmailHtmlGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardHasShopifyChannelConnectedGet(apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardHasShopifyChannelConnectedGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardInitCreateGiftCardGet(apiVersion?: string, options?: any): AxiosPromise<GiftCardViewModel> {
            return localVarFp.giftcardInitCreateGiftCardGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [status] 
         * @param {number} [page] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortField] 
         * @param {boolean} [isDesc] 
         * @param {string} [search] 
         * @param {string} [reasonType] 
         * @param {string} [batchId] 
         * @param {string} [recipientEmail] 
         * @param {string} [buyerEmail] 
         * @param {string} [orderNumber] 
         * @param {string} [issuedSince] 
         * @param {string} [buyerName] 
         * @param {string} [recipientName] 
         * @param {string} [deliveryStatus] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardListGet(status?: string, page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, reasonType?: string, batchId?: string, recipientEmail?: string, buyerEmail?: string, orderNumber?: string, issuedSince?: string, buyerName?: string, recipientName?: string, deliveryStatus?: string, apiVersion?: string, options?: any): AxiosPromise<GiftCardListItemViewModelListAndCountResult> {
            return localVarFp.giftcardListGet(status, page, skip, limit, sortField, isDesc, search, reasonType, batchId, recipientEmail, buyerEmail, orderNumber, issuedSince, buyerName, recipientName, deliveryStatus, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardMarkAsShippedPostViewModel} [giftCardMarkAsShippedPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardMarkAsShippedPost(apiVersion?: string, giftCardMarkAsShippedPostViewModel?: GiftCardMarkAsShippedPostViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardMarkAsShippedPost(apiVersion, giftCardMarkAsShippedPostViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [primaryGiftCardId] 
         * @param {string} [mergeFromGiftCardId] 
         * @param {boolean} [sendEmail] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardMergeGiftcardGet(accountId?: string, primaryGiftCardId?: string, mergeFromGiftCardId?: string, sendEmail?: boolean, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardMergeGiftcardGet(accountId, primaryGiftCardId, mergeFromGiftCardId, sendEmail, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardPdfIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.giftcardPdfIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardManualAdjustmentViewModel} [giftCardManualAdjustmentViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardPerformGiftCardManualAdjustmentPost(apiVersion?: string, giftCardManualAdjustmentViewModel?: GiftCardManualAdjustmentViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardPerformGiftCardManualAdjustmentPost(apiVersion, giftCardManualAdjustmentViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {PerformSyncGiftCardViewModel} [performSyncGiftCardViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardPerformSyncGiftCardPost(apiVersion?: string, performSyncGiftCardViewModel?: PerformSyncGiftCardViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardPerformSyncGiftCardPost(apiVersion, performSyncGiftCardViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardProductSettingsGet(apiVersion?: string, options?: any): AxiosPromise<GiftCardProductViewModel> {
            return localVarFp.giftcardProductSettingsGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [accountPlatformId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardRSeriesEmployeeListGet(accountPlatformId?: string, apiVersion?: string, options?: any): AxiosPromise<Array<RSeriesEmployeeViewModel>> {
            return localVarFp.giftcardRSeriesEmployeeListGet(accountPlatformId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [accountPlatformId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardRSeriesLocationListGet(accountPlatformId?: string, apiVersion?: string, options?: any): AxiosPromise<Array<RSeriesShopLocationViewModel>> {
            return localVarFp.giftcardRSeriesLocationListGet(accountPlatformId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {RegisterInstoreGiftCardViewModel} [registerInstoreGiftCardViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardRegisterInstoreCardPost(apiVersion?: string, registerInstoreGiftCardViewModel?: RegisterInstoreGiftCardViewModel, options?: any): AxiosPromise<GiftCardDetailViewModel> {
            return localVarFp.giftcardRegisterInstoreCardPost(apiVersion, registerInstoreGiftCardViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardRemoveVideoMessageIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardRemoveVideoMessageIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [emailType] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardResendEmailByTypeGet(id?: string, emailType?: string, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardResendEmailByTypeGet(id, emailType, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardResendEmailIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardResendEmailIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSalesChannelListGet(apiVersion?: string, options?: any): AxiosPromise<Array<SalesChannelListItemViewModel>> {
            return localVarFp.giftcardSalesChannelListGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CSeriesSettingPostViewModel} [cSeriesSettingPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveCSeriesChannelSettingsPost(apiVersion?: string, cSeriesSettingPostViewModel?: CSeriesSettingPostViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardSaveCSeriesChannelSettingsPost(apiVersion, cSeriesSettingPostViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardCodeFormattingViewModel} [giftCardCodeFormattingViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveCodeFormattingSettingsPost(apiVersion?: string, giftCardCodeFormattingViewModel?: GiftCardCodeFormattingViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardSaveCodeFormattingSettingsPost(apiVersion, giftCardCodeFormattingViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {EditGiftCardViewModel} [editGiftCardViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveEditGiftCardPost(apiVersion?: string, editGiftCardViewModel?: EditGiftCardViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardSaveEditGiftCardPost(apiVersion, editGiftCardViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardShippingAddressViewModel} [giftCardShippingAddressViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveEditShippingAddressPost(apiVersion?: string, giftCardShippingAddressViewModel?: GiftCardShippingAddressViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardSaveEditShippingAddressPost(apiVersion, giftCardShippingAddressViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [strEmailsToSuppress] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveEmailSuppressionSettingsPost(strEmailsToSuppress?: string, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardSaveEmailSuppressionSettingsPost(strEmailsToSuppress, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardExpiryViewModel} [giftCardExpiryViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveExpirySettingsPost(apiVersion?: string, giftCardExpiryViewModel?: GiftCardExpiryViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardSaveExpirySettingsPost(apiVersion, giftCardExpiryViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardNotesViewModel} [giftCardNotesViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveGifCardNotesPost(apiVersion?: string, giftCardNotesViewModel?: GiftCardNotesViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardSaveGifCardNotesPost(apiVersion, giftCardNotesViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardSaveNotesModel} [giftCardSaveNotesModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveGiftCardNotesPost(apiVersion?: string, giftCardSaveNotesModel?: GiftCardSaveNotesModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardSaveGiftCardNotesPost(apiVersion, giftCardSaveNotesModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {KSeriesSettingPostViewModel} [kSeriesSettingPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveKSeriesChannelSettingsPost(apiVersion?: string, kSeriesSettingPostViewModel?: KSeriesSettingPostViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardSaveKSeriesChannelSettingsPost(apiVersion, kSeriesSettingPostViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {KountaSettingsPostViewModel} [kountaSettingsPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveKountaChannelSettingsPost(apiVersion?: string, kountaSettingsPostViewModel?: KountaSettingsPostViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardSaveKountaChannelSettingsPost(apiVersion, kountaSettingsPostViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {string} [imageUrl] 
         * @param {any} [image] 
         * @param {string} [variants] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveProductSettingsPost(apiVersion?: string, title?: string, description?: string, imageUrl?: string, image?: any, variants?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardSaveProductSettingsPost(apiVersion, title, description, imageUrl, image, variants, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {RSeriesSettingPostViewModel} [rSeriesSettingPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveRSeriesChannelSettingsPost(apiVersion?: string, rSeriesSettingPostViewModel?: RSeriesSettingPostViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardSaveRSeriesChannelSettingsPost(apiVersion, rSeriesSettingPostViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SalesChannelListItemViewModel} [salesChannelListItemViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveSalesChannelPost(apiVersion?: string, salesChannelListItemViewModel?: SalesChannelListItemViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardSaveSalesChannelPost(apiVersion, salesChannelListItemViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {ShopifySettingsPostViewModel} [shopifySettingsPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveShopifyChannelSettingsPost(apiVersion?: string, shopifySettingsPostViewModel?: ShopifySettingsPostViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardSaveShopifyChannelSettingsPost(apiVersion, shopifySettingsPostViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SquareSettingPostViewModel} [squareSettingPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveSquareChannelSettingsPost(apiVersion?: string, squareSettingPostViewModel?: SquareSettingPostViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardSaveSquareChannelSettingsPost(apiVersion, squareSettingPostViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {WooCommerceSettingsPostViewModel} [wooCommerceSettingsPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSaveWooCommerceChannelSettingsPost(apiVersion?: string, wooCommerceSettingsPostViewModel?: WooCommerceSettingsPostViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardSaveWooCommerceChannelSettingsPost(apiVersion, wooCommerceSettingsPostViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SendGiftCardToFriendViewModel} [sendGiftCardToFriendViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSendGiftCardToFriendPost(apiVersion?: string, sendGiftCardToFriendViewModel?: SendGiftCardToFriendViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardSendGiftCardToFriendPost(apiVersion, sendGiftCardToFriendViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [accountPlatformId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardSquareLocationListGet(accountPlatformId?: string, apiVersion?: string, options?: any): AxiosPromise<Array<SquareLocationViewModel>> {
            return localVarFp.giftcardSquareLocationListGet(accountPlatformId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardToggleIsPromotionalGiftCardByIdIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardToggleIsPromotionalGiftCardByIdIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {SalesChannelSourceProductUpdatePostPayload} [salesChannelSourceProductUpdatePostPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardUpdateSalesChannelGiftCardSourceProductIdPost(apiVersion?: string, salesChannelSourceProductUpdatePostPayload?: SalesChannelSourceProductUpdatePostPayload, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardUpdateSalesChannelGiftCardSourceProductIdPost(apiVersion, salesChannelSourceProductUpdatePostPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {string} [giftCardId] 
         * @param {any} [videoMessageFile] 
         * @param {any} [videoMessageThumbnailFile] 
         * @param {number} [videoMessageSize] 
         * @param {number} [videoMessageThumbnailSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardUploadGiftCardVideoMessagePost(apiVersion?: string, giftCardId?: string, videoMessageFile?: any, videoMessageThumbnailFile?: any, videoMessageSize?: number, videoMessageThumbnailSize?: number, options?: any): AxiosPromise<UploadGiftCardsVideoMessageResultPayload> {
            return localVarFp.giftcardUploadGiftCardVideoMessagePost(apiVersion, giftCardId, videoMessageFile, videoMessageThumbnailFile, videoMessageSize, videoMessageThumbnailSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {any} [csvFile] 
         * @param {Array<string>} [syncToSelectAccountPlatformsOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardUploadGiftCardsCsvPost(apiVersion?: string, csvFile?: any, syncToSelectAccountPlatformsOnly?: Array<string>, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardUploadGiftCardsCsvPost(apiVersion, csvFile, syncToSelectAccountPlatformsOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftcardVoidGiftCardByIdIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftcardVoidGiftCardByIdIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GiftcardApi - object-oriented interface
 * @export
 * @class GiftcardApi
 * @extends {BaseAPI}
 */
export class GiftcardApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardAddToCartGiftCardToFriendIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardAddToCartGiftCardToFriendIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardCodeFormattingSettingsGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardCodeFormattingSettingsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {GiftCardViewModel} [giftCardViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardCreateGiftCardPost(apiVersion?: string, giftCardViewModel?: GiftCardViewModel, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardCreateGiftCardPost(apiVersion, giftCardViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardDeleteGiftCardByIdIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardDeleteGiftCardByIdIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardDeleteSalesChannelIdDelete(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardDeleteSalesChannelIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardEmailSuppressionSettingsGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardEmailSuppressionSettingsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardExpirySettingsGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardExpirySettingsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardExportGiftCardsToCsvGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardExportGiftCardsToCsvGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardGenerateBoppleSalesChannelPost(apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardGenerateBoppleSalesChannelPost(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardGenerateKSeriesSalesChannelPost(apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardGenerateKSeriesSalesChannelPost(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [accountId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardGenerateUniqueCodeByAccountIdGet(accountId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardGenerateUniqueCodeByAccountIdGet(accountId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardGenerateWrappedStorefrontSalesChannelPost(apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardGenerateWrappedStorefrontSalesChannelPost(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {string} [accountId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardGetByCodeCodeGet(code: string, accountId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardGetByCodeCodeGet(code, accountId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardGetByIdIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardGetByIdIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [giftCardId] 
     * @param {string} [eventType] 
     * @param {number} [page] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {string} [sortField] 
     * @param {boolean} [isDesc] 
     * @param {string} [search] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardGetEmailEventsPageByGiftCardGet(giftCardId?: string, eventType?: string, page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardGetEmailEventsPageByGiftCardGet(giftCardId, eventType, page, skip, limit, sortField, isDesc, search, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountPlatformId 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardGetGiftCardProductIdAccountPlatformIdGet(accountPlatformId: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardGetGiftCardProductIdAccountPlatformIdGet(accountPlatformId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [giftCardId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardGetGiftCardSyncErrorsViewModelGet(giftCardId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardGetGiftCardSyncErrorsViewModelGet(giftCardId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardGetPendingShippingCountGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardGetPendingShippingCountGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [giftcardId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardGetTimelineEventsByGiftCardIdGet(giftcardId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardGetTimelineEventsByGiftCardIdGet(giftcardId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardGiftCardToFriendIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardGiftCardToFriendIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardGiftToFriendEmailHtmlGet(id?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardGiftToFriendEmailHtmlGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardHasShopifyChannelConnectedGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardHasShopifyChannelConnectedGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardInitCreateGiftCardGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardInitCreateGiftCardGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [status] 
     * @param {number} [page] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {string} [sortField] 
     * @param {boolean} [isDesc] 
     * @param {string} [search] 
     * @param {string} [reasonType] 
     * @param {string} [batchId] 
     * @param {string} [recipientEmail] 
     * @param {string} [buyerEmail] 
     * @param {string} [orderNumber] 
     * @param {string} [issuedSince] 
     * @param {string} [buyerName] 
     * @param {string} [recipientName] 
     * @param {string} [deliveryStatus] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardListGet(status?: string, page?: number, skip?: number, limit?: number, sortField?: string, isDesc?: boolean, search?: string, reasonType?: string, batchId?: string, recipientEmail?: string, buyerEmail?: string, orderNumber?: string, issuedSince?: string, buyerName?: string, recipientName?: string, deliveryStatus?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardListGet(status, page, skip, limit, sortField, isDesc, search, reasonType, batchId, recipientEmail, buyerEmail, orderNumber, issuedSince, buyerName, recipientName, deliveryStatus, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {GiftCardMarkAsShippedPostViewModel} [giftCardMarkAsShippedPostViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardMarkAsShippedPost(apiVersion?: string, giftCardMarkAsShippedPostViewModel?: GiftCardMarkAsShippedPostViewModel, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardMarkAsShippedPost(apiVersion, giftCardMarkAsShippedPostViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [accountId] 
     * @param {string} [primaryGiftCardId] 
     * @param {string} [mergeFromGiftCardId] 
     * @param {boolean} [sendEmail] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardMergeGiftcardGet(accountId?: string, primaryGiftCardId?: string, mergeFromGiftCardId?: string, sendEmail?: boolean, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardMergeGiftcardGet(accountId, primaryGiftCardId, mergeFromGiftCardId, sendEmail, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardPdfIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardPdfIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {GiftCardManualAdjustmentViewModel} [giftCardManualAdjustmentViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardPerformGiftCardManualAdjustmentPost(apiVersion?: string, giftCardManualAdjustmentViewModel?: GiftCardManualAdjustmentViewModel, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardPerformGiftCardManualAdjustmentPost(apiVersion, giftCardManualAdjustmentViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {PerformSyncGiftCardViewModel} [performSyncGiftCardViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardPerformSyncGiftCardPost(apiVersion?: string, performSyncGiftCardViewModel?: PerformSyncGiftCardViewModel, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardPerformSyncGiftCardPost(apiVersion, performSyncGiftCardViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardProductSettingsGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardProductSettingsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [accountPlatformId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardRSeriesEmployeeListGet(accountPlatformId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardRSeriesEmployeeListGet(accountPlatformId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [accountPlatformId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardRSeriesLocationListGet(accountPlatformId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardRSeriesLocationListGet(accountPlatformId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {RegisterInstoreGiftCardViewModel} [registerInstoreGiftCardViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardRegisterInstoreCardPost(apiVersion?: string, registerInstoreGiftCardViewModel?: RegisterInstoreGiftCardViewModel, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardRegisterInstoreCardPost(apiVersion, registerInstoreGiftCardViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardRemoveVideoMessageIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardRemoveVideoMessageIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [emailType] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardResendEmailByTypeGet(id?: string, emailType?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardResendEmailByTypeGet(id, emailType, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardResendEmailIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardResendEmailIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardSalesChannelListGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardSalesChannelListGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {CSeriesSettingPostViewModel} [cSeriesSettingPostViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardSaveCSeriesChannelSettingsPost(apiVersion?: string, cSeriesSettingPostViewModel?: CSeriesSettingPostViewModel, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardSaveCSeriesChannelSettingsPost(apiVersion, cSeriesSettingPostViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {GiftCardCodeFormattingViewModel} [giftCardCodeFormattingViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardSaveCodeFormattingSettingsPost(apiVersion?: string, giftCardCodeFormattingViewModel?: GiftCardCodeFormattingViewModel, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardSaveCodeFormattingSettingsPost(apiVersion, giftCardCodeFormattingViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {EditGiftCardViewModel} [editGiftCardViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardSaveEditGiftCardPost(apiVersion?: string, editGiftCardViewModel?: EditGiftCardViewModel, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardSaveEditGiftCardPost(apiVersion, editGiftCardViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {GiftCardShippingAddressViewModel} [giftCardShippingAddressViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardSaveEditShippingAddressPost(apiVersion?: string, giftCardShippingAddressViewModel?: GiftCardShippingAddressViewModel, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardSaveEditShippingAddressPost(apiVersion, giftCardShippingAddressViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [strEmailsToSuppress] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardSaveEmailSuppressionSettingsPost(strEmailsToSuppress?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardSaveEmailSuppressionSettingsPost(strEmailsToSuppress, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {GiftCardExpiryViewModel} [giftCardExpiryViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardSaveExpirySettingsPost(apiVersion?: string, giftCardExpiryViewModel?: GiftCardExpiryViewModel, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardSaveExpirySettingsPost(apiVersion, giftCardExpiryViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {GiftCardNotesViewModel} [giftCardNotesViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardSaveGifCardNotesPost(apiVersion?: string, giftCardNotesViewModel?: GiftCardNotesViewModel, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardSaveGifCardNotesPost(apiVersion, giftCardNotesViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {GiftCardSaveNotesModel} [giftCardSaveNotesModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardSaveGiftCardNotesPost(apiVersion?: string, giftCardSaveNotesModel?: GiftCardSaveNotesModel, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardSaveGiftCardNotesPost(apiVersion, giftCardSaveNotesModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {KSeriesSettingPostViewModel} [kSeriesSettingPostViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardSaveKSeriesChannelSettingsPost(apiVersion?: string, kSeriesSettingPostViewModel?: KSeriesSettingPostViewModel, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardSaveKSeriesChannelSettingsPost(apiVersion, kSeriesSettingPostViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {KountaSettingsPostViewModel} [kountaSettingsPostViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardSaveKountaChannelSettingsPost(apiVersion?: string, kountaSettingsPostViewModel?: KountaSettingsPostViewModel, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardSaveKountaChannelSettingsPost(apiVersion, kountaSettingsPostViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {string} [title] 
     * @param {string} [description] 
     * @param {string} [imageUrl] 
     * @param {any} [image] 
     * @param {string} [variants] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardSaveProductSettingsPost(apiVersion?: string, title?: string, description?: string, imageUrl?: string, image?: any, variants?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardSaveProductSettingsPost(apiVersion, title, description, imageUrl, image, variants, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {RSeriesSettingPostViewModel} [rSeriesSettingPostViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardSaveRSeriesChannelSettingsPost(apiVersion?: string, rSeriesSettingPostViewModel?: RSeriesSettingPostViewModel, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardSaveRSeriesChannelSettingsPost(apiVersion, rSeriesSettingPostViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {SalesChannelListItemViewModel} [salesChannelListItemViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardSaveSalesChannelPost(apiVersion?: string, salesChannelListItemViewModel?: SalesChannelListItemViewModel, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardSaveSalesChannelPost(apiVersion, salesChannelListItemViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {ShopifySettingsPostViewModel} [shopifySettingsPostViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardSaveShopifyChannelSettingsPost(apiVersion?: string, shopifySettingsPostViewModel?: ShopifySettingsPostViewModel, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardSaveShopifyChannelSettingsPost(apiVersion, shopifySettingsPostViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {SquareSettingPostViewModel} [squareSettingPostViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardSaveSquareChannelSettingsPost(apiVersion?: string, squareSettingPostViewModel?: SquareSettingPostViewModel, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardSaveSquareChannelSettingsPost(apiVersion, squareSettingPostViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {WooCommerceSettingsPostViewModel} [wooCommerceSettingsPostViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardSaveWooCommerceChannelSettingsPost(apiVersion?: string, wooCommerceSettingsPostViewModel?: WooCommerceSettingsPostViewModel, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardSaveWooCommerceChannelSettingsPost(apiVersion, wooCommerceSettingsPostViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {SendGiftCardToFriendViewModel} [sendGiftCardToFriendViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardSendGiftCardToFriendPost(apiVersion?: string, sendGiftCardToFriendViewModel?: SendGiftCardToFriendViewModel, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardSendGiftCardToFriendPost(apiVersion, sendGiftCardToFriendViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [accountPlatformId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardSquareLocationListGet(accountPlatformId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardSquareLocationListGet(accountPlatformId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardToggleIsPromotionalGiftCardByIdIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardToggleIsPromotionalGiftCardByIdIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {SalesChannelSourceProductUpdatePostPayload} [salesChannelSourceProductUpdatePostPayload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardUpdateSalesChannelGiftCardSourceProductIdPost(apiVersion?: string, salesChannelSourceProductUpdatePostPayload?: SalesChannelSourceProductUpdatePostPayload, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardUpdateSalesChannelGiftCardSourceProductIdPost(apiVersion, salesChannelSourceProductUpdatePostPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {string} [giftCardId] 
     * @param {any} [videoMessageFile] 
     * @param {any} [videoMessageThumbnailFile] 
     * @param {number} [videoMessageSize] 
     * @param {number} [videoMessageThumbnailSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardUploadGiftCardVideoMessagePost(apiVersion?: string, giftCardId?: string, videoMessageFile?: any, videoMessageThumbnailFile?: any, videoMessageSize?: number, videoMessageThumbnailSize?: number, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardUploadGiftCardVideoMessagePost(apiVersion, giftCardId, videoMessageFile, videoMessageThumbnailFile, videoMessageSize, videoMessageThumbnailSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {any} [csvFile] 
     * @param {Array<string>} [syncToSelectAccountPlatformsOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardUploadGiftCardsCsvPost(apiVersion?: string, csvFile?: any, syncToSelectAccountPlatformsOnly?: Array<string>, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardUploadGiftCardsCsvPost(apiVersion, csvFile, syncToSelectAccountPlatformsOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftcardApi
     */
    public giftcardVoidGiftCardByIdIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftcardApiFp(this.configuration).giftcardVoidGiftCardByIdIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}
