import React, { useContext, useState, useEffect } from "react";
import { BillingPlan } from "../../apiclient";
import { AuthContext } from "../../contexts/AccountContext";
import AccountPlanFeaturesComponent from "./AccountPlanFeaturesComponent";
const AccountPlanOptionComponent = (props: any) => {
  const authContext = useContext(AuthContext);
  const ctaButtonText =
    props.stage === "onboarding" ? "Start free trial" : "Choose plan";
  const borderOutlineClass =
    authContext.account?.billingPlan === props.Plan ? "border-primary" : "";
  const stage = props.stage ?? "";

  const getChangePlanUrl = (newPlan: BillingPlan) => {
    if (authContext.account?.billingProvider === "Shopify") {
      return `/Shopify/ChangePlan?plan=${newPlan}`;
    } else return `/Billing/ChangePlan?plan=${newPlan}`;
  };

  const iframeTarget = () => {
    try {
      return window.self !== window.top ? "_blank" : "_self";
    } catch (e) {
      return "_blank";
    }
  };

  return (
    <React.Fragment>
      <div className="col-lg-4">
        <div
          className={
            "card card-pricing px-8 py-7 text-center " + borderOutlineClass
          }
        >
          <div className="alert alert-info">
            <div className="">
              <h4 className="mb-3 font-semibold">
                {props.planName}{" "}
                {props.mostPopular && (
                  <span className="ml-10 badge bg-secondary bg-opacity-50 text-dark">
                    Most popular
                  </span>
                )}
              </h4>
            </div>
            {props.isAnnual ? (
              <p className="text-muted text-sm mt-2">
              Includes {(Number(props.numCards) || 0).toLocaleString()} new gift cards/year
            </p>
            ) : (
              <p className="text-muted text-sm mt-2">
              Includes {(Number(props.numCards) || 0).toLocaleString()} new gift cards/month
            </p>
            )}
           
            <p className="text-muted text-sm mt-2 font-bold">
              Up to {props.numLocations} locations
            </p>
          </div>
          {props.savings && (
            <div className="transform mx-auto translate-y-n1/2 text-white position-relative">
              <span className="badge rounded-pill bg-primary text-white">
                {props.savings} OFF
              </span>
            </div>
          )}
          <div className="display-5 mt-5 text-heading">
            <span className="">${props.planPrice}</span>
            <span className="text-muted text-base font-semibold align-self-end ms-1">
              <span className="mt-n8">USD / month</span>
            </span>
          </div>
          <div className="text-muted text-sm my-5">
            <strong>+${props.addtionalCardPrice} </strong>per additional gift
            card{" "}
            {props.isAnnual && (
              <>
                <br />
                Paid annually
              </>
            )}
          </div>
          {authContext.account?.billingPlan === props.plan ? (
            <>
              <button disabled className="btn w-full btn-secondary mb-5">
                Current plan
              </button>
              {authContext.account?.billingProvider === "Stripe" && (
                <a
                  href="/Billing/CustomerPortal"
                  className="btn w-full btn-neutral"
                >
                  Manage subscription
                </a>
              )}
            </>
          ) : (
            <a
              href={getChangePlanUrl(props.plan)}
              className="btn w-full btn-primary"
              target={iframeTarget()}
            >
              {ctaButtonText}
            </a>
          )}
          <hr className="opacity-0 my-2" />
          <AccountPlanFeaturesComponent plan={props.plan} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AccountPlanOptionComponent;
