import React, { useState, useEffect } from "react";

import { TestApi } from "../apiclient";
import { TestViewModel } from "../apiclient/models";
import { apiConfig } from "../config"
import { axiosInstance } from "../ShopifyAxiosInterceptor";
import GoogleAddressFinder from "./GoogleAddressFinder";
const TestPage = () => {
    let testApi = new TestApi(apiConfig.configuration, apiConfig.baseUrl, axiosInstance);
    const [model, setModel] = useState({} as TestViewModel);

    return (
        <React.Fragment>
            <GoogleAddressFinder />
        </React.Fragment>
    );
}

export default TestPage;
