import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";
import EmailEditor, { EditorRef } from "react-email-editor";
import "./unlayer.css";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import {
  EmailNotificationViewModel,
  EmailApi,
  EmailNotificationType,
} from "../../apiclient";
import { apiConfig } from "../../config";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import SendTestEmailModal from "./SendTestEmailModal";
import { AuthContext } from "../../contexts/AccountContext";
import EmailEditSubjectModal from "./EmailEditSubjectModal";
const EmailPage = () => {
  let { notificationType } = useParams();
  const navigate = useNavigate();
  const [model, setModel] = useState({} as EmailNotificationViewModel);
  const [isLoading, setLoading] = useState(true as boolean);
  const [title, setTitle] = useState("" as string);
  const [testHtml, setTestHtml] = useState("" as string);
  const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
  const [isTestEmailModalShown, setIsTestEmailModalShown] = useState(false);
  const [isEditEmailSubjectModalShown, setIsEditEmailSubjectModalShown] =
    useState(false);
  const emailEditorRef = useRef<EditorRef | null>(null);
  let emailApi = new EmailApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const authContext = useContext(AuthContext);

  // useEffect(() => {
  //   if (emailEditorRef.current) {
  //     fetchData();
  //   }
  // }, []);

  const fetchData = () => {
    if (notificationType === EmailNotificationType.GiftCardPurchased) {
      setTitle("Gift card issued email");
    } else if (
      notificationType === EmailNotificationType.GiftCardPurchasedPdf
    ) {
      setTitle("Gift card issued pdf");
    } else if (notificationType === EmailNotificationType.GiftCardGifted) {
      setTitle("Gift card gifted email");
    } else if (notificationType === EmailNotificationType.GiftCardUpdated) {
      setTitle("Gift card updated email");
    }else {
      setTitle("Unused gift card reminder email");
    }
    emailApi
      .emailNotificationNotificationTypeGet(notificationType!)
      .then((response) => {
        var data = response.data;
        setModel(data);
        setLoading(false);
        //console.log("fetch data", data);
        var templateJson = JSON.parse(data.json!);
        emailEditorRef.current?.editor?.loadDesign(templateJson);

        // emailEditorRef.current?.editor?.setBodyValues({
        //   backgroundColor: "#e7e7e7",
        //   contentWidth: "300px", // or percent "50%"
        //   fontFamily: {
        //     label: "Helvetica",
        //     value: "'Helvetica Neue', Helvetica, Arial, sans-serif"
        //   },
        //   preheaderText: "Branding center defaults"
        // });
      })
      .catch((error: any) => {
        //console.log("get survey error", error);
        setLoading(false);
      });
  };

  const previewChanges = () => {
    emailEditorRef.current?.editor?.showPreview("desktop");
    // emailEditorRef.current?.editor?.registerCallback(
    //   "previewHtml",
    //   function (params: any, done: any) {
    //     done({
    //       html: params.html, // you can pass your custom html here
    //     });
    //   }
    // );
  };

  const subjectUpdated = (subject: string) => {
    setModel((values: EmailNotificationViewModel) => ({
      ...values,
      subject: subject,
    }));
  };

  const saveChanges = () => {
    emailEditorRef.current?.editor?.exportHtml((data: any) => {
      const { design, html } = data;
      console.log("exportHtml", html);
      console.log("exportJson", design);
      var submitModel = {
        html: html,
        json: JSON.stringify(design),
        notificationType: notificationType!,
        subject: model.subject,
      } as EmailNotificationViewModel;

      emailApi
        .emailSaveNotificationPost("1", submitModel)
        .then((response) => {
          toast.success("Design saved!");
          setUnsavedChanges(false);
        })
        .catch((error: any) => {
          toast.error("An error occurred when saving settings.");
          setUnsavedChanges(false);
        });
    });
  };

  // const saveDesign = () => {
  //   emailEditorRef.current?.editor?.saveDesign((design: any) => {
  //     console.log("saveDesign", JSON.stringify(design));
  //     //alert('Design JSON has been logged in your developer console.');
  //   });
  // };
  const showTestEmailModal = () => {
    emailEditorRef.current?.editor?.exportHtml((data: any) => {
      const { design, html } = data;
      console.log("showTestEmailModal", html);

      setIsTestEmailModalShown(true);
      setTestHtml(html);
    });
  };

  const onReady = () => {
    // editor is ready
    console.log("onReady");
    fetchData();
    const formattedAmount = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: authContext.account?.isoCurrencyCode,
      currencyDisplay: "symbol",
    }).format(50);

    if (emailEditorRef.current) {
      emailEditorRef.current?.editor?.setMergeTags({
        shop_name: {
          name: "Shop name",
          value: "{{shop_name}}",
          sample: authContext.account?.companyName,
        },
        shop_email: {
          name: "Shop email",
          value: "{{shop_email}}",
          sample:
            authContext.account?.replyToEmailAddress ??
            authContext.account?.emailAddress,
        },
        shop_url: {
          name: "Shop url",
          value: "{{shop_url}}",
          sample: authContext.account?.integrationDomain,
        },
        buyer_full_name: {
          name: "Buyer full name",
          value: "{{buyer_full_name}}",
          sample: "Peter Parker",
        },
        buyer_email: {
          name: "Buyer email",
          value: "{{buyer_email}}",
          sample: "peter.parker@cool.com",
        },
        date_of_purchase: {
          name: "Date of purchase",
          value: "{{date_of_purchase}}",
          sample: "24 May 2023",
        },
        balance: {
          name: "Gift card balance",
          value: "{{balance}}",
          sample: formattedAmount,
          },
        total_issued_amount: {
              name: "Gift card total issued amount",
              value: "{{total_issued_amount}}",
              sample: formattedAmount,
          },
        code: {
          name: "Gift card code",
          value: "{{code}}",
          sample: "XXXX-XXXX-XXXX-XXXX",
        },
        expiration_date: {
          name: "Expriation date",
          value: "{{expiration_date}}",
          sample: "24 May 2024",
        },
        gift_card_message: {
          name: "Gift card message",
          value: "{{gift_card_message}}",
          sample:
            "Best wishes for your birthday! Hope you enjoy this gift card!",
        },
        recipient_name: {
          name: "Recipient name",
          value: "{{recipient_name}}",
          sample: "Harry Osbourne",
        },
        recipient_email: {
          name: "Recipient email",
          value: "{{recipient_email}}",
          sample: "harry.osbourne@test.com",
        },
        gift_image_url: {
          name: "Gift image url",
          value: "{{gift_image_url}}",
          sample:
            "https://s3.amazonaws.com/loudhippo.resources/giftkart/placeholder-gift.png",
        },
      });
    }
  };

  return (
    <React.Fragment>
      <div className="">
        <header className="position-sticky top-0 overlap-10 bg-surface-primary border-bottom">
          <div className="container-fluid py-4">
            <div className="row align-items-center">
              <div className="col">
                <div className="d-flex align-items-center gap-4">
                  <div>
                    <Link
                      className="bi bi-chevron-left text-xs"
                      to={`/emails`}
                    ></Link>
                  </div>
                  <div className="vr opacity-20 my-1"></div>
                  {/* <h1 className="h4 ls-tight">{title}</h1> */}
                  <div className="">
                    <h4 className="ls-tight mb-1">{title}</h4>
                    {notificationType !==
                    EmailNotificationType.GiftCardPurchasedPdf && 
                    <p className="text-sm text-muted">
                      Subject: {model.subject}{" "}
                      <a
                        onClick={() => setIsEditEmailSubjectModalShown(true)}
                        className="text-link cursor-pointer"
                      >
                        (Edit)
                      </a>
                    </p>}
                  </div>
                </div>
              </div>
              <div className="col"></div>
              <div className="col-auto">
                <div className="hstack gap-2 justify-content-end">
                  {hasUnsavedChanges && (
                    <div className="text-sm text-muted font-semibold me-2 d-none d-md-block">
                      <span className="d-none d-sm-inline ms-2">
                        You have unsaved changes
                      </span>
                    </div>
                  )}
                  {/* <a
                    href="https://help.wrappedgiftcard.com/en/collections/3830625-account#general"
                    target={"_blank"}
                    className="text-sm text-muted text-primary-hover font-semibold me-2 d-none d-md-block"
                  >
                    <i className="bi bi-question-circle-fill"></i>
                    <span className="d-none d-sm-inline ms-2">Need help?</span>
                  </a> */}
                  {notificationType !==
                    EmailNotificationType.GiftCardPurchasedPdf && (
                    <>
                      <button
                        type="button"
                        className="btn btn-neutral"
                        onClick={previewChanges}
                      >
                        <span>Preview</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-neutral"
                        onClick={() => showTestEmailModal()}
                      >
                        <span>Send test email</span>
                      </button>
                    </>
                  )}
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={saveChanges}
                  >
                    <span>Save</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>

        {/* <!-- Main -->  */}
        <main className="">
          <div className="">
            <EmailEditor
              style={{ height: "1000px" }}
              options={{
                projectId: 111160,
                appearance: {
                  features: {
                    preview: true,
                  },
                  theme: "light",
                  panels: {
                    tools: {
                      collapsible: true,
                      dock: "left",
                      tabs: {
                        body: {
                          visible: true,
                        },
                      },
                    },
                  },
                },
              }}
              ref={emailEditorRef}
              onReady={onReady}
            />
            {/* )} */}
          </div>
        </main>
        <SendTestEmailModal
          show={isTestEmailModalShown}
          notificationType={model.notificationType}
          subject={model.subject}
          onHide={() => setIsTestEmailModalShown(false)}
          html={testHtml}
        />
        {model && model.subject && (
          <EmailEditSubjectModal
            show={isEditEmailSubjectModalShown}
            onHide={() => setIsEditEmailSubjectModalShown(false)}
            subject={model.subject}
            notificationType={model.notificationType}
            updated={subjectUpdated}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default EmailPage;
