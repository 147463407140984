import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AccountContext";
import { CrispChatContext } from "./CrispChat";
import SwitchAccountModal from "../pages/account/SwitchAccountModal";
import { shopifyAppBridge } from "../config";
import LaunchBanner from "./LaunchBanner";
import { AccountFeatureType } from "../apiclient";
import { apiConfig } from "../config";
import { RedeemAppApi, RedeemAppSettingsViewModel, AccountStatus } from "../apiclient";
import { axiosInstance } from "../ShopifyAxiosInterceptor";
import { useIntercom } from "react-use-intercom";


const TopNavBar = (props: any) => {
  const {
    boot,
    shutdown,
    hardShutdown,
    update,
    hide,
    show,
    showMessages,
    showNewMessages,
    getVisitorId,
    startTour,
    trackEvent,
    showArticle,
  } = useIntercom();

  let redeemAppApi = new RedeemAppApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [redeemAppSettings, setRedeemAppSettings] = useState({} as RedeemAppSettingsViewModel);
  const authContext = useContext(AuthContext);
  const crispChatContext = useContext(CrispChatContext);
  const [isSwitchAccountModalShown, setIsSwitchAccountModalShown] =
    useState(false);
  const numProfiles = authContext.account?.associatedAccountIds?.length ?? 1;
  const shopifyEmbedHostParam =
    new URLSearchParams(window.location.search).get("host") ||
    shopifyAppBridge.host;

  const [hideLogoutFromMenu, setHideLogoutFromMenu] = useState(
    false as boolean
  );

  useEffect(() => {
    if (shopifyEmbedHostParam || inIframe()) {
      setHideLogoutFromMenu(true);
    }

    if (authContext.account?.userRole === "Member") {
      fetchRedeemAppSettings();
    }

  }, []);


  function openChatForActivateAccountRequest() {
    showNewMessages("I'd like to reactivate my cancelled account.");
  }

  const fetchRedeemAppSettings = () => {
    redeemAppApi
      .redeemAppGetSettingsGet()
      .then((response) => {
        var data = response.data;
        setRedeemAppSettings(data);
      })
      .catch((error: any) => {
        console.log("get error", error);
      });
  };

  function inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  // console.log('top nav host param', shopifyEmbedHostParam)
  //let navigate = useNavigate();
  const logout = (event: any) => {
    (window as any).Intercom("shutdown");
    window.location.href = "/Account/Logout";
  };

  function openHelpChat() {
    console.log("loadHelpChat");
    crispChatContext.openChat();
  }

  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-0 py-3">
        <div className="container-fluid">
          {/* <!-- Toggler --> */}
          <button
            className="navbar-toggler ms-n2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarsExampleXxl"
            aria-controls="navbarsExampleXxl"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* <!-- Brand --> */}
          {props.showMenu ? (
            <Link
              to={`/dashboard`}
              className="navbar-brand d-flex align-items-center gap-2 py-lg-2 px-lg-6 me-0"
            >
              <div className="w-md-auto">
                <img
                  src="/img/logos/wrapped-logo.png"
                  className="h-10"
                  alt="..."
                />
              </div>
            </Link>
          ) : (
            <a className="navbar-brand d-flex align-items-center gap-2 py-lg-2 px-lg-6 me-0">
              <div className="w-md-auto ">
                <img
                  src="/img/logos/wrapped-logo.png"
                  className="h-10"
                  alt="..."
                />
              </div>
            </a>
          )}

          {/* <!-- Avatar --> */}
          <div className="navbar-nav mb-4 mb-lg-0 order-lg-1">
            <a
              href="https://help.wrappedgiftcards.com"
              target="_blank"
              className="nav-item nav-link float-end"
            >
              <i className="bi bi-question-circle-fill"></i> Help center
            </a>
          </div>
          <div className="order-lg-last ms-lg-4 position-relative">
            <a
              className="d-flex align-items-center dropdown"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="d-none d-sm-block ms-3">
                <span className="h6 text-white">
                  {authContext.account?.companyName}
                </span>
                {authContext.account?.billingPlan !== "Free" && (
                  <p className="text-nowrap text-xs text-muted">
                    Plan: {authContext.account?.billingPlan?.toString()}
                  </p>
                )}
              </div>
              <div className="d-none d-md-block ms-md-5">
                <i className="bi bi-chevron-down text-muted text-xs"></i>
              </div>
            </a>
            <ul className="dropdown-menu dropdown-menu-end">
              {/* <a href="#!" className="dropdown-item">
                    <i className="ni ni-settings-gear-65"></i>
                    <span>Settings</span>
                  </a> */}
              {props.showMenu &&
                (authContext.account?.userRole == "Admin" ||
                  authContext.account?.userRole == "Owner") && (
                  <li>
                    <Link className="dropdown-item" to={`/account`}>
                      <i className="bi bi-person-square"></i> Account settings
                    </Link>
                  </li>
                )}

              {!hideLogoutFromMenu && (
                <>
                  {numProfiles > 1 && (
                    <li>
                      <a
                        className="dropdown-item cursor-pointer"
                        onClick={() => setIsSwitchAccountModalShown(true)}
                      >
                        <i className="bi bi-arrow-left-right"></i> Switch
                        account
                      </a>
                    </li>
                  )}
                  <li>
                    <a className="dropdown-item cursor-pointer" onClick={logout}>
                      <i className="bi bi-box-arrow-left"></i> Logout
                    </a>
                  </li>
                </>
              )}
              <div className="dropdown-divider"></div>
              <li>
                <p className="text-center p-2 text-muted">
                  <small>
                    You are logged in as: {authContext.account?.username} (
                    {authContext.account?.userRole})
                  </small>
                </p>
                <p className="text-center p-2 text-muted">
                  <small>
                    Account ID: {authContext.account?.accountId}</small>
                </p>
              </li>
            </ul>
          </div>
          <div className="collapse navbar-collapse" id="navbarsExampleXxl">
            {/* <!-- Nav items --> */}
            {props.showMenu && (
              <div className="navbar-nav mb-4 mb-lg-0 order-lg-1">
                <Link className="nav-item nav-link" to={`/`}>
                  <i className="bi bi-house-door"></i> Home
                </Link>
                <Link className="nav-item nav-link" to={`/gift-cards`}>
                  <i className="bi bi-gift"></i> Gift cards
                </Link>
                {/* <Link className="nav-item nav-link" to={`/refunds`}>
                  <i className="bi bi-arrow-90deg-left"></i> Refunds and returns
                </Link> */}

                {(authContext.account?.userRole == "Admin" ||
                  authContext.account?.userRole == "Owner") && (
                    <>
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdownMenu"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-magic"></i> Customize
                        </a>
                        <div
                          className="dropdown-menu dropdown-menu-md p-0 shadow-6"
                          aria-labelledby=""
                        >
                          <div className="px-6 py-1">
                            <Link
                              className="dropdown-group py-5 border-bottom"
                              to={`/emails`}
                            >
                              <div className="d-flex">
                                <div className="lh-snug dropdown-helper">
                                  <i className="bi bi-briefcase"></i>
                                </div>
                                <div className="ms-4">
                                  <h6 className="dropdown-heading lh-snug mb-2">
                                    Emails
                                  </h6>
                                  <p className="dropdown-helper">
                                    Automatically email your digital gift cards to
                                    recipients.
                                  </p>
                                </div>
                              </div>
                            </Link>
                            <Link className="dropdown-group py-5" to={`/assets`}>
                              <div className="d-flex">
                                <div className="lh-snug dropdown-helper">
                                  <i className="bi bi-house-door"></i>
                                </div>
                                <div className="ms-4">
                                  <h6 className="dropdown-heading lh-snug mb-2">
                                    Assets
                                  </h6>
                                  <p className="dropdown-helper">
                                    Create a personalized branded gifting
                                    experience.
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </li>

                      {/* <Link className="nav-item nav-link" to={`/manage-integrations`}>
                <i className="bi bi-plug"></i> Integrations
              </Link> */}

                      {/*authContext?.account?.enabledFeatures && authContext?.account?.enabledFeatures.filter(a=>a == "StoreCredit").length > 0 && (
                <Link className="nav-item nav-link" to={`/customers`}>
                  <i className="bi bi-people-fill"></i> Customers
                </Link>
              )*/}


                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdownMenu"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-megaphone"></i> Marketing
                        </a>
                        <div
                          className="dropdown-menu dropdown-menu-md p-0 shadow-6"
                          aria-labelledby=""
                        >
                          <div className="px-6 py-1">
                            <Link
                              className="dropdown-group py-5 border-bottom"
                              to={`/campaigns`}
                            >
                              <div className="d-flex">
                                <div className="lh-snug dropdown-helper">
                                  <i className="bi bi-envelope"></i>
                                </div>
                                <div className="ms-4">
                                  <h6 className="dropdown-heading lh-snug mb-2">
                                    Campaigns
                                  </h6>
                                  <p className="dropdown-helper">
                                    Send an email with a digital gift card to
                                    recipients from a batch.
                                  </p>
                                </div>
                              </div>
                            </Link>
                            <Link className="dropdown-group py-5" to={`/automations`}>
                              <div className="d-flex">
                                <div className="lh-snug dropdown-helper">
                                  <i className="bi bi-rocket-takeoff"></i>
                                </div>
                                <div className="ms-4">
                                  <h6 className="dropdown-heading lh-snug mb-2">
                                    Automations
                                  </h6>
                                  <p className="dropdown-helper">
                                    Automatically generate and email digital gift cards to customers
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </li>


                      <Link className="nav-item nav-link" to={`/reporting`}>
                        <i className="bi bi-file-bar-graph"></i> Reports
                      </Link>
                      <Link className="nav-item nav-link" to={`/account`}>
                        <i className="bi bi-gear"></i> Settings
                      </Link>
                      {/* <a
                      href="https://help.wrappedgiftcards.com"
                      target="_blank"
                      className="nav-item nav-link float-end"
                    >
                      <i className="bi bi-question-circle-fill"></i> Help center
                    </a> */}
                    </>
                  )}

                {authContext.account?.userRole == "Member" && redeemAppSettings?.isEnabled &&
                  <Link className="nav-item nav-link" to={`/account/redeem-app`}>
                    <i className="bi bi-gear"></i> Settings
                  </Link>
                }
              </div>
            )}
          </div>
        </div>
      </nav>
      {authContext.account && (
        <SwitchAccountModal
          show={isSwitchAccountModalShown}
          onHide={() => setIsSwitchAccountModalShown(false)}
        />
      )}


      {authContext.account && authContext.account.accountStatus == AccountStatus.Cancelled && <>

        <div className="alert alert-danger" role="alert">
          <div className="row align-items-center">
            <div className="col font-bold">
              Your account has been cancelled and your gift cards will no longer sync across your sales channels. <br />Please contact us at support@wrappedgiftcards.com to reactivate your account.
            </div>
            <div className="col-auto">
              <div className="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  onClick={() => openChatForActivateAccountRequest()}
                  className="btn d-inline-flex btn-sm btn-primary mx-1"
                >
                  <span className=" pe-2">
                    <i className="bi bi-chat"></i>
                  </span>
                  <span>Contact support</span>
                </button>
              </div>
            </div>
          </div>


        </div>
      </>}

      {authContext.account && authContext.account.isAccountBillingPastDue && <>

        <div className="alert alert-warning" role="alert">
          <div className="row align-items-center">
            <div className="col font-bold">
              Your account billing is overdue. Please update your payment details to keep your account and gift card sync running.
            </div>
            <div className="col-auto">
              <div className="hstack gap-2 justify-content-end">
                <a
                  href="/Billing/CustomerPortal"
                  className="btn d-inline-flex btn-sm btn-primary mx-1"
                >
                  <span className=" pe-2">
                    <i className="bi bi-credit-card"></i>
                  </span>
                  <span>Update payment details</span>
                </a>
              </div>
            </div>
          </div>

        </div>
      </>}

    </React.Fragment>
  );
};

export default TopNavBar;
